import { Box, Grid, Card, AlertColor } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as NotificationWithoutBell } from '../../assets/images/notification_on.svg';
import { ReactComponent as MoreIcon } from '../../assets/images/moreIcon.svg';
import { ReactComponent as DoubleTick } from '../../assets/images/double-tick.svg';

import {
  CLIENT_NAME_KEY,
  REFERRAL_ID_KEY,
  STORAGE_USER_ID_KEY,
} from '../../services/Constant';
import {
  NotificationCenterType,
  NotificationMain,
  deleteNotificationById,
  getNotificationReadApi,
  getNotificationsApi,
  updateAllNotificationAsRead,
} from '../../services/configApi/notification/notification';
import AppPagination from '../../components/shared/AppPagination';
import { ReactComponent as NotifficationRead } from '../../assets/images/notificationRead.svg';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import {
  ACTIVITY_APPROVAL_SHEET_ROUTE_NAME,
  ACTIVITY_SHEET_ROUTE_NAME,
  CLIENTS_ROUTE_NAME,
  FACESHEET,
  PROFILE_ROUTE_NAME,
  SCHEDULER_ROUTE_NAME,
  TASK_MANAGER,
} from '../../routes/Routing';
import { formatDate } from '../../utils/dateUtil';
import { useLocation, useNavigate } from 'react-router';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import moment from 'moment';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import {
  getAllReferrals,
  getFacesheetDetails,
  ReferralResponseType,
} from '../../services/configApi/Clients';
import { LookUpContext } from '../../context/LookUpContextProvider';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import Loader from '../../components/shared/Loader';
import ActionMenu from '../../components/formlib/ActionMenu';
import ModalPopup from '../../components/formlib/ModalPopup';

const NotificationList = () => {
  const navigate = useNavigate();
  const [notificationList, setNotificationList] = useState<
    NotificationCenterType[]
  >([]);
  const PAGE_SIZE = 10;
  const { state } = useLocation();
  const [paginationCount, setPaginationCount] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [unread, setUnread] = useState<boolean | null>(
    state.displayRead == false ? false : null,
  );
  const { updateDemographics } = useContext(LookUpContext);
  const [loader, setLoader] = useState(false);
  const [toastOpen, setToastOpen] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState<boolean>(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastOpen(false);
  };

  const toggleLoader = (value: boolean) => {
    setLoader(value);
  };

  const contextValue = React.useMemo<LoaderContextType>(
    () => ({
      loader,
      toggleLoader,
    }),
    [loader, toggleLoader],
  );

  const getNotifications = () => {
    const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (userId === null) {
      return;
    }
    toggleLoader(true);
    getNotificationsApi(userId, pageNumber, PAGE_SIZE, '', '', unread)
      .then((response: NotificationMain) => {
        toggleLoader(false);
        setNotificationList(response.notificationDetails);
        setPaginationCount(response.totalCount);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenSnackBar(true);
        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  useEffect(() => {
    getNotifications();
  }, [pageNumber, unread]);

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const updateNotification = (
    notificationId: string,
    notificationRead: boolean,
  ) => {
    toggleLoader(true);
    getNotificationReadApi(notificationId, !notificationRead)
      .then(() => {
        toggleLoader(false);
        setNotificationList((notifications) => {
          return notifications.map((notification) => {
            if (notification.id === notificationId) {
              notification.notificationRead = !notification.notificationRead;
            }
            return notification;
          });
        });
        setToastOpen(true);
        setToastrVariable('success');
        if (notificationRead) {
          setToastrId('notificationMarkAsUneadSuccessMessage');
          setToastrDefaultMessage('Notification marked as unread successfully');
        } else {
          setToastrId('notificationMarkAsReadSuccessMessage');
          setToastrDefaultMessage('Notification marked as read successfully');
        }
      })
      .catch(() => {
        toggleLoader(false);
        setToastOpen(true);
        setToastrVariable('error');
        setToastrId('notificationUpdateFailureMessage');
        setToastrDefaultMessage('Failed to update otification');
      });
  };

  const deleteNotification = (id: string) => {
    const employeeId = localStorage.getItem(STORAGE_USER_ID_KEY);
    if (employeeId) {
      toggleLoader(true);
      deleteNotificationById(id, employeeId)
        .then(() => {
          setToastOpen(true);
          setToastrVariable('success');
          setToastrId('deleteNotificationSuccessMessage');
          setToastrDefaultMessage('Notification deleted successfully');
          getNotifications();
        })
        .catch((error) => {
          toggleLoader(false);
          setToastOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('deleteNotificationFailureMessage');
            setToastrDefaultMessage('Failed to delete notification');
          }
        });
    }
  };

  const notificationRead = (items: NotificationCenterType) => {
    getNotificationReadApi(items.id, true)
      .then(() => {
        const customEvent: CustomEvent = new CustomEvent(
          'customNotificationEvent',
          {
            detail: { message: 'Hello from custom event!' },
          },
        );
        window.dispatchEvent(customEvent);
        if (
          items.notificationType === 'intake_facilitator' ||
          items.notificationType === 'client'
        ) {
          localStorage.setItem('ClientId', items.notificationIdTwo);
          localStorage.setItem(CLIENT_NAME_KEY, items.notificationIdThree);
          localStorage.setItem(REFERRAL_ID_KEY, items.notificationId);
          if (checkPermissionForFeature('backend.clients', 'viewPermission')) {
            navigate(FACESHEET);
          }
        } else if (items.notificationType === 'insurance') {
          if (
            checkPermissionForFeature('backend.clients', 'viewPermission') &&
            items.referralId !== null
          ) {
            toggleLoader(true);
            localStorage.setItem('ClientId', items.notificationIdTwo);
            localStorage.setItem(CLIENT_NAME_KEY, items.notificationIdThree);
            localStorage.setItem(REFERRAL_ID_KEY, items.referralId);
            localStorage.setItem('defaultReferralId', items.referralId);
            getAllReferrals(items.notificationIdTwo, items.referralId)
              .then(async (response: ReferralResponseType[]) => {
                if (response) {
                  localStorage.setItem(
                    'clientReferralDetails',
                    JSON.stringify(response),
                  );
                  getFacesheetDetails(items.notificationIdTwo)
                    .then((responseData) => {
                      if (responseData) {
                        updateDemographics(responseData.demographics);
                      }
                      toggleLoader(false);
                      sessionStorage.setItem('tabName', '3');
                      navigate(CLIENTS_ROUTE_NAME, {
                        state: {
                          clientId: localStorage.getItem('ClientId'),
                          isCreate: true,
                        },
                      });
                    })
                    .catch((errorFacesheet) => {
                      toggleLoader(false);
                      setToastrVariable('error');
                      if (isCustomError(errorFacesheet)) {
                        const apiError = errorFacesheet as ApiError;
                        setToastrId(apiError.id);
                        setToastrDefaultMessage(apiError.message);
                      } else {
                        setToastrId('Client.error');
                        setToastrDefaultMessage(errorFacesheet);
                      }
                    });
                }
              })
              .catch((error) => {
                toggleLoader(false);
                setToastrVariable('error');
                if (isCustomError(error)) {
                  const apiError = error as ApiError;
                  setToastrId(apiError.id);
                  setToastrDefaultMessage(apiError.message);
                } else {
                  setToastrId('clientLoadError');
                  setToastrDefaultMessage('Failed to get client details');
                }
              });
          }
        } else if (items.notificationType === 'schedule') {
          navigate(PROFILE_ROUTE_NAME);
        } else if (items.notificationType === 'appointment') {
          if (
            checkPermissionForFeature('backend.appointment', 'viewPermission')
          ) {
            navigate(SCHEDULER_ROUTE_NAME, {
              replace: true,
              state: {
                date: formatDate(
                  new Date(items.notificationDate),
                  'YYYY-MM-DD',
                ),
                appointmentId: items.notificationId,
              },
            });
          }
        } else if (items.notificationType === 'appointment_deleted') {
          if (
            checkPermissionForFeature('backend.appointment', 'viewPermission')
          ) {
            navigate(SCHEDULER_ROUTE_NAME, {
              replace: true,
              state: {
                date: formatDate(
                  new Date(items.notificationDate),
                  'YYYY-MM-DD',
                ),
              },
            });
          }
        } else if (items.notificationType === 'activitysheet') {
          navigate(ACTIVITY_SHEET_ROUTE_NAME);
        } else if (items.notificationType === 'activitysheetapproval') {
          if (
            checkPermissionForFeature(
              'backend.review_activity_sheet',
              'viewPermission',
            )
          ) {
            navigate(ACTIVITY_APPROVAL_SHEET_ROUTE_NAME, {
              state: {
                id: items.notificationId,
                date: items.notificationDate,
                firstName: items.notificationIdTwo,
                lastName: items.notificationIdThree,
              },
            });
          }
        } else if (items.notificationType === 'task_manager') {
          if (
            checkPermissionForFeature('backend.task_manager', 'viewPermission')
          ) {
            navigate(TASK_MANAGER);
          }
        }
      })
      .catch((error) => {
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('SomethingWentWrongText');
          setToastrDefaultMessage('Something went wrong, Please try again!');
          setToastrVariable('error');
        }
      });
  };

  return (
    <LoaderContext.Provider value={contextValue}>
      {loader && <Loader />}
      <SnackBarComponent
        open={toastOpen}
        handleClose={handleClose}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      {showConfirmationModal && (
        <ModalPopup
          open={showConfirmationModal}
          description="notificationMarkAsAllReadConfirmMessage"
          // defaultDescription="Are you sure you want to mark all Notifications as read?"
          onOk={() => setShowConfirmationModal(false)}
          onClose={() => setShowConfirmationModal(false)}
          onCancel={() => {
            const userId = localStorage.getItem(STORAGE_USER_ID_KEY);
            if (userId) {
              setShowConfirmationModal(false);
              toggleLoader(true);
              updateAllNotificationAsRead(userId)
                .then(() => {
                  toggleLoader(false);
                  setToastOpen(true);
                  setToastrVariable('success');
                  setToastrId('allNotificationMarkedSuccessMessage');
                  setToastrDefaultMessage(
                    'All notifications are Marked As Read successfully',
                  );
                  setNotificationList((notifications) => {
                    return notifications.map((notification) => {
                      if (!notification.notificationRead) {
                        notification.notificationRead = true;
                      }
                      return notification;
                    });
                  });
                })
                .catch((error) => {
                  toggleLoader(false);
                  setToastOpen(true);
                  setToastrVariable('error');
                  if (isCustomError(error)) {
                    const apiError = error as ApiError;
                    setToastrId(apiError.id);
                    setToastrDefaultMessage(apiError.message);
                  } else {
                    const errorMessage = error as ApiMessage;
                    if (errorMessage.code === 404) {
                      setToastrId('noUnreadNotificationFoundMessage');
                      setToastrDefaultMessage('Failed to update notifications');
                    } else {
                      setToastrId('allNotificationsUpdateFailureMessage');
                      setToastrDefaultMessage('Failed to update notifications');
                    }
                  }
                });
            }
          }}
          labelId1="Clientpage.Yesbtn"
          negativeActionLabel="Yes"
          labelId2="Clientpage.Nobtn"
          positiveActionLabel="No"
        />
      )}
      <Box component="main">
        <SnackBarComponent
          open={openSnackBar}
          handleClose={handleCloseSnakBar}
          successOrError={toastrVariable}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
        />
        <Box component="section">
          <Box component="div" className="rowContainer">
            <CustomBackArrow onClick={() => window.history.back()} />
            <Grid container className="ml-md">
              <Grid item xs={6} lg={6}>
                <Box className="flex__  align__items__center">
                  <MediumTypography
                    labelid={'notificationListText'}
                    defaultlabel={'Notifications Center'}
                    fontSize="30px"
                    fontweight={600}
                    sxProps={{ lineHeight: '40px' }}
                    textColor="#2A4241"
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={6}
                lg={6}
                className="flex__ align__items__center justifyContent-FlexEnd"
              >
                <CheckBoxComponent
                  name="notificationRead"
                  labelid="notificationUnreadText"
                  defaultlabelid="Display Unread Notifications"
                  ischecked={unread == false ? true : false}
                  CheckHandleChange={(
                    event: React.ChangeEvent<HTMLInputElement>,
                  ) => {
                    setPageNumber(0);
                    const checked =
                      event.target.checked === true ? false : null;
                    setUnread(checked);
                  }}
                />
                {notificationList.length > 0 && (
                  <Grid
                    item
                    xs={3}
                    lg={3}
                    className="flex__ align__items__center justifyContent-FlexEnd"
                  >
                    <Box
                      className="flex__ align__items__center cursorPointer"
                      onClick={() => setShowConfirmationModal(true)}
                    >
                      <DoubleTick />
                      <MediumTypography
                        className="ml-xs"
                        labelid="markAllAsReadText"
                        defaultlabel="Mark All As Read"
                        sxProps={{
                          fontFamily: 'Lato-Bold',
                        }}
                      />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
          {notificationList.length > 0 && (
            <Box component="div">
              <Card className="formCardview">
                {notificationList.map((items, index) => {
                  return (
                    <>
                      <Box component="div" className="flex__ cursorPointer">
                        <Box
                          sx={{ flexGrow: 1, display: 'inline-flex' }}
                          className="cursorPointer"
                        >
                          <Box>
                            {items.notificationRead === false ? (
                              <NotificationWithoutBell />
                            ) : (
                              <NotifficationRead />
                            )}
                          </Box>

                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '6px',
                            }}
                            onClick={() => {
                              notificationRead(items);
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '6px',
                              }}
                            >
                              {items.notificationRead === false ? (
                                <>
                                  <MediumTypography
                                    label={`${items.body}.`}
                                    fontSize="14px"
                                    fontweight={600}
                                    textColor="#2A4241"
                                    sxProps={{ marginLeft: '16px' }}
                                  />
                                </>
                              ) : (
                                <MediumTypography
                                  label={`${items.body}.`}
                                  fontSize="14px"
                                  fontweight={500}
                                  textColor="#2A4241"
                                  sxProps={{ marginLeft: '16px' }}
                                />
                              )}
                              <MediumTypography
                                label={'View'}
                                fontSize="14px"
                                fontweight={500}
                                className="cursorPointer"
                                textColor="#008C82"
                                sxProps={{ marginLeft: '0px' }}
                              />
                            </Box>

                            <MediumTypography
                              label={moment(items.createdOn).format(
                                'MM/DD/YYYY hh:mm:ss a',
                              )}
                              fontSize="14px"
                              fontweight={500}
                              textColor="#97A6A5"
                              sxProps={{ marginLeft: '16px' }}
                            />
                          </Box>
                        </Box>

                        <Box className={'flex__ alignItemCenter'}>
                          <ActionMenu
                            rootView={
                              <MoreIcon style={{ margin: '4px 8px 0px 8px' }} />
                            }
                            items={[
                              {
                                labelId: items.notificationRead
                                  ? 'markAsUnreadText'
                                  : 'markAsReadText',
                                defaultLabelId: items.notificationRead
                                  ? 'Mark As Unread'
                                  : 'Mark As Read',
                                visible: true,
                              },
                              {
                                labelId: 'deleteText',
                                defaultLabelId: 'Delete',
                                visible: true,
                                itemTextColor: 'var(--thom-radical-red)',
                              },
                            ]}
                            onItemClick={(i) => {
                              if (i === 0) {
                                updateNotification(
                                  items.id,
                                  items.notificationRead,
                                );
                              } else if (i === 1) {
                                deleteNotification(items.id);
                              }
                            }}
                          />
                        </Box>
                      </Box>
                      {notificationList.length - 1 !== index && (
                        <Box
                          className="horizontalLine"
                          sx={{ marginY: '8px', width: '100%' }}
                        />
                      )}
                    </>
                  );
                })}
              </Card>
              {paginationCount > 10 && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <AppPagination
                    pageNumber={pageNumber}
                    paginationCount={paginationCount}
                    handleChangePage={(_event, newPage) => {
                      if (newPage - 1 !== pageNumber) {
                        setPageNumber(newPage - 1);
                      }
                    }}
                  />
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </LoaderContext.Provider>
  );
};

export default NotificationList;
