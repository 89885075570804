import React, { FC, RefObject, useEffect, useState } from 'react';
import {
  getCurrentMonthDateTimeStart,
  getCurrentWeekSundayAsDate,
  getMonthDateTimeStart,
  getWeekSundayAsDate,
  isCurrentDateBetween,
} from '../../utils/utilities';
import { Box, Grid } from '@mui/material';
import MediumTypography from './MediumTypography';
import './DateRangeComponent.css';
import moment from 'moment';
import dayjs from 'dayjs';

interface DateRange {
  startDate: Date;
  endDate: Date;
}

//viewType: 'timeGridDay' | 'timeGridWeek' | 'dayGridMonth'
interface DateRangeProp {
  viewType: string;
  onDateRangeChange: (startDate: Date, endDate?: Date) => void;
  dataRef: RefObject<{ startDate: Date | null; endDate: Date | null }>;
}

const DateRangeComponent: FC<DateRangeProp> = (props) => {
  const { viewType, onDateRangeChange, dataRef } = props;
  const [dateRange, setDateRange] = useState<DateRange>();

  useEffect(() => {
    let startDate =
      dataRef.current === null || dataRef.current.startDate === null
        ? new Date()
        : dataRef.current.startDate;
    if (
      dataRef.current !== null &&
      dataRef.current.startDate !== null &&
      dataRef.current.endDate !== null
    ) {
      if (isMonthView()) {
        startDate = getMonthDateTimeStart(
          isCurrentDateBetween(
            dataRef.current.startDate,
            dataRef.current.endDate,
          )
            ? new Date()
            : dataRef.current.startDate,
        );
      } else if (isWeekView()) {
        const currentDate = dayjs();
        const calenderDate = dayjs(dataRef.current.startDate);
        if (currentDate.month() === calenderDate.month()) {
          startDate = getWeekSundayAsDate(new Date());
        } else {
          startDate = getWeekSundayAsDate(dataRef.current.startDate);
        }
        // const momentDate1 = moment(dataRef.current.startDate);
        // const momentDate2 = moment(dataRef.current.endDate);
        // const diffDays = momentDate2.diff(momentDate1, "days");
        // if(diffDays < 6) {
        //   startDate = getWeekSundayAsDate(isCurrentDateBetween(dataRef.current.startDate, dataRef.current.endDate)? new Date() : dataRef.current.startDate);
        // } else {
        //   const end = new Date(dataRef.current.startDate);
        //   end.setMonth(end.getMonth() + 1);
        //   end.setDate(end.getDate() - 1);
        //   startDate = getWeekSundayAsDate(isCurrentDateBetween(dataRef.current.startDate, end)? new Date() : dataRef.current.startDate);
        // }
      } else {
        const momentDate1 = moment(dataRef.current.startDate);
        const momentDate2 = moment(dataRef.current.endDate);
        const diffDays = momentDate2.diff(momentDate1, 'days');
        if (diffDays < 6) {
          startDate = isCurrentDateBetween(
            dataRef.current.startDate,
            dataRef.current.endDate,
          )
            ? new Date()
            : dataRef.current.startDate;
        } else if (diffDays === 7) {
          const end = new Date(dataRef.current.startDate);
          end.setDate(end.getDate() + 6);
          startDate = isCurrentDateBetween(dataRef.current.startDate, end)
            ? new Date()
            : dataRef.current.startDate;
        } else {
          const end = new Date(dataRef.current.startDate);
          end.setMonth(end.getMonth() + 1);
          end.setDate(end.getDate() - 1);
          startDate = isCurrentDateBetween(dataRef.current.startDate, end)
            ? new Date()
            : dataRef.current.startDate;
        }
      }
    } else {
      if (isMonthView()) {
        startDate = getCurrentMonthDateTimeStart();
      } else if (isWeekView()) {
        startDate = getCurrentWeekSundayAsDate();
      }
    }
    startDate.setHours(0);
    startDate.setMinutes(0);
    startDate.setSeconds(0);
    const endDate = new Date(startDate);
    if (isWeekView()) {
      endDate.setDate(endDate.getDate() + 6);
    } else if (isMonthView()) {
      endDate.setMonth(endDate.getMonth() + 1);
    } else {
      endDate.setDate(endDate.getDate() + 1);
    }
    setDateRange({ startDate: startDate, endDate: endDate });
    const endDateToPass = new Date(endDate);
    endDateToPass.setDate(endDateToPass.getDate() + 1);
    if (dataRef.current !== null) {
      dataRef.current.startDate = startDate;
      dataRef.current.endDate = endDateToPass;
    }
  }, [viewType]);

  const handlePrev = () => {
    if (!dateRange) return;
    const newStartDate = new Date(dateRange.startDate);
    const newEndDate = new Date(dateRange.endDate);

    if (isWeekView()) {
      newStartDate.setDate(newStartDate.getDate() - 7);
      newEndDate.setDate(newEndDate.getDate() - 7);
    } else if (isMonthView()) {
      newStartDate.setMonth(newStartDate.getMonth() - 1);
      newEndDate.setMonth(newEndDate.getMonth() - 1);
    } else {
      newStartDate.setDate(newStartDate.getDate() - 1);
      newEndDate.setDate(newEndDate.getDate() - 1);
    }

    setDateRange({ startDate: newStartDate, endDate: newEndDate });
    const endDateToPass = new Date(newEndDate);
    endDateToPass.setDate(endDateToPass.getDate() + 1);
    if (dataRef.current !== null) {
      dataRef.current.startDate = newStartDate;
      dataRef.current.endDate = endDateToPass;
    }
    onDateRangeChange(newStartDate, endDateToPass);
  };

  const handleNext = () => {
    if (!dateRange) return;
    const newStartDate = new Date(dateRange.startDate);
    const newEndDate = new Date(dateRange.endDate);

    if (isWeekView()) {
      newStartDate.setDate(newStartDate.getDate() + 7);
      newEndDate.setDate(newEndDate.getDate() + 7);
    } else if (isMonthView()) {
      newStartDate.setMonth(newStartDate.getMonth() + 1);
      newEndDate.setMonth(newEndDate.getMonth() + 1);
    } else {
      newStartDate.setDate(newStartDate.getDate() + 1);
      newEndDate.setDate(newEndDate.getDate() + 1);
    }

    setDateRange({ startDate: newStartDate, endDate: newEndDate });
    const endDateToPass = new Date(newEndDate);
    endDateToPass.setDate(endDateToPass.getDate() + 1);
    if (dataRef.current !== null) {
      dataRef.current.startDate = newStartDate;
      dataRef.current.endDate = endDateToPass;
    }
    onDateRangeChange(newStartDate, endDateToPass);
  };

  const isWeekView = () => {
    // Implement your logic to determine if it's week view
    // Return true if it's week view, false otherwise
    return viewType === 'timeGridWeek' || viewType === 'listWeek';
  };

  const isMonthView = () => {
    // Implement your logic to determine if it's month view
    // Return true if it's month view, false otherwise
    return viewType === 'dayGridMonth';
  };

  const isDayView = () => {
    // Implement your logic to determine if it's day view
    // Return true if it's day view, false otherwise
    return viewType === 'timeGridDay';
  };

  const formatDate = (date: Date): string => {
    if (isMonthView())
      return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
    else
      return date.toLocaleString('en-US', {
        month: 'long',
        year: 'numeric',
        day: 'numeric',
      });
  };

  const getDates = () => {
    if (!dateRange) return;
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    if (isDayView() || isMonthView()) return formatDate(startDate);
    else return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };

  return (
    <Grid container direction="row" className="toolbar">
      <Box onClick={handlePrev} className="previousdate"></Box>
      <Box className="middlelayer">
        <MediumTypography label={getDates()} />
      </Box>
      <Box className="nextdate" onClick={handleNext}></Box>
    </Grid>
  );
};

export default DateRangeComponent;
