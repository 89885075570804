import {
  AlertColor,
  Box,
  Card,
  Grid,
  autocompleteClasses,
} from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import TitleText from '../../components/formlib/TitleText';
import { Formik, FormikProps } from 'formik';
import { ReactComponent as GoTo } from '../../assets/images/goTo.svg';
import MediumTypography from '../../components/formlib/MediumTypography';
import RadioComponent from '../../components/formlib/RadioComponent';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import dayjs, { Dayjs } from 'dayjs';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import { useIntl } from 'react-intl';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import * as yup from 'yup';
import SmallTypography from '../../components/formlib/SmallTypography';
import {
  EligibilityDeterminationOthers,
  EligibilityDeterminationType,
} from '../eligibility/types';
import { useLocation, useNavigate } from 'react-router';
import ProgramPanel from '../../components/forms/ProgramPanel';
import SignatureComponent from '../../components/formlib/modal/SignatureComponent';
import { ReactComponent as Editsignature } from '../../assets/images/editsignature.svg';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import {
  addOrUpdateEligibilityDeterminationApi,
  getEligibilityDeterminationFormApi,
} from '../../services/configApi/eligibility/eligibilityDetermination';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import {
  BATELLE_DEVELOPMENT_INVENTORY_2,
  CLINICAL_JUDGEMENT_ROUTE,
} from '../../routes/Routing';
import ModalPopup from '../../components/formlib/ModalPopup';
import _ from 'lodash';
import AutocompleteSearch from '../../components/formlib/AutocompleteSearch';
import { OptionType } from '../../utils/type';
import {
  DiagnosisCode,
  getCodesAndDescriptionNew,
} from '../../services/configApi/codelist/diagnosticCodes';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { datePickerMinDateMaxDateValidate } from '../../utils/dateUtil';
import MultipleAutocompleteSearch from '../../components/formlib/MultipleAutocompleteSearch';
import { DiagnosisOptionType } from '../faceSheet/FaceSheetScreen';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

const initialEligibilityDeterminationValues = {
  id: '',
  dateOfEvaluation: null,
  medicalDiagnosisEligibility: null,
  notificationDate: null,
  medicalDiagnosisType: '',
  otherMedicalDiagnosis: '',
  bdiEligibility: null,
  riskFactorEligibility: null,
  pursueClinicalEligibility: null,
  eligibilitySummaryNarrative: '',
  signature: {
    signatureData: '',
    partitionKey: '',
    rowKey: '',
    id: '',
    isUpdated: false,
  },
  isOneYearDiagnosis: false,
  isThreeYearDiagnosis: false,
  isBdiTwoEligible: false,
  isRiskFactorEligible: false,
  isClinicalJudgementEligible: false,
};

const EligibilityDeterminationForm = () => {
  const rctl = useIntl();
  const location = useLocation();
  const [evaluationId] = useState<string>(location.state?.evaluationId);
  const [uploadedToTBR] = useState<boolean>(location.state?.isUploadedToTBR);
  const formikRef = useRef<FormikProps<EligibilityDeterminationType>>(null);
  const [eligibilityFormInitialValue, setEligibilityFormInitialValue] =
    useState<EligibilityDeterminationType | null>(null);
  const [eligibilityForm, setEligibilityForm] =
    useState<EligibilityDeterminationOthers | null>(null);
  const [signClicked, setSignClicked] = useState<boolean>(false);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [base64Signature, setBase64Signature] = useState('');
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const { handleUpdateUnsavedChanges, hasUnsavedChanges } = useContext(
    UnSavedChangesContext,
  );
  const [signaturePartitionKey, setSignaturePartitionKey] =
    useState<string>('');
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);
  const [selectedDiagnosisCodesList, setSelectedDiagnosisCodesList] =
    useState<OptionType | null>(null);
  const [diagnosisCodesList, setDiagnosisCodesList] = useState<
    DiagnosisOptionType[]
  >([]);
  const [isScreenLocked, setIsScreenLocked] = useState<boolean>(false);
  const [isResteClick, setResetClick] = useState<boolean>(false);
  const [diagnosisRequied, setDiagnosisRequired] = useState<boolean>(false);
  const validationSchema = yup.object().shape({
    medicalDiagnosisEligibility: yup
      .boolean()
      .nullable()
      .required('diagnosisEligibility.required'),
    medicalDiagnosisType: yup
      .string()
      .nullable()
      .when('medicalDiagnosisEligibility', {
        is: true,
        then: yup.string().required('diagnosisType.required').nullable(),
        otherwise: yup.string().nullable(),
      }),
    otherMedicalDiagnosis: yup.string().nullable(),
    bdiEligibility: yup
      .boolean()
      .nullable()
      .required('bdiEligibility.required'),
    riskFactorEligibility: yup
      .boolean()
      .nullable()
      .required('riskFactorEligibility.required'),
    pursueClinicalEligibility: yup
      .boolean()
      .nullable()
      .required('pursueClinicalEligibility.required'),
    notificationDate: yup.date().nullable(),
    eligibilitySummaryNarrative: yup.string().nullable(),
    eligibilityDate: yup.date().nullable(),
    isThreeYearDiagnosis: yup.boolean().nullable(),
    isBdiTwoEligible: yup.boolean().nullable(),
    isRiskFactorEligible: yup.boolean().nullable(),
    isClinicalJudgementEligible: yup.boolean().nullable(),
  });

  const validateForm = (value: EligibilityDeterminationType) => {
    const errors: Partial<EligibilityDeterminationType> = {};

    if (
      datePickerMinDateMaxDateValidate(value.dateOfEvaluation) ||
      datePickerMinDateMaxDateValidate(value.notificationDate)
    ) {
      errors.dateOfEvaluation = 'datePickerMinDateMaxDateValidate';
      errors.notificationDate = 'datePickerMinDateMaxDateValidate';
    }
    return errors;
  };

  useEffect(() => {
    if (eligibilityForm && eligibilityForm.bdiEligibility === null) {
      if (
        location.state?.evaluationStatus &&
        location.state.evaluationStatus === 'Eligible'
      ) {
        eligibilityForm.bdiEligibility = true;
        setEligibilityForm(eligibilityForm);
      }
    }
  }, [eligibilityForm]);

  const navigate = useNavigate();
  useEffect(() => {
    setSignaturePartitionKey(signaturePartitionKey);
  }, [signaturePartitionKey]);

  const getEligibilityDeterminationData = async (
    filteredDiagnosisCodeList: DiagnosisOptionType[],
  ) => {
    toggleLoader(true);
    getEligibilityDeterminationFormApi(evaluationId)
      .then((response: EligibilityDeterminationOthers) => {
        setDiagnosisRequired(
          response.medicalDiagnosisEligibility !== null
            ? response.medicalDiagnosisEligibility
            : false,
        );
        const checkCode = (code: string | null, array: OptionType[]) => {
          const found = array.find((item) => item.id === code);
          return found ? found.label.includes('*') : false;
        };
        const result = checkCode(
          response.medicalDiagnosisType,
          filteredDiagnosisCodeList,
        );
        toggleLoader(false);
        setEligibilityForm(response);
        setSignaturePartitionKey(response.signature.partitionKey);
        const filteredDiagnosisList: DiagnosisOptionType[] = [];
        filteredDiagnosisCodeList.forEach((codeDetails) => {
          if (codeDetails.id === response.medicalDiagnosisType) {
            filteredDiagnosisList.push(codeDetails);
          }

          response.otherMedicalDiagnosis.split(',').forEach((code: string) => {
            if (codeDetails.id === code) {
              filteredDiagnosisList.push(codeDetails);
            }
          });

          if (!codeDetails.isDeleted) {
            filteredDiagnosisList.push(codeDetails);
          }
        });
        // remove duplicates in filteredDiagnosisList
        const uniqueDiagnosisList = filteredDiagnosisList.filter(
          (item, index) => {
            return filteredDiagnosisList.indexOf(item) === index;
          },
        );

        uniqueDiagnosisList.sort((a, b) => {
          if (a.id < b.id) return -1;
          if (a.id > b.id) return 1;
          return 0;
        });

        const soertedLIst = uniqueDiagnosisList;

        setDiagnosisCodesList(soertedLIst);

        const selected: OptionType | undefined = uniqueDiagnosisList.find(
          (d) => d.id === response.medicalDiagnosisType,
        );
        if (selected) setSelectedDiagnosisCodesList(selected);
        formikRef.current?.setValues((values) => ({
          ...values,
          id: response.id,
          medicalDiagnosisEligibility: response.medicalDiagnosisEligibility,
          notificationDate:
            response.notificationDate !== null
              ? dayjs(response.notificationDate).format('YYYY-MM-DD')
              : dayjs(new Date()).format('YYYY-MM-DD'),
          medicalDiagnosisType: response.medicalDiagnosisType,
          otherMedicalDiagnosis: response.otherMedicalDiagnosis.toString(),
          bdiEligibility: response.bdiEligibility,
          riskFactorEligibility: response.riskFactorEligibility,
          pursueClinicalEligibility: response.pursueClinicalEligibility,
          eligibilitySummaryNarrative: response.eligibilitySummaryNarrative,
          dateOfEvaluation:
            response.dateOfEvaluation !== null
              ? dayjs(response.dateOfEvaluation).format('YYYY-MM-DD')
              : dayjs(new Date()).format('YYYY-MM-DD'),
          signature: {
            ...values.signature,
            signatureData: response.signature.signatureData,
            isUpdated: false,
            partitionKey: response.signature.partitionKey,
            rowKey: response.signature.rowKey,
          },
          isOneYearDiagnosis: response.medicalDiagnosisType
            ? result
            : response.isOneYearDiagnosis,
          isThreeYearDiagnosis: response.medicalDiagnosisType
            ? result
            : response.isThreeYearDiagnosis,
          isBdiTwoEligible: response.isBdiTwoEligible,
          isRiskFactorEligible: response.isRiskFactorEligible,
          isClinicalJudgementEligible: response.isClinicalJudgementEligible,
        }));
        setTimeout(() => {
          if (formikRef.current?.values) {
            setEligibilityFormInitialValue(formikRef.current?.values);
          }
        }, 1000);
      })
      .catch((error) => {
        toggleLoader(false);
        setOpenSnackBar(true);

        setToastrVariable('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          setToastrId('loadFailureMessage');
          setToastrDefaultMessage('Failed to get details');
        }
      });
  };

  const onCancelClick = () => {
    setSignClicked(false);
  };

  const handleSuccess = (successError: AlertColor, id: string, mes: string) => {
    setOpenSnackBar(true);
    setToastrVariable(successError);
    setToastrId(id);
    setToastrDefaultMessage(mes);
  };

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  const signatureSaveOrUPdate = (signed: string) => {
    setBase64Signature(signed);
    setSignClicked(!signClicked);
    setResetClick(false);
    formikRef.current?.setValues((values) => ({
      ...values,
      signature: {
        ...values.signature,
        signatureData: signed,
        isUpdated: true,
      },
    }));
  };

  const submitEligibilityDeterminationResponse = async (
    vals: EligibilityDeterminationType,
  ) => {
    toggleLoader(true);
    if (isResteClick === true) {
      vals.signature.signatureData = '';
      vals.signature.partitionKey = '';
      vals.signature.rowKey = '';
      vals.signature.isUpdated = true;
    }
    if (eligibilityForm) {
      const obj: EligibilityDeterminationOthers = {
        client: eligibilityForm.client,
        parent: eligibilityForm.parent,
        program: eligibilityForm.program,
        ...vals,
      };
      addOrUpdateEligibilityDeterminationApi(evaluationId, obj)
        .then(() => {
          toggleLoader(false);
          setOpenSnackBar(true);
          setToastrVariable('success');

          if (vals.id) {
            setToastrId('eligibilityDetermination.updateText');
            setToastrDefaultMessage(
              'Eligibility Determination Updated Successfully',
            );
          } else {
            setToastrId('eligibilityDetermination.successText');
            setToastrDefaultMessage(
              'Eligibility Determination Saved Successfully',
            );
          }
          getEligibilityDeterminationData(diagnosisCodesList);
        })
        .catch((error) => {
          toggleLoader(false);
          setOpenSnackBar(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('eligibilityDetermination.errorText');
            setToastrDefaultMessage(
              'Failed to update Eligibility Determination',
            );
          }
        });
    }
  };

  const resetCallBackForTypeEmployee = () => {
    setSignClicked(!signClicked);
    setResetClick(true);

    formikRef.current?.setValues((values) => ({
      ...values,
      signature: {
        ...values.signature,
        signatureData: '',
        isUpdated: true,
        partitionKey: '',
        rowKey: '',
      },
    }));
  };

  useEffect(() => {
    if (checkPermissionForFeature('backend.clients', 'editPermission')) {
      setIsScreenLocked(false);
    } else {
      setIsScreenLocked(true);
    }
  }, []);

  const parentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleDiagnosisDataGetAPI = async () => {
      toggleLoader(true);
      getCodesAndDescriptionNew()
        .then((response: DiagnosisCode[]) => {
          const diagnosisCodeList: DiagnosisOptionType[] = [];
          if (response.length > 0) {
            response.forEach((codeDetails) => {
              if (codeDetails.qualifyingDiagnosis) {
                diagnosisCodeList.push({
                  id: codeDetails.code,
                  label: codeDetails.oneYearEligibility
                    ? `${codeDetails.code} - * ${codeDetails.description}`
                    : `${codeDetails.code} - ${codeDetails.description}`,
                  isDeleted: codeDetails.isDeleted
                    ? codeDetails.isDeleted
                    : false,
                });
              }
            });
            getEligibilityDeterminationData(diagnosisCodeList);
          }
        })
        .catch((error) => {
          toggleLoader(false);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    };

    handleDiagnosisDataGetAPI();
  }, []);
  return (
    <Box component="main">
      <ModalPopup
        description="formUnsavedChangesMessage"
        open={showCancelDialog}
        onCancel={() => {
          setShowCancelDialog(false);
        }}
        onOk={() => {
          if (eligibilityFormInitialValue !== null) {
            formikRef.current?.setValues(eligibilityFormInitialValue);
            setShowCancelDialog(false);
          }
        }}
        labelId1="Clientpage.Nobtn"
        negativeActionLabel="No"
        labelId2="Clientpage.Yesbtn"
        positiveActionLabel="Yes"
      />
      <SnackBarComponent
        open={openSnackBar}
        handleClose={handleCloseSnakBar}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <Box component="section">
        <Box component="div" className="rowContainer">
          <CustomBackArrow
            onClick={() => navigate(BATELLE_DEVELOPMENT_INVENTORY_2)}
          />
          <Grid container>
            <Grid item xs={6} lg={6}>
              <Box className="flex__  align__items__center">
                <TitleText
                  labelid="eligibilityDetermination_text"
                  defaultlabel="Eligibility Determination"
                  Sxprops={{ ml: 1 }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={6}
              lg={6}
              className="flex__ align__items__center justifyContent-FlexEnd"
            ></Grid>
          </Grid>
        </Box>
        <div
          ref={parentRef}
          style={{
            pointerEvents: isScreenLocked || uploadedToTBR ? 'none' : 'auto',
            opacity: isScreenLocked || uploadedToTBR ? '0.5' : '1 ',
          }}
          onKeyDownCapture={(e) => {
            if (isScreenLocked) {
              e.preventDefault();
              e.stopPropagation();
            }
          }}
          onFocus={() => {
            if (isScreenLocked) {
              if (parentRef.current) parentRef.current.focus();
            }
          }}
        >
          <Box component="div">
            <Formik
              initialValues={initialEligibilityDeterminationValues}
              validateOnMount={true}
              innerRef={formikRef}
              validateOnChange={true}
              enableReinitialize
              validationSchema={validationSchema}
              validate={validateForm}
              onSubmit={(values) => {
                values.signature.signatureData = base64Signature;

                submitEligibilityDeterminationResponse(values);
              }}
            >
              {({
                values,
                errors,
                touched,
                // initialValues,
                setFieldValue,
                handleSubmit,
              }) => {
                useEffect(() => {
                  if (_.isEqual(eligibilityFormInitialValue, values)) {
                    handleUpdateUnsavedChanges(false);
                  } else {
                    handleUpdateUnsavedChanges(true);
                  }
                }, [values, eligibilityFormInitialValue, hasUnsavedChanges]);
                return (
                  <>
                    <Card className="formCardview">
                      {eligibilityForm && (
                        <ProgramPanel
                          program={eligibilityForm.program}
                          client={eligibilityForm.client}
                          parent={eligibilityForm.parent}
                          showIntakeFacilitator={true}
                        />
                      )}

                      <Box component="div">
                        <Box
                          className="horizontalLine"
                          sx={{ marginY: '8px' }}
                        />
                      </Box>
                      <Box component="div">
                        <Grid container rowSpacing={'24px'} className="pt-md">
                          <Grid item xs={4}>
                            {values.dateOfEvaluation !== null && (
                              <DatePickerComponent
                                disabledDate={true}
                                labelid="eligibilityDetermination.eligibilityDate"
                                disableFuture={false}
                                defaultlabelid="Date of Evaluation"
                                value={
                                  values.dateOfEvaluation
                                    ? dayjs(values.dateOfEvaluation)
                                    : dayjs(new Date())
                                }
                                handlechange={(datevalue: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(datevalue).format('YYYY-MM-DD');
                                  if (datevalue === null) {
                                    setFieldValue('eligibilityDate', null);
                                  } else {
                                    setFieldValue(
                                      'eligibilityDate',
                                      formattedDate,
                                    );
                                  }
                                }}
                              />
                            )}
                            {values.dateOfEvaluation === null && (
                              <DatePickerComponent
                                disabledDate={
                                  uploadedToTBR || signaturePartitionKey !== ''
                                }
                                labelid="eligibilityDetermination.eligibilityDate"
                                disableFuture={false}
                                defaultlabelid="Date of Evaluation"
                                disableManualInput={
                                  signaturePartitionKey !== ''
                                }
                                value={null}
                                handlechange={(datevalue: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(datevalue).format('YYYY-MM-DD');
                                  if (datevalue === null) {
                                    setFieldValue('eligibilityDate', null);
                                  } else {
                                    setFieldValue(
                                      'eligibilityDate',
                                      formattedDate,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Grid>
                          <Grid item xs={0.3}></Grid>
                          <Grid item xs={4}>
                            {values.notificationDate !== null && (
                              <DatePickerComponent
                                disabledDate={
                                  uploadedToTBR || signaturePartitionKey !== ''
                                }
                                labelid="eligibilityDetermination.notificationDateText"
                                disableFuture={false}
                                disableManualInput={
                                  signaturePartitionKey !== ''
                                }
                                defaultlabelid="Notification Date"
                                value={dayjs(values.notificationDate)}
                                handlechange={(datevalue: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(datevalue).format('YYYY-MM-DD');
                                  if (datevalue === null) {
                                    setFieldValue('notificationDate', null);
                                  } else {
                                    setFieldValue(
                                      'notificationDate',
                                      formattedDate,
                                    );
                                  }
                                }}
                              />
                            )}

                            {values.notificationDate === null && (
                              <DatePickerComponent
                                disabledDate={
                                  uploadedToTBR || signaturePartitionKey !== ''
                                }
                                labelid="eligibilityDetermination.notificationDateText"
                                disableFuture={false}
                                disableManualInput={
                                  signaturePartitionKey !== ''
                                }
                                defaultlabelid="Notification Date"
                                value={null}
                                handlechange={(datevalue: Dayjs | null) => {
                                  const formattedDate =
                                    dayjs(datevalue).format('YYYY-MM-DD');
                                  if (datevalue === null) {
                                    setFieldValue('notificationDate', null);
                                  } else {
                                    setFieldValue(
                                      'notificationDate',
                                      formattedDate,
                                    );
                                  }
                                }}
                              />
                            )}
                          </Grid>
                        </Grid>
                        <Grid
                          container
                          rowSpacing={'16px'}
                          columnSpacing={'40px'}
                        >
                          <Grid
                            item
                            xs={4.5}
                            lg={4.5}
                            sx={{ marginY: '5px' }}
                          ></Grid>

                          <Grid item xs={12} lg={12}>
                            <MediumTypography
                              labelid="eligibilityDetermination.diagnosisEligibility"
                              defaultlabel="Medical Diagnosis Eligibility (Evaluation Diagnosis)"
                              fontweight={600}
                              fontSize="16px"
                              textColor="#2A4241"
                            />
                          </Grid>
                          <Grid item xs={4.5}>
                            <RadioComponent
                              disable={
                                uploadedToTBR || signaturePartitionKey !== ''
                              }
                              value={values.medicalDiagnosisEligibility}
                              name="multipleBirth"
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                if (JSON.parse(e.target.value) === true) {
                                  setDiagnosisRequired(true);
                                } else {
                                  setDiagnosisRequired(false);
                                  setSelectedDiagnosisCodesList(null);
                                  setFieldValue('medicalDiagnosisType', '');
                                  setFieldValue('isOneYearDiagnosis', false);
                                  setFieldValue('isThreeYearDiagnosis', false);
                                  setFieldValue('otherMedicalDiagnosis', '');
                                }
                                setFieldValue(
                                  'medicalDiagnosisEligibility',
                                  e.target.value,
                                );
                              }}
                              labelid=""
                              defaultlabelid=""
                              labelidA="eligibilityDetermination.eligibleText"
                              defaultlabelidA="Eligible"
                              labelidB="eligibilityDetermination.nonEligibleText"
                              defaultlabelidB="Not Eligible"
                            />
                            {errors.medicalDiagnosisEligibility &&
                              touched.medicalDiagnosisEligibility && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.medicalDiagnosisEligibility}
                                  defaultLabelId="Medical Diagnosis Eligibility is required"
                                />
                              )}
                          </Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>

                          <Grid item xs={9} lg={9}>
                            <AutocompleteSearch
                              disabled={
                                uploadedToTBR ||
                                !diagnosisRequied ||
                                signaturePartitionKey !== ''
                              }
                              renderOption={(props, option: OptionType) => {
                                const parts = option.label.split(' - ');
                                let beforeHyphen = '';
                                let afterHyphen = '';

                                if (parts.length > 1) {
                                  beforeHyphen = parts[0];
                                  afterHyphen = parts?.slice(1)?.join(' - ');
                                } else {
                                  beforeHyphen = option.label;
                                }

                                const underlineStyle = afterHyphen.includes('*')
                                  ? 'underline'
                                  : 'none';

                                return (
                                  <li {...props}>
                                    {parts.length > 1 ? (
                                      <>
                                        <span>{beforeHyphen} - </span>
                                        <span
                                          style={{
                                            textDecoration: underlineStyle,
                                          }}
                                        >
                                          {afterHyphen}
                                        </span>
                                      </>
                                    ) : (
                                      <span
                                        style={{
                                          textDecoration: underlineStyle,
                                        }}
                                      >
                                        {beforeHyphen}
                                      </span>
                                    )}
                                  </li>
                                );
                              }}
                              textDecoration={
                                selectedDiagnosisCodesList?.label.includes(
                                  '- *',
                                )
                                  ? 'underline'
                                  : 'none'
                              }
                              Required={diagnosisRequied}
                              labelId={
                                'eligibilityDetermination.selectDiagnosisText'
                              }
                              defaultId="Select Evaluation Diagnosis *"
                              optionHintId={'noDiagnosisDropdownTextId'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                },
                                backgroundColor: 'white',
                              }}
                              data={diagnosisCodesList}
                              selectedValue={
                                selectedDiagnosisCodesList
                                  ? selectedDiagnosisCodesList
                                  : { id: '', label: '' }
                              }
                              onSelectItem={(value: OptionType | null) => {
                                if (value === null) {
                                  setSelectedDiagnosisCodesList(null);
                                  setFieldValue('medicalDiagnosisType', '');
                                  setFieldValue('isOneYearDiagnosis', false);
                                  setFieldValue('isThreeYearDiagnosis', false);
                                } else {
                                  const hasStarPrefix =
                                    value.label.includes('- *');
                                  setSelectedDiagnosisCodesList(value);
                                  setFieldValue(
                                    'medicalDiagnosisType',
                                    value.id,
                                  );
                                  if (hasStarPrefix) {
                                    setFieldValue('isOneYearDiagnosis', true);
                                    setFieldValue(
                                      'isThreeYearDiagnosis',
                                      false,
                                    );
                                  } else {
                                    setFieldValue('isThreeYearDiagnosis', true);
                                    setFieldValue('isOneYearDiagnosis', false);
                                  }
                                }
                              }}
                              disableLabel={false}
                            />
                            {errors.medicalDiagnosisType &&
                              touched.medicalDiagnosisType && (
                                <MediumTypography
                                  labelid={errors.medicalDiagnosisType}
                                  className="errorText-md"
                                />
                              )}
                          </Grid>

                          <Grid item xs={9} lg={9} className="mt-sm">
                            <MultipleAutocompleteSearch
                              disabled={
                                uploadedToTBR ||
                                !diagnosisRequied ||
                                signaturePartitionKey !== ''
                              }
                              labelId="IntakeCurrentHealth.othermedicalDiagnosis"
                              defaultId="Other Known Medical Diagnosis"
                              data={diagnosisCodesList}
                              type="string"
                              onSelectItem={(value: OptionType[] | null) => {
                                if (value) {
                                  const selectedMedicalDiagnosisIds = value.map(
                                    (r) => r.id,
                                  );
                                  setFieldValue(
                                    'otherMedicalDiagnosis',
                                    selectedMedicalDiagnosisIds.toString(),
                                  );
                                }
                              }}
                              disableLabel={true}
                              optionHintId={'No Medical Diagnosis found'}
                              sxProps={{
                                [`& .${autocompleteClasses.popupIndicator}`]: {
                                  transform: 'none',
                                  marginTop: '2px',
                                },
                                backgroundColor: '#fff',
                              }}
                              value={values.otherMedicalDiagnosis
                                .split(',') // Split the string by comma
                                .map((r) => ({
                                  id: r,
                                  label:
                                    diagnosisCodesList.find(
                                      (dCl) => dCl.id === r,
                                    )?.label || '', // Set label to empty string if not found
                                }))
                                .filter(
                                  (
                                    option,
                                  ): option is { id: string; label: string } =>
                                    option.label !== '', // Filter out options with empty labels
                                )}
                            />
                            {errors.otherMedicalDiagnosis &&
                              touched.otherMedicalDiagnosis && (
                                <MediumTypography
                                  className="errorText-md"
                                  labelid={errors.otherMedicalDiagnosis}
                                />
                              )}
                          </Grid>

                          <Grid item xs={4.5}></Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>
                          <Grid item xs={12} lg={12}>
                            <MediumTypography
                              labelid="eligibilityDetermination.BdiText"
                              defaultlabel="BDI Eligibility"
                              fontweight={600}
                              fontSize="16px"
                              textColor="#2A4241"
                            />
                          </Grid>
                          <Grid item xs={4.5}>
                            <RadioComponent
                              disable={
                                uploadedToTBR || signaturePartitionKey !== ''
                              }
                              value={values.bdiEligibility}
                              name="multipleBirth"
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                if (JSON.parse(e.target.value) === true) {
                                  setFieldValue('isBdiTwoEligible', true);
                                } else {
                                  setFieldValue('isBdiTwoEligible', false);
                                }

                                setFieldValue(
                                  'bdiEligibility',
                                  JSON.parse(e.target.value),
                                );
                              }}
                              labelid=""
                              defaultlabelid=""
                              labelidA="eligibilityDetermination.eligibleText"
                              defaultlabelidA="Eligible"
                              labelidB="eligibilityDetermination.nonEligibleText"
                              defaultlabelidB="Not Eligible"
                            />
                            {errors.bdiEligibility &&
                              touched.bdiEligibility && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.bdiEligibility}
                                  defaultLabelId="BDI Eligibility is required"
                                />
                              )}
                          </Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>
                          <Grid item xs={4.5}></Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>
                          <Grid item xs={12} lg={12}>
                            <MediumTypography
                              labelid="eligibilityDetermination.riskFactorText"
                              defaultlabel="Risk Factor Eligibility"
                              fontweight={600}
                              fontSize="16px"
                              textColor="#2A4241"
                            />
                          </Grid>
                          <Grid item xs={4.5}>
                            <RadioComponent
                              disable={
                                uploadedToTBR || signaturePartitionKey !== ''
                              }
                              value={values.riskFactorEligibility}
                              name="multipleBirth"
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                if (JSON.parse(e.target.value) === true) {
                                  setFieldValue('isRiskFactorEligible', true);
                                } else {
                                  setFieldValue('isRiskFactorEligible', false);
                                }

                                setFieldValue(
                                  'riskFactorEligibility',
                                  JSON.parse(e.target.value),
                                );
                              }}
                              labelid=""
                              defaultlabelid=""
                              labelidA="eligibilityDetermination.eligibleText"
                              defaultlabelidA="Eligible"
                              labelidB="eligibilityDetermination.nonEligibleText"
                              defaultlabelidB="Not Eligible"
                            />
                            {errors.riskFactorEligibility &&
                              touched.riskFactorEligibility && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.riskFactorEligibility}
                                  defaultLabelId="Risk Factor Eligibility is required"
                                />
                              )}
                          </Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>
                          <Grid item xs={4.5}></Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>
                          <Grid item xs={12} lg={12} display={'flex'}>
                            <MediumTypography
                              labelid="eligibilityDetermination.pursueClinicalText"
                              defaultlabel="Clinical Judgment"
                              fontweight={600}
                              fontSize="16px"
                              textColor="#2A4241"
                            />
                            <Box
                              className="flex__ align__items__center ml-sm"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => {
                                if (
                                  _.isEqual(eligibilityFormInitialValue, values)
                                ) {
                                  navigate(CLINICAL_JUDGEMENT_ROUTE, {
                                    state: {
                                      evaluationId: evaluationId,
                                      isUploadedToTBR: uploadedToTBR,
                                    },
                                  });
                                } else {
                                  setShowCancelDialog(true);
                                }
                              }}
                            >
                              <GoTo />
                            </Box>
                          </Grid>
                          <Grid item xs={4.5}>
                            <RadioComponent
                              disable={
                                uploadedToTBR || signaturePartitionKey !== ''
                              }
                              value={values.pursueClinicalEligibility}
                              name="multipleBirth"
                              handleChange={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                if (JSON.parse(e.target.value) === true) {
                                  setFieldValue(
                                    'isClinicalJudgementEligible',
                                    true,
                                  );
                                } else {
                                  setFieldValue(
                                    'isClinicalJudgementEligible',
                                    false,
                                  );
                                }
                                setFieldValue(
                                  'pursueClinicalEligibility',
                                  JSON.parse(e.target.value),
                                );
                              }}
                              labelid=""
                              defaultlabelid=""
                              labelidA="eligibilityDetermination.eligibleText"
                              defaultlabelidA="Eligible"
                              labelidB="eligibilityDetermination.nonEligibleText"
                              defaultlabelidB="Not Eligible"
                            />
                            {errors.pursueClinicalEligibility &&
                              touched.pursueClinicalEligibility && (
                                <SmallTypography
                                  sxProps={{ color: 'red' }}
                                  labelId={errors.pursueClinicalEligibility}
                                  defaultLabelId="Pursue Clinical Eligibility is required"
                                />
                              )}
                          </Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>
                          <Grid item xs={4.5}></Grid>
                          <Grid item xs={4.5} lg={4.5}></Grid>
                          <Grid
                            item
                            xs={12}
                            lg={12}
                            sx={{ marginBottom: '1%' }}
                          >
                            <MediumTypography
                              labelid="eligibilityDetermination.officialBasisText"
                              defaultlabel="Official Basis for Eligibility"
                              fontweight={600}
                              fontSize="16px"
                              textColor="#2A4241"
                            />
                          </Grid>
                        </Grid>

                        <Box
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '0px',
                          }}
                        >
                          <Box style={{ display: 'flex', gap: '20px' }}>
                            <div style={{ flex: '1 0 33%' }}>
                              <MediumTypography
                                labelid="eligibilityDetermination.eligibilityBasisText"
                                defaultlabel="Eligibility Basis"
                                fontweight={500}
                                fontSize="14px"
                                textColor="#97A6A5"
                              />
                            </div>
                            <div style={{ flex: '1 0 33%' }}>
                              <MediumTypography
                                labelid="eligibilityDetermination.reEvalutationDueText"
                                defaultlabel="Re-evaluation Due"
                                fontweight={500}
                                fontSize="14px"
                                textColor="#97A6A5"
                              />
                            </div>
                            <div style={{ flex: '1 0 33%' }}></div>
                          </Box>

                          <Box style={{ display: 'flex', gap: '20px' }}>
                            <Box style={{ flex: '1 0 33%' }}>
                              <CheckBoxComponent
                                disabled={true}
                                labelid="eligibilityDetermination.3YearDiagnosisText"
                                defaultlabelid="3 Year Diagnosis"
                                fontWeight={400}
                                fontFamily="Lato-Regular"
                                ischecked={values.isThreeYearDiagnosis}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  if (values.isThreeYearDiagnosis === true) {
                                    if (e.target.checked === false) {
                                      setFieldValue(
                                        'isThreeYearDiagnosis',
                                        e.target.checked,
                                      );
                                    } else {
                                      e.preventDefault();
                                    }
                                  } else {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                flex: '1 0 33%',
                                position: 'relative',
                                top: '10px',
                              }}
                            >
                              <MediumTypography
                                labelid="eligibilityDetermination.everyOneYearText"
                                defaultlabel="Every 1 year"
                                fontweight={400}
                                fontSize="14px"
                                textColor="#2A4241"
                              />
                            </Box>
                            <Box style={{ flex: '1 0 33%' }}></Box>
                          </Box>

                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '0px' }}
                          />
                          <Box style={{ display: 'flex', gap: '20px' }}>
                            <Box style={{ flex: '1 0 33%' }}>
                              <CheckBoxComponent
                                disabled={true}
                                labelid="eligibilityDetermination.1YearDiagnosisText"
                                defaultlabelid="1 Year Diagnosis"
                                fontWeight={400}
                                fontFamily="Lato-Regular"
                                ischecked={values.isOneYearDiagnosis}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  if (values.isOneYearDiagnosis === true) {
                                    if (e.target.checked === false) {
                                      setFieldValue(
                                        'isOneYearDiagnosis',
                                        e.target.checked,
                                      );
                                    } else {
                                      e.preventDefault();
                                    }
                                  } else {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                flex: '1 0 33%',
                                position: 'relative',
                                top: '10px',
                              }}
                            >
                              <MediumTypography
                                labelid="eligibilityDetermination.everyOneYearText"
                                defaultlabel="Every 1 year"
                                fontweight={400}
                                fontSize="14px"
                                textColor="#2A4241"
                              />
                            </Box>
                            <Box style={{ flex: '1 0 33%' }}></Box>
                          </Box>
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '0px' }}
                          />
                          <Box
                            style={{
                              display: 'flex',
                              gap: '20px',
                            }}
                          >
                            <Box style={{ flex: '1 0 33%' }}>
                              <CheckBoxComponent
                                disabled={true}
                                labelid="eligibilityDetermination.BdiText"
                                defaultlabelid="BDI Eligibility"
                                fontWeight={400}
                                fontFamily="Lato-Regular"
                                ischecked={values.bdiEligibility ?? false}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  if (values.isBdiTwoEligible === true) {
                                    if (e.target.checked === false) {
                                      setFieldValue(
                                        'isBdiTwoEligible',
                                        e.target.checked,
                                      );
                                    } else {
                                      e.preventDefault();
                                    }
                                  } else {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                flex: '1 0 33%',
                                position: 'relative',
                                top: '10px',
                              }}
                            >
                              <MediumTypography
                                labelid="eligibilityDetermination.everyOneYearText"
                                defaultlabel="Every 1 year"
                                fontweight={400}
                                fontSize="14px"
                                textColor="#2A4241"
                              />
                            </Box>
                            <Box style={{ flex: '1 0 33%' }}></Box>
                          </Box>
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '0px' }}
                          />
                          <Box
                            style={{
                              display: 'flex',
                              gap: '20px',
                            }}
                          >
                            <Box style={{ flex: '1 0 33%' }}>
                              <CheckBoxComponent
                                disabled={true}
                                labelid="eligibilityDetermination.riskFacText"
                                defaultlabelid="Risk Factor"
                                fontWeight={400}
                                fontFamily="Lato-Regular"
                                ischecked={values.isRiskFactorEligible}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  if (values.isRiskFactorEligible === true) {
                                    if (e.target.checked === false) {
                                      setFieldValue(
                                        'isRiskFactorEligible',
                                        e.target.checked,
                                      );
                                    } else {
                                      e.preventDefault();
                                    }
                                  } else {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                flex: '1 0 33%',
                                position: 'relative',
                                top: '10px',
                              }}
                            >
                              <MediumTypography
                                labelid="eligibilityDetermination.everyOneYearText"
                                defaultlabel="Every 1 year"
                                fontweight={400}
                                fontSize="14px"
                                textColor="#2A4241"
                              />
                            </Box>
                            <Box style={{ flex: '1 0 33%' }}></Box>
                          </Box>
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '1px' }}
                          />
                          <Box
                            style={{
                              display: 'flex',
                              gap: '20px',
                            }}
                          >
                            <Box style={{ flex: '1 0 33%' }}>
                              <CheckBoxComponent
                                disabled={true}
                                labelid="eligibilityDetermination.clinicalJudgment"
                                defaultlabelid="Clinical Judgement"
                                fontWeight={400}
                                fontFamily="Lato-Regular"
                                ischecked={values.isClinicalJudgementEligible}
                                CheckHandleChange={(
                                  e: React.ChangeEvent<HTMLInputElement>,
                                ) => {
                                  if (
                                    values.isClinicalJudgementEligible === true
                                  ) {
                                    if (e.target.checked === false) {
                                      setFieldValue(
                                        'isClinicalJudgementEligible',
                                        e.target.checked,
                                      );
                                    } else {
                                      e.preventDefault();
                                    }
                                  } else {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            </Box>
                            <Box
                              style={{
                                flex: '1 0 33%',
                                position: 'relative',
                                top: '10px',
                              }}
                            >
                              <MediumTypography
                                labelid="eligibilityDetermination.everySixMonthsText"
                                defaultlabel="Every 6 months"
                                fontweight={400}
                                fontSize="14px"
                                textColor="#2A4241"
                              />
                            </Box>
                            <Box style={{ flex: '1 0 33%' }}></Box>
                          </Box>
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '1px' }}
                          />
                        </Box>

                        <Grid item xs={10} sx={{ marginTop: '2%' }}>
                          <CommentTextbox
                            disabled={
                              uploadedToTBR || signaturePartitionKey !== ''
                            }
                            maxLength={2000}
                            rows={4}
                            height="60px"
                            name="immunizationComment"
                            placeholder={rctl.formatMessage({
                              id: 'eligibilityDetermination.commentBoxPlaceText',
                            })}
                            handlechange={(value: string) => {
                              setFieldValue(
                                'eligibilitySummaryNarrative',
                                value,
                              );
                            }}
                            Value={values.eligibilitySummaryNarrative}
                          />
                        </Grid>

                        <Grid item xs={10}>
                          {values.signature !== null &&
                            (values.signature.signatureData !== '' ||
                              values.signature.rowKey !== '') && (
                              <ButtonComponent
                                disabled={uploadedToTBR}
                                className="btn-primary btn-cancel signature_view_Box"
                                labelId={'ProgressNoteViewSignature'}
                                defaultLabelId={'View signature'}
                                variantType="outlined"
                                LeftIcon={<Editsignature />}
                                onClick={() => setSignClicked(true)}
                              />
                            )}

                          {values.signature !== null &&
                            values.signature.signatureData === '' &&
                            values.signature.rowKey === '' && (
                              <ButtonComponent
                                disabled={uploadedToTBR}
                                className="btn-primary btn-cancel signature_add_Box"
                                labelId="Insurance.Addsignature"
                                defaultLabelId="Add Signature"
                                variantType="outlined"
                                onClick={() => {
                                  setSignClicked(true);
                                }}
                              />
                            )}
                        </Grid>
                      </Box>
                    </Card>
                    <Box className="mb-xlg">
                      <Grid
                        className="flex__ justifyContent-FlexEnd pt-md"
                        container
                        direction="row"
                        alignItems="right"
                      >
                        <Grid item>
                          <ButtonComponent
                            disabled={uploadedToTBR}
                            className="btn-primary btn-cancel mr-md"
                            variantType="contained"
                            defaultLabelId="Cancel"
                            labelId="Contacts.cancelbtn"
                            onClick={() => {
                              if (
                                _.isEqual(eligibilityFormInitialValue, values)
                              ) {
                                navigate(BATELLE_DEVELOPMENT_INVENTORY_2);
                              } else {
                                setShowCancelDialog(true);
                              }
                            }}
                          />

                          {eligibilityForm?.id === '' ||
                            (eligibilityForm?.id === null && (
                              <ButtonComponent
                                disabled={uploadedToTBR}
                                className="btn-primary btn-submit"
                                variantType="contained"
                                type="submit"
                                labelId="Insurance.save"
                                defaultLabelId="Save"
                                onClick={() => {
                                  handleSubmit();
                                }}
                              />
                            ))}
                          {eligibilityForm?.id && (
                            <ButtonComponent
                              disabled={uploadedToTBR}
                              className="btn-primary btn-submit"
                              variantType="contained"
                              type="submit"
                              labelId="clientpage.Update"
                              defaultLabelId="Update"
                              onClick={() => {
                                formikRef.current?.submitForm();
                              }}
                            />
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                    {signClicked && (
                      <Box component="div">
                        <SignatureComponent
                          signClicked={signClicked}
                          signature={values.signature.signatureData}
                          labelId="EmployeeSignatureText"
                          defaultLabelId="Employee Signature"
                          onCancelClick={onCancelClick}
                          signaturePartitionKey={values.signature.partitionKey}
                          signatureRowKey={values.signature.rowKey}
                          signatureSaveOrUPdate={signatureSaveOrUPdate}
                          handleSuccess={handleSuccess}
                          resetCallBackForTypeEmployee={
                            resetCallBackForTypeEmployee
                          }
                          type="Employee"
                        />
                      </Box>
                    )}
                  </>
                );
              }}
            </Formik>
          </Box>
        </div>
      </Box>
    </Box>
  );
};

export default EligibilityDeterminationForm;
