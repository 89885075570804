import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { OptionType } from '../../../utils/type';
import dayjs, { Dayjs } from 'dayjs';
import { getIfspById } from '../../../services/ifspClient/IfspApi';
import { LoaderContext, LoaderContextType } from '../../../layouts/AppSidebar';
import { Box } from '@mui/system';
import SnackBarComponent from '../../../components/formlib/SnackBarComponent';
import { AlertColor } from '@mui/material';
import ThomProgram from '../../../assets/images/thomchildlogo.svg';
import { ApiError, isCustomError } from '../../../services/ApiResponseHandler';
import _, { cloneDeep } from 'lodash';
import {
  LookUpContext,
  LookUpContextType,
} from '../../../context/LookUpContextProvider';
import { UnSavedChangesContext } from '../../../context/UnSavedChangesProvider';
type IfspContextType = {
  children: ReactNode;
};

export type RoutineType = {
  [key: string]: {
    value: string;
    comments: string;
  };
};

export type CustomRoutineType = {
  name: string;
  value: string;
  comments: string;
};
export type ReviewedOnType = {
  reviewedDate: string | null | Dayjs;
  reviewedByName: string;
  reviewedByDiscipline: string;
};

export type IfspTeamMembersType = {
  id: string;
  name: string;
  phone: string;
  email: string;
  startDate?: string | null | Dayjs;
  endDate?: string | null | Dayjs;
  discipline?: string | null | undefined;
  jobDescription?: string | null | undefined;
  role: string;
};
export type ContactsType = {
  id: string;
  name: string;
  phone: string;
  email: string;
  isPrimary: boolean;
  type: string;
  relationshipToClient: string;
  address: string;
  startDate?: string | null;
  endDate?: string | null;
};

export type ParentsType = {
  id: string;
  name: string;
  role: string;
  email: string;
  phone: string;
};

export type TeamMembersType = {
  id: string;
  name: string;
  role: string;
  discipline?: string | null | undefined;
  email: string;
  phone: string;
  startDate?: string | null | Dayjs;
  endDate?: string | null | Dayjs;
};

export type OthersType = {
  name: string;
  jobDescription: string;
  startDate?: string | null | Dayjs;
  endDate?: string | null | Dayjs;
};

export type EasiestHardestTimesType = {
  routineName: string;
  reason: string;
};

type HealthSummaryType = {
  dateOfEvaluation: string | null | Dayjs;
  teamMember: {
    name: string;
    memberName: string;
    discipline: string;
  }[];
  evaluationResults: string;
  healthHistory: string;
  currentHealthStatus: string;
  additionalInfo: string;
  isChecked: boolean;
  sessionReviewedDate: string | null | Dayjs;
};

type OutcomesProgressReviewType = {
  outcomeId: string;
  startDate: string | null | Dayjs;
  endDate: string | null | Dayjs;
  concern: string;
  outcome: string;
  measurableProcedure: string;
  targetDate: string;
  isRecordSubmitted: boolean;
  tbrOutcomeId: number;
  tbrUpdateRequired: boolean;
};

type OutcomesProgressInformationType = {
  date: string | null | Dayjs;
  progressDesc: string;
  progressRating: string;
};

type ChildIfspOutcomeType = {
  isChecked: boolean;
  outcomesProgressReview: OutcomesProgressReviewType;
  outcomesProgressInformation: OutcomesProgressInformationType[];
};

type FamilyConcernsType = {
  concern: string;
  priorities: string;
  materialResource: string;
  infoResource: string;
  supportiveResource: string;
  isChecked: boolean;
  sessionReviewedDate: string | null | Dayjs;
};

export type DevelopmentalSummaryProgressReview = {
  adaptive: string;
  personalSocial: string;
  communication: string;
  motor: string;
  cognition: string;
};

export type DevelopmentalSummaryType = {
  developmentalSummaryProgressReview: DevelopmentalSummaryProgressReview[];
  isChecked: boolean;
  sessionReviewedDate: string | null | Dayjs;
};

type EarlyInterventionServiceType = {
  servicePlanId: string;
  eiService: string;
  providedBy: string;
  locationOfService: string;
  length: string;
  period: string;
  frequency: string;
  intensity: string;
  duration: string;
  startDate: string | null | Dayjs;
  endDate: string | null | Dayjs;
  reasonIfLate: string;
  isRecordSubmitted: boolean;
  tbrServicePlanId: number;
  tbrUpdateRequired: boolean;
};

type ServiceDeliveryPlanType = {
  earlyInterventionService: EarlyInterventionServiceType[];
  notes: string;
  justification: string;
  otherNeededService: string;
  stepsToTake: string;
  isChecked: boolean;
};

type TransitionPlanType = {
  isTransitionPlanDone: string;
  transitionPlanDate: string | null | Dayjs;
  noTransitionPlanReason: string;
  needs: string;
  leaEligible: string;
  notify: string;
  consentShare: boolean;
  consentDate: string | null | Dayjs;
  outsideTPCReason: string;
  meetingDate: string | null | Dayjs;
  noTPCReason: string;
  sectionDetermine: boolean;
  specify: string;
  makeReferral: boolean;
  date: string | null | Dayjs;
  tpc: boolean;
  leaInvited: string;
  repParticipate: string;
  participatedNames: string;
  noReferralMade: boolean;
  seaStatus: string;
  leaOptOutDate: string | null | Dayjs;
  isChecked: boolean;
};

type ConsentForChangeType = {
  changes: string;
  isEligibleUntilThirdBirthday: string;
  eligibleTool: OptionType;
  assessmentCompletionDate: string | null | Dayjs;
  reEstablishedDate: string | null | Dayjs;
  parentInitial: OptionType;
  consentType: string;
  isChecked: boolean;
  sessionReviewedDate: string | null | Dayjs;
  participated: boolean;
  agreeDisagree: string;
  exceptionList: string;
  parentSignature: {
    id: string;
    isUpdated: boolean;
    signature: string;
    verbalConsent: boolean;
    date: string | null | Dayjs;
    partitionKey: string;
    rowKey: string;
  }[];
  consent: boolean;
  consentSignature: {
    id: string;
    isUpdated: boolean;
    signature: string;
    verbalConsent: boolean;
    date: string | null | Dayjs;
    partitionKey: string;
    rowKey: string;
  }[];
};

export type IfspContextDataType = {
  programDetails: {
    name: string;
    imageUrl: string;
    email: string;
    phoneNumber: string;
    faxNumber: string;
    addressLine1: string;
    addressLine2: string;
    website: string;
  };
  client: {
    name: string;
    sex: string;
    dphId: string;
    dob: string;
  };
  reasonForDelay: {
    id: string;
    label: string;
  };
  reviewedList: ReviewedOnType[];
  ifspTeam: {
    contact: ContactsType[];
    parents: ParentsType[];
    teamMember: TeamMembersType[];
    others: OthersType[];
    forms: {
      dailyRoutines: {
        routines: RoutineType;
        customRoutines: CustomRoutineType[];
        easiestTimes: EasiestHardestTimesType[];
        hardestTimes: EasiestHardestTimesType[];
        isChecked: boolean;
        sessionReviewedDate: string | null | Dayjs;
      }[];
      healthSummary: HealthSummaryType[];
      childIfspOutcomes: ChildIfspOutcomeType[];
      familyConcerns: FamilyConcernsType[];
      developmentalSummary: DevelopmentalSummaryType[];
      serviceDeliveryPlan: ServiceDeliveryPlanType[];
      transitionPlan: TransitionPlanType;
      transitionPreparation: {
        changesComingUp: string;
        preparationHelp: string;
        supports: string;
        communityOpportunities: string;
        serviceChangesNeeded: string;
        isChecked: boolean;
      };
      consentForChangeForSDP: ConsentForChangeType[];
    };
  };
  completedByRole: string;
  completedBy: OptionType;
  completed: boolean;
  ifspMeetingDate: Dayjs | string | null;
  primarySettingCode: string;
  primarySettingCodeJustification: string;
  ifspSignedDate: Dayjs | string | null;
  completionDate: Dayjs | null | string;
  isLocked: boolean;
  evaluationDate: Dayjs | null | string;
  notificationDate: Dayjs | null | string;
  selectedLanguage: string;
};

export const initialRoutine = {
  wakingUp: {
    name: 'Waking up',
    value: '',
    comments: '',
  },
  mealTime: {
    name: 'Meal time',
    value: '',
    comments: '',
  },
  napTime: {
    name: 'Nap time',
    value: '',
    comments: '',
  },
  playTime: {
    name: 'Play time',
    value: '',
    comments: '',
  },
  downTime: {
    name: 'Down time/hanging out',
    value: '',
    comments: '',
  },
  bathTime: {
    name: 'Bath time',
    value: '',
    comments: '',
  },
  bedTime: {
    name: 'Bed time',
    value: '',
    comments: '',
  },
  familyActivities: {
    name: 'Family activities',
    value: '',
    comments: '',
  },
  socialGatherings: {
    name: 'Social and community gatherings',
    value: '',
    comments: '',
  },
  comingFromHome: {
    name: 'Coming and going from home',
    value: '',
    comments: '',
  },
  dropOff: {
    name: 'Drop off/Pick up',
    value: '',
    comments: '',
  },
  runningErrands: {
    name: 'Running errands',
    value: '',
    comments: '',
  },
  workSchedule: {
    name: 'Work/School schedule',
    value: '',
    comments: '',
  },
  childCare: {
    name: 'Child care',
    value: '',
    comments: '',
  },
  doctorAppointment: {
    name: "Doctor's (or other) appointments",
    value: '',
    comments: '',
  },
};

const IfspContext = createContext<{
  ifsp: IfspContextDataType;
  initialIfsp: IfspContextDataType;
  updateIfspContext: (data: IfspContextDataType) => void;
  updateIfspTouched: (touched: boolean) => void;
  getIfsp?: (clientId: string) => void;
  ifspTouched: boolean;
  updateIsTransitionDateReqired: (data: boolean) => void;
  updateIsOptOutNotificationDateRequired: (data: boolean) => void;
  updateTransitionOutOfRange: (data: boolean) => void;
  UpdateLeaReferralDateOutOfRange: (data: boolean) => void;
  isTransitionDateReqired: boolean;
  isOptOutNotificationDateRequired: boolean;
  transitionDateOutOfRange: boolean;
  leaReferralDateOutOfRange: boolean;
  primarySettingCode: OptionType[];
  reasonData: OptionType[];
}>({
  ifsp: {
    programDetails: {
      name: '',
      imageUrl: '',
      email: '',
      phoneNumber: '',
      faxNumber: '',
      addressLine1: '',
      addressLine2: '',
      website: '',
    },
    client: {
      name: '',
      sex: '',
      dphId: '',
      dob: '',
    },
    reasonForDelay: {
      id: '',
      label: '',
    },
    reviewedList: [],
    ifspTeam: {
      contact: [],
      parents: [],
      teamMember: [],
      others: [],
      forms: {
        dailyRoutines: [
          {
            routines: initialRoutine,
            customRoutines: [],
            easiestTimes: [
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
            ],
            hardestTimes: [
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
            ],
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        healthSummary: [
          {
            dateOfEvaluation: null,
            teamMember: [],
            evaluationResults: '',
            healthHistory: '',
            currentHealthStatus: '',
            additionalInfo: '',
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        childIfspOutcomes: [
          {
            outcomesProgressReview: {
              outcomeId: '',
              startDate: null,
              endDate: null,
              concern: '',
              outcome: '',
              measurableProcedure: '',
              targetDate: '',
              isRecordSubmitted: false,
              tbrOutcomeId: 0,
              tbrUpdateRequired: true,
            },
            outcomesProgressInformation: [
              {
                date: null,
                progressDesc: '',
                progressRating: '',
              },
            ],
            isChecked: false,
          },
        ],
        familyConcerns: [
          {
            concern: '',
            priorities: '',
            materialResource: '',
            infoResource: '',
            supportiveResource: '',
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        developmentalSummary: [
          {
            developmentalSummaryProgressReview: [
              {
                adaptive: '',
                cognition: '',
                communication: '',
                motor: '',
                personalSocial: '',
              },
            ],
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        serviceDeliveryPlan: [
          {
            earlyInterventionService: [],
            notes: '',
            justification: '',
            otherNeededService: '',
            stepsToTake: '',
            isChecked: false,
          },
        ],
        transitionPlan: {
          consentDate: null,
          isTransitionPlanDone: '',
          leaEligible: '',
          noTPCReason: '',
          noTransitionPlanReason: '',
          outsideTPCReason: '',
          seaStatus: '',
          leaOptOutDate: null,
          transitionPlanDate: null,
          needs: '',
          meetingDate: '',
          repParticipate: 'yes',
          participatedNames: '',
          notify: '',
          consentShare: false,
          sectionDetermine: false,
          specify: '',
          makeReferral: false,
          date: '',
          tpc: false,
          leaInvited: '',
          noReferralMade: false,
          isChecked: false,
        },
        transitionPreparation: {
          changesComingUp: '',
          preparationHelp: '',
          supports: '',
          communityOpportunities: '',
          serviceChangesNeeded: '',
          isChecked: false,
        },
        consentForChangeForSDP: [
          {
            changes: '',
            isEligibleUntilThirdBirthday: '',
            eligibleTool: { id: '', label: '' },
            assessmentCompletionDate: null,
            reEstablishedDate: null,
            parentInitial: { id: '', label: '' },
            isChecked: false,
            consentType: 'consent',
            sessionReviewedDate: '',
            participated: false,
            agreeDisagree: 'agree',
            exceptionList: '',
            parentSignature: [
              {
                id: 'p.0',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
              {
                id: 'p.1',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
            ],
            consent: false,
            consentSignature: [
              {
                id: 'c.0',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
              {
                id: 'c.1',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
            ],
          },
        ],
      },
    },
    completedByRole: '',
    completedBy: { id: '', label: '' },
    completed: false,
    primarySettingCode: '',
    primarySettingCodeJustification: '',
    ifspMeetingDate: dayjs().format('MM/DD/YYYY'),
    ifspSignedDate: dayjs().format('MM/DD/YYYY'),
    completionDate: null,
    isLocked: false,
    evaluationDate: null,
    notificationDate: null,
    selectedLanguage: '',
  },
  initialIfsp: {
    programDetails: {
      name: '',
      imageUrl: '',
      email: '',
      phoneNumber: '',
      faxNumber: '',
      addressLine1: '',
      addressLine2: '',
      website: '',
    },
    client: {
      name: '',
      sex: '',
      dphId: '',
      dob: '',
    },
    reasonForDelay: {
      id: '',
      label: '',
    },
    reviewedList: [],
    ifspTeam: {
      contact: [],
      parents: [],
      teamMember: [],
      others: [],
      forms: {
        dailyRoutines: [
          {
            routines: initialRoutine,
            customRoutines: [],
            easiestTimes: [
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
            ],
            hardestTimes: [
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
              { routineName: '', reason: '' },
            ],
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        healthSummary: [
          {
            dateOfEvaluation: null,
            teamMember: [],
            evaluationResults: '',
            healthHistory: '',
            currentHealthStatus: '',
            additionalInfo: '',
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        childIfspOutcomes: [
          {
            outcomesProgressReview: {
              outcomeId: '',
              startDate: null,
              endDate: null,
              concern: '',
              outcome: '',
              measurableProcedure: '',
              targetDate: '',
              isRecordSubmitted: false,
              tbrOutcomeId: 0,
              tbrUpdateRequired: true,
            },
            outcomesProgressInformation: [
              {
                date: null,
                progressDesc: '',
                progressRating: '',
              },
            ],
            isChecked: false,
          },
        ],
        familyConcerns: [
          {
            concern: '',
            priorities: '',
            materialResource: '',
            infoResource: '',
            supportiveResource: '',
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        developmentalSummary: [
          {
            developmentalSummaryProgressReview: [
              {
                adaptive: '',
                cognition: '',
                communication: '',
                motor: '',
                personalSocial: '',
              },
            ],
            isChecked: false,
            sessionReviewedDate: '',
          },
        ],
        serviceDeliveryPlan: [
          {
            earlyInterventionService: [],
            notes: '',
            justification: '',
            otherNeededService: '',
            stepsToTake: '',
            isChecked: false,
          },
        ],
        transitionPlan: {
          consentDate: null,
          isTransitionPlanDone: '',
          leaEligible: '',
          noTPCReason: '',
          noTransitionPlanReason: '',
          outsideTPCReason: '',
          seaStatus: '',
          leaOptOutDate: null,
          transitionPlanDate: null,
          needs: '',
          meetingDate: '',
          repParticipate: 'yes',
          participatedNames: '',
          notify: '',
          consentShare: false,
          sectionDetermine: false,
          specify: '',
          makeReferral: false,
          date: '',
          tpc: false,
          leaInvited: '',
          noReferralMade: false,
          isChecked: false,
        },
        transitionPreparation: {
          changesComingUp: '',
          preparationHelp: '',
          supports: '',
          communityOpportunities: '',
          serviceChangesNeeded: '',
          isChecked: false,
        },
        consentForChangeForSDP: [
          {
            changes: '',
            isEligibleUntilThirdBirthday: '',
            eligibleTool: { id: '', label: '' },
            assessmentCompletionDate: null,
            reEstablishedDate: null,
            parentInitial: { id: '', label: '' },
            isChecked: false,
            consentType: 'consent',
            sessionReviewedDate: '',
            participated: false,
            agreeDisagree: 'agree',
            exceptionList: '',
            parentSignature: [
              {
                id: 'p.0',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
              {
                id: 'p.1',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
            ],
            consent: false,
            consentSignature: [
              {
                id: 'c.0',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
              {
                id: 'c.1',
                isUpdated: false,
                signature: '',
                verbalConsent: false,
                date: dayjs().format('MM/DD/YYYY'),
                partitionKey: '',
                rowKey: '',
              },
            ],
          },
        ],
      },
    },
    completedByRole: '',
    completedBy: { id: '', label: '' },
    completed: false,
    primarySettingCode: '',
    primarySettingCodeJustification: '',
    ifspMeetingDate: dayjs().format('MM/DD/YYYY'),
    ifspSignedDate: dayjs().format('MM/DD/YYYY'),
    completionDate: null,
    isLocked: false,
    evaluationDate: null,
    notificationDate: null,
    selectedLanguage: '',
  },
  updateIfspContext: () => {},
  updateIfspTouched: () => {},
  UpdateLeaReferralDateOutOfRange: () => {},
  updateTransitionOutOfRange: () => {},
  updateIsOptOutNotificationDateRequired: () => {},
  updateIsTransitionDateReqired: () => {},
  getIfsp: () => {},
  ifspTouched: false,
  isTransitionDateReqired: false,
  isOptOutNotificationDateRequired: false,
  leaReferralDateOutOfRange: false,
  transitionDateOutOfRange: false,
  primarySettingCode: [],
  reasonData: [],
});

export const IfspContextProvider = ({ children }: IfspContextType) => {
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  const { lookups } = useContext(LookUpContext) as LookUpContextType;
  const getIfsp = (clientId: string) => {
    if (clientId) {
      getIfspById(clientId)
        .then((data: IfspContextDataType) => {
          toggleLoader(false);
          setIsOptOutNotificationDateRequired(
            (data.ifspTeam.forms.transitionPlan.leaOptOutDate === null ||
              data.ifspTeam.forms.transitionPlan.leaOptOutDate === '') &&
              data.ifspTeam.forms.transitionPlan.seaStatus === 'OPTOUT',
          );
          setIfsp(
            cloneDeep({
              ...data,
              ifspTeam: {
                ...data.ifspTeam,
                forms: {
                  ...data.ifspTeam.forms,
                  dailyRoutines: data.ifspTeam.forms.dailyRoutines.map((x) => ({
                    ...x,
                    easiestTimes:
                      x.easiestTimes.length !== 3
                        ? [
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                          ]
                        : x.easiestTimes,
                    hardestTimes:
                      x.hardestTimes.length !== 3
                        ? [
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                          ]
                        : x.hardestTimes,
                  })),
                  developmentalSummary:
                    data.ifspTeam.forms.developmentalSummary.map((x) => ({
                      ...x,
                      developmentalSummaryProgressReview:
                        x.developmentalSummaryProgressReview.length === 0
                          ? [
                              {
                                adaptive: '',
                                cognition: '',
                                motivation: '',
                                communication: '',
                                motor: '',
                                personalSocial: '',
                              },
                            ]
                          : x.developmentalSummaryProgressReview,
                    })),
                  consentForChangeForSDP:
                    data.ifspTeam.forms.consentForChangeForSDP.map(
                      (x, index) => ({
                        ...x,
                        consentType:
                          x.consentType === ''
                            ? index === 0
                              ? 'consent'
                              : 'consentForChange'
                            : x.consentType,
                        consentSignature: x.consentSignature.map((y) => ({
                          ...y,
                          date:
                            y.signature || y.partitionKey
                              ? y.date
                              : dayjs().format('MM/DD/YYYY'),
                        })),
                        parentSignature: x.parentSignature.map((y) => ({
                          ...y,
                          date:
                            y.signature || y.partitionKey
                              ? y.date
                              : dayjs().format('MM/DD/YYYY'),
                        })),
                      }),
                    ),
                },
              },
              programDetails: {
                ...data.programDetails,
                imageUrl:
                  data.programDetails.imageUrl &&
                  (data.programDetails.imageUrl !== null ||
                    data.programDetails.imageUrl !== '')
                    ? data.programDetails.imageUrl
                    : ThomProgram,
              },
              ifspMeetingDate:
                data.ifspMeetingDate === '' || null
                  ? dayjs().format('MM/DD/YYYY')
                  : data.ifspMeetingDate,
              ifspSignedDate:
                data.ifspSignedDate === '' || null
                  ? dayjs().format('MM/DD/YYYY')
                  : data.ifspSignedDate,
            }),
          );
          setInitialIfsp(
            cloneDeep({
              ...data,
              ifspTeam: {
                ...data.ifspTeam,
                forms: {
                  ...data.ifspTeam.forms,
                  dailyRoutines: data.ifspTeam.forms.dailyRoutines.map((x) => ({
                    ...x,
                    easiestTimes:
                      x.easiestTimes.length !== 3
                        ? [
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                          ]
                        : x.easiestTimes,
                    hardestTimes:
                      x.hardestTimes.length !== 3
                        ? [
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                            { routineName: '', reason: '' },
                          ]
                        : x.hardestTimes,
                  })),
                  developmentalSummary:
                    data.ifspTeam.forms.developmentalSummary.map((x) => ({
                      ...x,
                      developmentalSummaryProgressReview:
                        x.developmentalSummaryProgressReview.length === 0
                          ? [
                              {
                                adaptive: '',
                                cognition: '',
                                motivation: '',
                                communication: '',
                                motor: '',
                                personalSocial: '',
                              },
                            ]
                          : x.developmentalSummaryProgressReview,
                    })),
                  consentForChangeForSDP:
                    data.ifspTeam.forms.consentForChangeForSDP.map(
                      (x, index) => ({
                        ...x,
                        consentType:
                          x.consentType === ''
                            ? index === 0
                              ? 'consent'
                              : 'consentForChange'
                            : x.consentType,
                        consentSignature: x.consentSignature.map((y) => ({
                          ...y,
                          date:
                            y.signature || y.partitionKey
                              ? y.date
                              : dayjs().format('MM/DD/YYYY'),
                        })),
                        parentSignature: x.parentSignature.map((y) => ({
                          ...y,
                          date:
                            y.signature || y.partitionKey
                              ? y.date
                              : dayjs().format('MM/DD/YYYY'),
                        })),
                      }),
                    ),
                },
              },
              programDetails: {
                ...data.programDetails,
                imageUrl:
                  data.programDetails.imageUrl &&
                  (data.programDetails.imageUrl !== null ||
                    data.programDetails.imageUrl !== '')
                    ? data.programDetails.imageUrl
                    : ThomProgram,
              },
              ifspMeetingDate:
                data.ifspMeetingDate === '' || null
                  ? dayjs().format('MM/DD/YYYY')
                  : data.ifspMeetingDate,
              ifspSignedDate:
                data.ifspSignedDate === '' || null
                  ? dayjs().format('MM/DD/YYYY')
                  : data.ifspSignedDate,
            }),
          );
        })
        .catch((error) => {
          toggleLoader(false);
          setOpen(true);
          setToastrVariable('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId('IFSP.failedToGet');
            setToastrDefaultMessage('Failed to get signature');
          }
        });
    }
  };
  useEffect(() => {
    toggleLoader(true);
    const clientId = localStorage.getItem('ClientId');
    if (clientId) {
      getIfsp(clientId);
    }
  }, []);

  const [ifsp, setIfsp] = useState<IfspContextDataType>(
    cloneDeep({
      programDetails: {
        name: '',
        imageUrl: '',
        email: '',
        phoneNumber: '',
        faxNumber: '',
        addressLine1: '',
        addressLine2: '',
        website: '',
      },
      client: {
        name: '',
        sex: '',
        dphId: '',
        dob: '',
      },
      reasonForDelay: {
        id: '',
        label: '',
      },
      reviewedList: [],
      ifspTeam: {
        contact: [],
        parents: [],
        teamMember: [],
        others: [],
        forms: {
          dailyRoutines: [
            {
              routines: initialRoutine,
              customRoutines: [],
              easiestTimes: [
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
              ],
              hardestTimes: [
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
              ],
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          healthSummary: [
            {
              dateOfEvaluation: null,
              teamMember: [],
              evaluationResults: '',
              healthHistory: '',
              currentHealthStatus: '',
              additionalInfo: '',
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          childIfspOutcomes: [
            {
              outcomesProgressReview: {
                outcomeId: '',
                startDate: null,
                endDate: null,
                concern: '',
                outcome: '',
                measurableProcedure: '',
                targetDate: '',
                reason: '',
                isRecordSubmitted: false,
                tbrOutcomeId: 0,
                tbrUpdateRequired: true,
              },
              outcomesProgressInformation: [
                {
                  date: null,
                  progressDesc: '',
                  progressRating: '',
                },
              ],
              isChecked: false,
            },
          ],
          familyConcerns: [
            {
              concern: '',
              priorities: '',
              materialResource: '',
              infoResource: '',
              supportiveResource: '',
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          developmentalSummary: [
            {
              developmentalSummaryProgressReview: [
                {
                  adaptive: '',
                  cognition: '',
                  communication: '',
                  motor: '',
                  personalSocial: '',
                },
              ],
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          serviceDeliveryPlan: [
            {
              earlyInterventionService: [],
              notes: '',
              justification: '',
              otherNeededService: '',
              stepsToTake: '',
              isChecked: false,
            },
          ],
          transitionPlan: {
            consentDate: null,
            isTransitionPlanDone: '',
            leaEligible: '',
            noTPCReason: '',
            noTransitionPlanReason: '',
            outsideTPCReason: '',
            leaOptOutDate: null,
            seaStatus: '',
            transitionPlanDate: null,
            needs: '',
            meetingDate: '',
            repParticipate: 'yes',
            participatedNames: '',
            notify: '',
            consentShare: false,
            sectionDetermine: false,
            specify: '',
            makeReferral: false,
            date: '',
            tpc: false,
            leaInvited: '',
            noReferralMade: false,
            isChecked: false,
          },
          transitionPreparation: {
            changesComingUp: '',
            preparationHelp: '',
            supports: '',
            communityOpportunities: '',
            serviceChangesNeeded: '',
            isChecked: false,
          },
          consentForChangeForSDP: [
            {
              changes: '',
              isEligibleUntilThirdBirthday: '',
              eligibleTool: { id: '', label: '' },
              assessmentCompletionDate: null,
              reEstablishedDate: null,
              parentInitial: { id: '', label: '' },
              isChecked: false,
              consentType: 'consent',
              sessionReviewedDate: '',
              participated: false,
              agreeDisagree: 'agree',
              exceptionList: '',
              parentSignature: [
                {
                  id: 'p.0',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
                {
                  id: 'p.1',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
              ],
              consent: false,
              consentSignature: [
                {
                  id: 'c.0',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
                {
                  id: 'c.1',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
              ],
            },
          ],
        },
      },
      completedByRole: '',
      completedBy: { id: '', label: '' },
      completed: false,
      primarySettingCode: '',
      primarySettingCodeJustification: '',
      ifspMeetingDate: dayjs().format('MM/DD/YYYY'),
      ifspSignedDate: dayjs().format('MM/DD/YYYY'),
      completionDate: null,
      isLocked: false,
      evaluationDate: null,
      notificationDate: null,
      selectedLanguage: '',
    }),
  );

  const [open, setOpen] = useState<boolean>(false);
  const [toastrVariable, setToastrVariable] = useState<AlertColor>('info');
  const [toastrDefaultMessage, setToastrDefaultMessage] = useState('');
  const [toastrId, setToastrId] = useState('');
  const [initialIfsp, setInitialIfsp] = useState<IfspContextDataType>(
    cloneDeep({
      programDetails: {
        name: '',
        imageUrl: '',
        email: '',
        phoneNumber: '',
        faxNumber: '',
        addressLine1: '',
        addressLine2: '',
        website: '',
      },
      client: {
        name: '',
        sex: '',
        dphId: '',
        dob: '',
      },
      reasonForDelay: {
        id: '',
        label: '',
      },
      reviewedList: [],
      ifspTeam: {
        contact: [],
        parents: [],
        teamMember: [],
        others: [],
        forms: {
          dailyRoutines: [
            {
              routines: initialRoutine,
              customRoutines: [],
              easiestTimes: [
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
              ],
              hardestTimes: [
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
                { routineName: '', reason: '' },
              ],
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          healthSummary: [
            {
              dateOfEvaluation: null,
              teamMember: [],
              evaluationResults: '',
              healthHistory: '',
              currentHealthStatus: '',
              additionalInfo: '',
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          childIfspOutcomes: [
            {
              outcomesProgressReview: {
                outcomeId: '',
                startDate: null,
                endDate: null,
                concern: '',
                outcome: '',
                measurableProcedure: '',
                targetDate: '',
                reason: '',
                isRecordSubmitted: false,
                tbrOutcomeId: 0,
                tbrUpdateRequired: true,
              },
              outcomesProgressInformation: [
                {
                  date: null,
                  progressDesc: '',
                  progressRating: '',
                },
              ],
              isChecked: false,
            },
          ],
          familyConcerns: [
            {
              concern: '',
              priorities: '',
              materialResource: '',
              infoResource: '',
              supportiveResource: '',
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          developmentalSummary: [
            {
              developmentalSummaryProgressReview: [
                {
                  adaptive: '',
                  cognition: '',
                  communication: '',
                  motor: '',
                  personalSocial: '',
                },
              ],
              isChecked: false,
              sessionReviewedDate: '',
            },
          ],
          serviceDeliveryPlan: [
            {
              earlyInterventionService: [],
              notes: '',
              justification: '',
              otherNeededService: '',
              stepsToTake: '',
              isChecked: false,
            },
          ],
          transitionPlan: {
            consentDate: null,
            isTransitionPlanDone: '',
            leaEligible: '',
            noTPCReason: '',
            noTransitionPlanReason: '',
            outsideTPCReason: '',
            seaStatus: '',
            leaOptOutDate: null,
            transitionPlanDate: null,
            needs: '',
            meetingDate: '',
            repParticipate: '',
            participatedNames: '',
            notify: '',
            consentShare: false,
            sectionDetermine: false,
            specify: '',
            makeReferral: false,
            date: '',
            tpc: false,
            leaInvited: '',
            noReferralMade: false,
            isChecked: false,
          },
          transitionPreparation: {
            changesComingUp: '',
            preparationHelp: '',
            supports: '',
            communityOpportunities: '',
            serviceChangesNeeded: '',
            isChecked: false,
          },
          consentForChangeForSDP: [
            {
              changes: '',
              isEligibleUntilThirdBirthday: '',
              eligibleTool: { id: '', label: '' },
              assessmentCompletionDate: null,
              reEstablishedDate: null,
              parentInitial: { id: '', label: '' },
              isChecked: false,
              consentType: 'consent',
              sessionReviewedDate: '',
              participated: false,
              agreeDisagree: '',
              exceptionList: '',
              parentSignature: [
                {
                  id: 'p.0',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
                {
                  id: 'p.1',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
              ],
              consent: false,
              consentSignature: [
                {
                  id: 'c.0',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
                {
                  id: 'c.1',
                  isUpdated: false,
                  signature: '',
                  verbalConsent: false,
                  date: dayjs().format('MM/DD/YYYY'),
                  partitionKey: '',
                  rowKey: '',
                },
              ],
            },
          ],
        },
      },
      completedByRole: '',
      completedBy: { id: '', label: '' },
      completed: false,
      primarySettingCode: '',
      primarySettingCodeJustification: '',
      ifspMeetingDate: dayjs().format('MM/DD/YYYY'),
      ifspSignedDate: dayjs().format('MM/DD/YYYY'),
      completionDate: null,
      isLocked: false,
      evaluationDate: null,
      notificationDate: null,
      selectedLanguage: '',
    }),
  );
  const [ifspTouched, setIfspTouched] = useState<boolean>(false);
  const [isTransitionDateReqired, setIsTransitionDateReqired] =
    useState<boolean>(false);
  const [
    isOptOutNotificationDateRequired,
    setIsOptOutNotificationDateRequired,
  ] = useState<boolean>(false);
  const [transitionDateOutOfRange, setTransitionDateOutOfRange] =
    useState<boolean>(false);
  const [leaReferralDateOutOfRange, setLeaReferralDateOutOfRange] =
    useState<boolean>(false);

  const [primarySettingCode, setPrimarySettingCode] = useState<OptionType[]>(
    [],
  );
  const [reasonData, setReasonData] = useState<OptionType[]>([]);

  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);

  useEffect(() => {
    if (lookups) {
      setPrimarySettingCode(() => {
        return lookups.primarySettingCode.map((item) => {
          return { id: item.code, label: item.description };
        });
      });
      setReasonData(() => {
        return lookups.reason.map((item) => {
          return { id: item.code, label: item.description };
        });
      });
    }
  }, [lookups]);

  const updateIfspContext = (data: IfspContextDataType) => {
    setIfsp(data);
  };
  const updateIfspTouched = (touched: boolean) => {
    setIfspTouched(touched);
  };

  const UpdateLeaReferralDateOutOfRange = (data: boolean) => {
    setLeaReferralDateOutOfRange(data);
  };
  const updateTransitionOutOfRange = (data: boolean) => {
    setTransitionDateOutOfRange(data);
  };

  const updateIsTransitionDateReqired = (data: boolean) => {
    setIsTransitionDateReqired(data);
  };

  const updateIsOptOutNotificationDateRequired = (data: boolean) => {
    setIsOptOutNotificationDateRequired(data);
  };

  const handleCloseSnakBar = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (_.isEqual(initialIfsp, ifsp)) {
      handleUpdateUnsavedChanges(false);
      setIfspTouched(false);
    } else {
      handleUpdateUnsavedChanges(true);
      setIfspTouched(true);
    }
  }, [ifsp]);
  return (
    <Box component="div">
      <SnackBarComponent
        open={open}
        handleClose={handleCloseSnakBar}
        successOrError={toastrVariable}
        labelId={toastrId}
        defaultMessageId={toastrDefaultMessage}
      />
      <IfspContext.Provider
        value={{
          ifsp,
          updateIfspContext,
          updateIfspTouched,
          ifspTouched,
          initialIfsp,
          getIfsp,
          updateIsTransitionDateReqired,
          updateIsOptOutNotificationDateRequired,
          UpdateLeaReferralDateOutOfRange,
          updateTransitionOutOfRange,
          isTransitionDateReqired,
          isOptOutNotificationDateRequired,
          leaReferralDateOutOfRange,
          transitionDateOutOfRange,
          primarySettingCode,
          reasonData,
        }}
      >
        {children}
      </IfspContext.Provider>
    </Box>
  );
};

export default IfspContext;
