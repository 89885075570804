import dayjs from 'dayjs';
import { LoginBody } from '../../pages/SignInComponent';
import { LoginStateType } from '../../utils/type';
import { ApiResponseHandler } from '../ApiResponseHandler';
import UserApiClient from '../UserApiClient';
import { AuthBody } from '../../pages/AuthScreen';

export const loginUser = async (params: LoginBody): Promise<LoginStateType> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<LoginBody, LoginStateType>(
      `login?timezone=${timezone}`,
      params,
    ),
  );
};

export const loginEntraId = async (
  params: AuthBody,
): Promise<LoginStateType> => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post<AuthBody, LoginStateType>(
      `login`,
      params,
    ),
  );
};

export const signOutUser = async (refreshToken: string) => {
  return ApiResponseHandler(
    await UserApiClient.getInstance().post('signout', {
      refreshToken: refreshToken,
    }),
  );
};
