import dayjs from 'dayjs';
import { ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';
import { Productivity } from '../activitySheet/weeklyActivitySheetProvider';
import { ServiceCoordinatorsType } from '../../../utils/type';

export interface CaseLoadClient {
  clientId: string;
  clientName: string | null;
  referralId: string;
  age: string | null;
  dateOfBirth: string | null;
  referralBeginDate: string | null;
  remainingAssessmentHours: string | null;
  rollOverDate: string | null;
  ifspDate: string | null;
  reEvaluationDate: string | null;
  ifspFirstMeetingDate: string | null;
  ifspSignedDate: string | null;
  intakeFacilitatorName: string | null;
  transitionPlanning: string | null;
  transitionPlanningConference: string | null;
  serviceCoordinatorName: string | null;
}

export interface CaseloadListResponse {
  count: number;
  clientsCount: number;
  clientsAbove27: number;
  transitionCompleted: number;
  clients: CaseLoadClient[] | null;
  productivity: Productivity[] | null;
}

export const getCaseLoadList = async (
  staffType: string,
  employeeIds: string,
  clientName: string,
  tileType: string,
  pageNumber: number,
  sort: string,
  sortType: string,
): Promise<CaseloadListResponse> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<CaseloadListResponse>(
      `caseload/list?staffType=${staffType}&page=${pageNumber}&size=10&sort=${sort}&sortType=${sortType}&employeeIds=${employeeIds}&clientName=${clientName}&tileType=${tileType}&timezone=${timezone}&zoneName=${zoneName}`,
    ),
  );
};

export const getCaseloadListEmployees = async (): Promise<
  ServiceCoordinatorsType[]
> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<ServiceCoordinatorsType[]>(
      `/employee/caseload`,
    ),
  );
};
