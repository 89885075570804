import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';
import { ProgressNoteType } from '../../utils/type';
import dayjs from 'dayjs';
import { CreateDeleteProgressNoteType } from '../../pages/ProgressNote/ProgressNote';
import { THOM_BASE_URL } from '../Constant';
import { getCurrentWeekSundayAsDate } from '../../utils/utilities';

export interface ProgressNotesListResponseType {
  progressNotesId: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  suffix: string | null;
  enrolmentId: string | null;
  appointmentType: string | null;
  appointmentStartTime: string | null;
  attendees: AttendeeDetailsType[] | null;
  duration: string | null;
  status: string | null;
  commonAppointmentId: string | null;
  appointmentId: string | null;
  attendeeId: string | null;
  clientId: string | null;
}

export type AttendeeDetailsType = {
  attendeeId: string | null;
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  suffix: string | null;
};

export interface ProgressNotesListResposense {
  progressNotes: ProgressNotesListResponseType[];
  count: number;
}
export const getProgressNote = async (
  attendeeId: string,
  commonAppointmentId: string,
): Promise<ProgressNoteType> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `progress-notes?attendeeId=${attendeeId}&commonAppointmentId=${commonAppointmentId}&timezone=${timezone}`,
    ),
  );
};

export interface GroupBilling {
  appointmentId: string | null; // mandatory
  attendeeId: string | null; // mandatory
  groupBillingDuration: string | null;
  groupBillingStartTime: string | null;
}

export const EditProgressNote = async (
  params: ProgressNoteType,
  attendeeId: string,
  commonAppointmentId: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(
    dayjs(getCurrentWeekSundayAsDate()).format('Z'),
  );
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `progress-notes?attendeeId=${attendeeId}&commonAppointmentId=${commonAppointmentId}&timezone=${timezone}`,
      params,
    ),
  );
};

export const CancelProgressNoteVisit = async (
  body: {
    cancelReason: string;
    status: string;
  },
  attendeeId: string,
  appointmentId: string,
  clientId: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().patch(
      `progress-notes?attendeeId=${attendeeId}&appointmentId=${appointmentId}&clientId=${clientId}&timezone=${timezone}`,
      body,
    ),
  );
};

export const CreateDeleteProgressNote = async (
  params: CreateDeleteProgressNoteType,
  attendeeId: string,
  appointmentId: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `progress-notes?attendeeId=${attendeeId}&appointmentId=${appointmentId}&timezone=${timezone}`,
      params,
    ),
  );
};

export const downloadProgressNote = async (
  clientId: string,
  attendeeId: string,
  commonAppointmentId: string,
  localTimeZone: string,
): Promise<Blob> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<Blob>(
      `progress-notes/download?clientId=${clientId}&attendeeId=${attendeeId}&commonAppointmentId=${commonAppointmentId}&timezone=${localTimeZone}`,
      {},
      {
        baseURL: THOM_BASE_URL,
        responseType: 'blob',
      },
    ),
  );
};

export const UpdateGroupBillig = async (
  params: GroupBilling,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `progress-notes/groupBilling`,
      params,
    ),
  );
};

export const getProgressNotesListApi = async (
  clientName: string,
  status: string,
  appointmentStartDate: string,
  appointmentEndDate: string,
  page: number,
  size: number,
): Promise<ProgressNotesListResposense> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `progress-notes/all?clientName=${clientName}&status=${status}&appointmentStartDate=${appointmentStartDate}&page=${page}&size=${size}&appointmentEndDate=${appointmentEndDate}`,
    ),
  );
};

export const getProgressNotesListOnClientApi = async (
  clientId: string,
  attendeeName: string,
  status: string,
  appointmentStartDate: string,
  appointmentEndDate: string,
  page: number,
  size: number,
): Promise<ProgressNotesListResposense> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `progress-notes/facesheet?clientId=${clientId}&attendeeName=${attendeeName}&status=${status}&appointmentStartDate=${appointmentStartDate}&page=${page}&size=${size}&appointmentEndDate=${appointmentEndDate}`,
    ),
  );
};
