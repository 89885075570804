import { ApiResponse } from 'apisauce';
import { EventBusProvider } from '../context/EventBusProvider';

export enum ApiErrorStatus {
  NETWORK_ERROR = 'NETWORK_ERROR',
  CLIENT_ERROR = 'CLIENT_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',
  TIMEOUT_ERROR = 'TIMEOUT_ERROR',
  CONNECTION_ERROR = 'CONNECTION_ERROR',
  CANCEL_ERROR = 'CANCEL_ERROR',
}

export type ApiMessage = {
  message: string;
  status: string;
  code: number;
};

export type ResponseItem = {
  responseId: string;
  formId: string;
};

export class ApiError {
  id: string;

  message: string;

  constructor(id: string, message: string) {
    this.id = id;
    this.message = message;
  }
}

export const isCustomError = <T extends object>(error: T): boolean => {
  return Object.hasOwn(error, 'id') && Object.hasOwn(error, 'message');
};

export const ApiResponseHandler = async <T, U>(
  response: ApiResponse<T, U>,
): Promise<U> => {
  try {
    return await new Promise<U>((resolve, reject) => {
      if (response.ok && response.data) {
        resolve(response.data as U);
      } else if (response.problem !== null) {
        if (response.problem === ApiErrorStatus.CLIENT_ERROR) {
          if (response.status && response.status === 403) {
            const keyToKeep = 'permissionDenied';
            const valueToKeep = localStorage.getItem(keyToKeep);
            sessionStorage.clear();
            localStorage.clear();
            if (valueToKeep !== null) {
              localStorage.setItem(keyToKeep, valueToKeep);
            }
            EventBusProvider.publish('logout');
          } else {
            reject(response.data as ApiMessage);
          }
        } else {
          switch (response.problem) {
            case ApiErrorStatus.NETWORK_ERROR:
              return reject({
                id: 'internetCheckConnectionError',
                message: 'Check internet connection and try again later',
              } as ApiError);

            case ApiErrorStatus.SERVER_ERROR:
              let message = 'Unable to connect server. Please try again later';
              let idValue = 'serverError';
              if (
                response.status === 500 &&
                response.data !== undefined &&
                response.data !== null &&
                typeof response.data === 'object' &&
                'code' in response.data &&
                response.data.code === 1056
              ) {
                message = 'Insufficient permissions to update the appointment.';
              } else if (
                response.status === 500 &&
                response.data !== undefined &&
                response.data !== null &&
                typeof response.data === 'object' &&
                'code' in response.data &&
                response.data.code === 13001
              ) {
                idValue = 'passwordExpired';
                message =
                  'Your access has expired. Please contact support for assistance.';
              }
              return reject({
                id: idValue,
                message: message,
              } as ApiError);

            case ApiErrorStatus.TIMEOUT_ERROR:
              return reject({
                id: 'requestTimeoutError',
                message: 'Request timeout. Please try again',
              } as ApiError);

            case ApiErrorStatus.CONNECTION_ERROR:
              return reject({
                id: 'serverError',
                message: 'Unable to connect server. Please try again later',
              } as ApiError);

            case ApiErrorStatus.CANCEL_ERROR:
              return reject({
                id: 'requestCancelledError',
                message: 'Request cancelled',
              } as ApiError);

            default:
              return reject({
                id: 'somethingWrongError',
                message: 'Something went wrong. Please try again later',
              } as ApiError);
          }
        }
      } else {
        return reject({
          id: 'somethingWrongError',
          message: 'Something went wrong. Please try again later',
        } as ApiError);
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
