import React, { useEffect, useState } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker, DatePickerSlots } from '@mui/x-date-pickers/DatePicker';
import { FormattedMessage } from 'react-intl';
import './Textinput.css';
import dayjs, { Dayjs } from 'dayjs';

interface DatePickerComponentProps {
  labelid: string;
  defaultlabelid: string;
  disabledDate?: boolean;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  value: Dayjs | null;
  handlechange?: (value: Dayjs | null) => void;
  onYearChange?: (value: Dayjs) => void;
  disableFuture?: boolean;
  inputFormat?: string;
  labelRequired?: boolean;
  views?: Array<'day' | 'month' | 'year'>;
  slots?: DatePickerSlots<Dayjs>;
  className?: string;
  needCrossIcon?: boolean;
  disableManualInput?: boolean;
  required?: boolean;
  name?: string;
  placeholderOnfloatBackgroundColor?: string;
}

const DatePickerComponent = ({
  labelid,
  defaultlabelid,
  disabledDate,
  minDate,
  value,
  handlechange,
  onYearChange,
  disableFuture,
  inputFormat,
  maxDate,
  labelRequired,
  views,
  slots,
  className,
  needCrossIcon,
  disableManualInput,
  required,
  name,
  placeholderOnfloatBackgroundColor,
}: DatePickerComponentProps) => {
  const [valueDate, setValueDate] = useState<Dayjs | null>(value);
  const [valueDateForBlur, setValueDateForBlur] = useState<Dayjs | null>(value);
  const [helperDate, setHelperDate] = useState<Dayjs | null>(value);
  const internalHandleDateChange = (date: Dayjs | null) => {
    setValueDateForBlur(date);
    if (handlechange) {
      if (date?.isValid()) {
        setHelperDate(date);
        setValueDate(date);
        handlechange(date);
      } else if (!date?.day() && !date?.month() && !date?.year()) {
        setValueDate(null);
        handlechange(null);
      }
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (value === null || value?.isValid()) {
      setValueDate(value);
      setHelperDate(value);
      setValueDateForBlur(value);
    }
  }, [value]);
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        name={name}
        slotProps={{
          field: {
            clearable: needCrossIcon ?? true,
            onClear: () => {
              setValueDate(null);
              setHelperDate(null);
            },
            readOnly: disabledDate,
          },
          textField: {
            required: required,
            disabled: disableManualInput ?? false,
            onFocus: () => {
              if (valueDate === null) {
                setValueDate(helperDate);
                internalHandleDateChange(helperDate);
              }
            },
            onBlur: () => {
              if (!dayjs(valueDateForBlur).isValid()) {
                setValueDate(null);
                internalHandleDateChange(null);
              }
            },
            onKeyDown: (event) => {
              if (
                (event.code === 'Backspace' || event.code === 'Delete') &&
                !disabledDate
              ) {
                setHelperDate(null);
                setValueDate(null);
              }
            },
          },
        }}
        slots={slots}
        className={
          ` datePickerStyle datepicker ${
            disabledDate ? ' disabledTextColor ' : ' notDisabledTextColor '
          }` + className
        }
        label={
          labelRequired ? (
            ''
          ) : (
            <FormattedMessage id={labelid} defaultMessage={defaultlabelid} />
          )
        }
        disabled={disabledDate}
        open={open}
        minDate={minDate}
        maxDate={maxDate ?? dayjs('01/01/2100')}
        onOpen={handleOpen}
        onClose={handleClose}
        value={valueDate?.isValid() ? valueDate : null}
        onChange={internalHandleDateChange}
        onYearChange={onYearChange}
        disableFuture={disableFuture}
        format={inputFormat ?? 'MM/DD/YYYY'}
        views={views ?? ['year', 'month', 'day']}
        sx={{
          color: '#2a4241',
          fontSize: '14px',
          fontFamily: 'Lato-Regular',
          minWidth: '75px !important',
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused fieldset': {
              border: '1px solid #00C6B8',
            },
            '&:hover fieldset': {
              borderColor: '#00C6B8',
            },
            '&.Mui-error fieldset': {
              borderColor: '#97A6A5',
            },
          },
          '&.datepicker > label.MuiFormLabel-filled': {
            backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
            color: '#97A6A5',
            paddingLeft: '4px',
            paddingRight: '4px',
          },
          '&.datepicker > label.Mui-focused': {
            backgroundColor: placeholderOnfloatBackgroundColor ?? 'white',
            color: '#00C6B8',
            paddingLeft: '4px',
            paddingRight: '4px',
          },
          '& .MuiInputLabel-root': {
            '&.Mui-error': {
              color: '#97A6A5',
            },
          },
        }}
      />
    </LocalizationProvider>
  );
};
export default DatePickerComponent;
