import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import MediumTypography from './MediumTypography';

interface CheckboxProps {
  labelid?: string;
  defaultlabelid?: string;
  ischecked?: boolean;
  disabled?: boolean;
  CheckHandleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  name?: string;
  fontWeight?: number;
  fontFamily?: string;
  className?: string;
}
const CheckBoxComponent: React.FC<CheckboxProps> = (props) => {
  const {
    labelid,
    defaultlabelid,
    ischecked,
    disabled,
    name,
    label,
    CheckHandleChange,
    fontWeight,
    fontFamily,
    className,
  } = props;
  const handleKeyDown = (event: React.KeyboardEvent<HTMLButtonElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      (event.currentTarget as HTMLButtonElement).click();
    }
  };
  return (
    <FormControlLabel
      className={className}
      disabled={disabled}
      control={
        <Checkbox
          name={name}
          className={className}
          checked={ischecked}
          onChange={CheckHandleChange}
          onKeyDown={handleKeyDown}
          style={{
            color: ischecked
              ? 'var(--thom-green-primary)'
              : 'var(--thom-mono-gray)',
            cursor: 'pointer',
          }}
        />
      }
      label={
        <MediumTypography
          sxProps={{
            fontFamily: fontFamily ?? 'Lato-Bold',
            fontStyle: 'normal',
            fontWeight: fontWeight ?? 600,
            fontSize: '14px',
            lineHeight: '17px',
            cursor: 'pointer',
          }}
          label={label}
          labelid={labelid}
          defaultlabel={defaultlabelid}
        />
      }
    />
  );
};
export default CheckBoxComponent;
