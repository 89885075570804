// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fabStyles.MuiFab-root {
  border-radius: 5px;
  border: 0px 0px 0px 4px;
  padding: 16px;
  font-family: 'Lato-Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
  /* color: #2a4241; */
  z-index: 0;
  height: 32px;
  box-shadow: 0px 2px 2px 0px #21326226;
}

.fabStyles > p {
  font-family: 'Lato-Regular', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-transform: none;
}
`, "",{"version":3,"sources":["webpack://./src/pages/taskManager/TaskManagerList.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,uBAAuB;EACvB,aAAa;EACb,uCAAuC;EACvC,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,oBAAoB;EACpB,UAAU;EACV,YAAY;EACZ,qCAAqC;AACvC;;AAEA;EACE,uCAAuC;EACvC,eAAe;EACf,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".fabStyles.MuiFab-root {\n  border-radius: 5px;\n  border: 0px 0px 0px 4px;\n  padding: 16px;\n  font-family: 'Lato-Regular', sans-serif;\n  font-size: 16px;\n  font-weight: 500;\n  text-transform: none;\n  /* color: #2a4241; */\n  z-index: 0;\n  height: 32px;\n  box-shadow: 0px 2px 2px 0px #21326226;\n}\n\n.fabStyles > p {\n  font-family: 'Lato-Regular', sans-serif;\n  font-size: 16px;\n  font-weight: 500;\n  text-transform: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
