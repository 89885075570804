import { FC, CSSProperties } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';

interface CheckboxProps {
  onCheckBoxClick?: (checked: boolean) => void;
  disable?: boolean;
  style?: CSSProperties;
  value: boolean;
  className?: string;
}

const CustomCheckBox: FC<CheckboxProps> = ({
  onCheckBoxClick,
  disable,
  value,
  style,
  className,
}) => {
  return (
    <FormControlLabel
      className="mr-none"
      disabled={disable}
      control={
        <Checkbox
          className={className}
          style={{
            color: value
              ? 'var(--thom-green-primary)'
              : 'var(--thom-mono-gray)',
            cursor: 'pointer',
            ...style,
          }}
          checked={value}
          disabled={disable}
          onChange={() => {
            if (onCheckBoxClick !== undefined) {
              onCheckBoxClick(!value);
            }
          }}
          sx={{
            '&.Mui-checked': {
              color: 'var(--thom-green-primary)',
            },
          }}
        />
      }
      label={null}
    />
  );
};

export default CustomCheckBox;
