import { Id } from '../../../components/formlib/modal/ShareDownloadModal';
import { Notification } from '../../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../../ApiResponseHandler';
import ThomApiClient from '../../ThomApiClient';

export type NotificationCenterType = {
  id: string;
  userId: string;
  programId: string;
  notificationType: string;
  notificationDate: string;
  notificationId: string;
  notificationIdTwo: string;
  notificationIdThree: string;
  notificationRead: boolean;
  title: string;
  body: string;
  createdOn: string;
  referralId: string | null;
};

export type NotificationMain = {
  notificationDetails: NotificationCenterType[];
  totalCount: number;
  unreadCount: string;
};

export const notifications = async (
  params: Notification,
): Promise<ApiMessage> => {
  const url = `notification`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const getNotificationsApi = async (
  employeeId: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
  notificationsRead: boolean | null,
): Promise<NotificationMain> => {
  let url = `/notification?employeeId=${employeeId}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType}`;

  if (notificationsRead === false) {
    url += `&notificationRead=${notificationsRead}`;
  }

  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<NotificationMain>(url),
  );
};

export const getNotificationReadApi = async (
  notificationId: string,
  notificationRead: boolean,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().patch(
      `notification?notificationId=${notificationId}&notificationRead=${notificationRead}`,
    ),
  );
};

export const updateAllNotificationAsRead = async (
  employeeId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(`mark-read?employeeId=${employeeId}`),
  );
};

export const deleteNotificationById = async (
  id: string,
  employeeId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete(
      `notification?employeeId=${employeeId}&id=${id}`,
    ),
  );
};

export const shareOption = async (
  clientId: string | null,
  localTimezone: string,
  params: Id,
): Promise<ApiMessage> => {
  const url = `intake/email?clientId=${clientId}&
  timezone=${localTimezone}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const shareOptionConsent = async (
  clientId: string | null,
  localTimezone: string,
  params: Id,
  type: string | undefined,
  id: string | undefined,
): Promise<ApiMessage> => {
  const url = `consent/email?clientId=${clientId}&timezone=${localTimezone}&type=${type}&consentId=${id}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const shareOptionEics = async (
  clientId: string | null,
  localTimezone: string,
  params: Id,
): Promise<ApiMessage> => {
  const url = `eics/email?clientId=${clientId}&
  timezone=${localTimezone}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const shareOptionProgressNote = async (
  clientId: string | null,
  localTimezone: string,
  params: Id,
  attendeeId: string | undefined,
  commonAppointmentId: string | undefined,
): Promise<ApiMessage> => {
  const url = `progress-notes/email?clientId=${clientId}&timezone=${localTimezone}&attendeeId=${attendeeId}&commonAppointmentId=${commonAppointmentId}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};

export const shareOptionIFSP = async (
  clientId: string | null,
  localTimezone: string,
  params: Id,
): Promise<ApiMessage> => {
  const url = `ifsp/email?clientId=${clientId}&timezone=${localTimezone}`;
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(url, params),
  );
};
