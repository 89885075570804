import React, { useContext, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Box, styled } from '@mui/material';
import { PROGRAM_MANAGEMENT_ROUTE_NAME } from '../../routes/Routing';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import AddNewProgram from './AddNewProgram';
import ProgramConfiguration from './ProgramConfiguration';
import CreateHolidayList from '../holidays/CreateHolidayList';
import TitleText from '../../components/formlib/TitleText';
import CustomBackArrow from '../../components/imagepickers/backArrow';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';
import ModalPopup from '../../components/formlib/ModalPopup';
const theme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00C6B8',
          height: 2,
        },
      },
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  className?: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props;
  return (
    <Box
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={className}
    >
      {value === index && (
        <Box sx={{ p: '24px 0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  );
}

export interface TabProps {
  tabPosition: number;
}

const CustomTabs = styled(Tabs)({
  '& .MuiButtonBase-root.MuiTab-root': {
    padding: '0px 0px',
    maxWidth: '100%',
    minWidth: 'auto',
    margin: '0px 10px',
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
    lineHeight: '20px',
  },
  '&.Mui-selected': {
    color: '#008C82',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
  },
});

const CustomTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    color: '#00C6B8',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
    lineHeight: '20px',
  },
}));

function AddProgramSetup() {
  const navigate = useNavigate();
  const location = useLocation();
  const [pId, setPId] = useState('');
  const [tabProp, setTabProp] = React.useState<TabProps>({
    tabPosition: 0,
  });
  const [openUnSavedModal, setOpenUnSavedModal] = useState<boolean>(false);
  const [pendingPosition, setPendingPosition] = useState<number>(0);
  const { hasUnsavedChanges, handleUpdateUnsavedChanges } = useContext(
    UnSavedChangesContext,
  );

  useEffect(() => {
    const { state } = location;
    if (state !== undefined && state !== null) {
      setPId(state.pId);
    }
  }, []);

  const handleTabChange = (
    _event: React.ChangeEvent<object>,
    position: number,
  ) => {
    setPendingPosition(position);
    if (!hasUnsavedChanges) {
      setOpenUnSavedModal(false);
      setTabProp({ ...tabProp, tabPosition: position });
    } else {
      setOpenUnSavedModal(true);
    }
  };

  return (
    <Box component="main">
      <ThemeProvider theme={theme}>
        <ModalPopup
          open={openUnSavedModal}
          description="UnsavedAlertDescriptionWhileSwitching"
          labelId1="UnSavedAlertCancel"
          labelId2="UnsavedAlertSwitch"
          negativeActionLabel="No"
          positiveActionLabel="Yes"
          onCancel={() => {
            setOpenUnSavedModal(false);
          }}
          onOk={() => {
            setTabProp({ ...tabProp, tabPosition: pendingPosition });
            handleUpdateUnsavedChanges(false);
            setOpenUnSavedModal(false);
          }}
        />
        <Box component="section">
          <Box className="rowContainer pb-xs">
            <CustomBackArrow
              onClick={() => navigate(PROGRAM_MANAGEMENT_ROUTE_NAME)}
            />
            <Box component="div" className="ml-md">
              <TitleText
                label={
                  location?.state
                    ? `Program Management - (${location.state.programAcronym})`
                    : 'Program Management'
                }
                defaultlabel="Referral"
              />
            </Box>
          </Box>

          <Box component="div" sx={{ marginTop: '-8px' }}>
            <CustomTabs
              value={tabProp.tabPosition}
              onChange={handleTabChange}
              aria-label="secondary tabs example"
            >
              <CustomTab label="Program Details" className="ml-none" />
              <CustomTab label="Program Configuration" className="ml-none" />
              <CustomTab label="Holiday List" />
            </CustomTabs>
            <TabPanel
              value={tabProp.tabPosition}
              index={0}
              className="tabPanelToppadding"
            >
              <AddNewProgram />
            </TabPanel>
            <TabPanel
              value={tabProp.tabPosition}
              index={1}
              className="tabPanelToppadding"
            >
              <ProgramConfiguration programId={location.state?.programId} />
            </TabPanel>
            <TabPanel
              value={tabProp.tabPosition}
              index={2}
              className="tabPanelToppadding"
            >
              <CreateHolidayList programId={pId} />
            </TabPanel>
          </Box>
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default AddProgramSetup;
