import { AddNewReferral } from '../../utils/type';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';

export interface ReferralLookup {
  id: string;
  code: string;
  description: string;
}

export interface EmployeeLookUpResponse {
  referralReason: ReferralLookup[];
  referralSource: ReferralLookup[];
}

export interface GetReferralDetailsResponse {
  referralCount: number;
  referrals: AddNewReferral[];
}
export const getReferralDetails = async (
  searchquery: string,
  page: number,
  size: number,
  sort: string,
  sortType: string,
): Promise<GetReferralDetailsResponse> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get<GetReferralDetailsResponse>(
      `referral?search=${searchquery}&page=${page}&size=${size}&sort=${sort}&sortType=${sortType}`,
    ),
  );
};

export const deleteReferralById = async (
  referralId: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().delete<ApiMessage>(
      `deleteReferral?id=${referralId}`,
    ),
  );
};
