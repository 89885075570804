import Box from '@mui/material/Box';
import ButtonComponent from './ButtonComponent';
import MediumTypography from './MediumTypography';
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Slide,
} from '@mui/material';
import { ReactComponent as WarningIcon } from '../../assets/images/WarningIcon.svg';
import { ReactComponent as WarningText } from '../../assets/images/Warningtext.svg';
import { TransitionProps } from '@mui/material/transitions';
import React, { useEffect } from 'react';
interface ModalProps {
  open: boolean;
  maxWidth?: Breakpoint;
  onClose?: () => void;
  labelId1?: string;
  description: string;
  defaultDescription?: string;
  positiveActionLabel?: string;
  labelId2?: string;
  negativeActionLabel?: string;
  onOk?: () => void;
  onCancel?: () => void;
  hidePositiveBtn?: boolean;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<ModalProps>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalPopup(props: Readonly<ModalProps>) {
  const {
    open,
    onClose,
    onOk,
    onCancel,
    description,
    defaultDescription,
    labelId1,
    labelId2,
    positiveActionLabel,
    negativeActionLabel,
    maxWidth,
    hidePositiveBtn,
  } = props;

  useEffect(() => {
    try {
      const customEvent = new CustomEvent('openModel', {
        detail: {
          message: 'This is a custom event for openModel',
          time: new Date(),
        },
      });
      document.dispatchEvent(customEvent);
    } catch (ex) {
      console.log('openModel CustomEvent', ex);
    }
  }, [open]);

  return (
    <Box>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        fullWidth
        maxWidth={maxWidth ?? 'xs'}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent sx={{ p: 0 }} className="p-lg">
          <DialogContentText id="alert-dialog-slide-description">
            <Grid container rowSpacing={'24px'} columnSpacing={'4px'}>
              <Grid item xs={2} pl={0} display={'flex'} justifyContent={'left'}>
                <WarningIcon />
              </Grid>
              <Grid item xs={10}>
                <WarningText />
                <MediumTypography
                  labelid={description}
                  defaultlabel={
                    defaultDescription ?? 'Are you sure you want reset?'
                  }
                  fontweight={500}
                  textColor="#2a4241"
                  sxProps={{ lineHeight: 1.5 }}
                />
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogActions className="pt-sm" sx={{ p: 0 }}>
            <Box>
              {onCancel && (
                <ButtonComponent
                  className="btn-primary btn-cancel alrt_Width_btn"
                  variantType="contained"
                  labelId={labelId1}
                  defaultLabelId={negativeActionLabel}
                  onClick={onCancel}
                />
              )}
              {!hidePositiveBtn && (
                <ButtonComponent
                  variantType="contained"
                  type="submit"
                  className="btn-primary btn-submit alrt_Width_btn ml-md"
                  labelId={labelId2}
                  defaultLabelId={positiveActionLabel}
                  onClick={onOk}
                />
              )}
            </Box>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
