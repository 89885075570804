import { initializeApp } from 'firebase/app';
import { getToken, getMessaging, onMessage } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyBE3kjVi637smh3X4azL3FkZXU_sEDicoY',
  authDomain: 'thomchild-b8b25.firebaseapp.com',
  projectId: 'thomchild-b8b25',
  storageBucket: 'thomchild-b8b25.appspot.com',
  messagingSenderId: '217753177640',
  appId: '1:217753177640:web:c109389538be26d4382f1d',
  measurementId: 'G-ZT5JYMLB1N',
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export const getOrRegisterServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    return window.navigator.serviceWorker
      .getRegistration('/firebase-push-notification-scope')
      .then(async (serviceWorker) => {
        await serviceWorker?.unregister();
        // if (serviceWorker) return serviceWorker;
        return window.navigator.serviceWorker.register(
          '/firebase-messaging-sw.js',
          {
            scope: '/firebase-push-notification-scope',
          },
        );
      });
  }
  throw new Error('The browser doesn`t support service worker.');
};

export const getFirebaseToken = () =>
  getOrRegisterServiceWorker().then((serviceWorkerRegistration) =>
    getToken(messaging, {
      vapidKey: process.env.REACT_APP_VAPID_KEY,
      serviceWorkerRegistration,
    }),
  );

export const onForegroundMessage = () =>
  new Promise((resolve) => onMessage(messaging, (payload) => resolve(payload)));

export { onMessage, messaging };
