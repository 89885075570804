import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import MediumTypography from './MediumTypography';
import { forEach } from 'lodash';

interface ChipDisplayProps {
  items: string[];
  width: number;
}

const ChipDisplay: React.FC<ChipDisplayProps> = React.memo(({ items, width }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleItems, setVisibleItems] = useState<string[]>([]);
  const [remainingCount, setRemainingCount] = useState(0);

  const calculateVisibleItems = () => {
    let currentWidth = 0;
    let visibleItemLocal: string[] = [];
    forEach(items, (item, index) => {
      if(index === 0) {
        visibleItemLocal = [item];
      } else {    
        if (currentWidth + (items[index].length * 10) < width) {
          visibleItemLocal = [...visibleItemLocal, item];
          currentWidth += (items[index].length * 10);
        }
      }
      }
    );
    setVisibleItems(visibleItemLocal);
    setRemainingCount(
      items.length - visibleItemLocal.length
    )
  };

  useEffect(() => {
    calculateVisibleItems(); // Initial calculation
    return () => {
    };
  }, [items]);

  return (
    <Box
      ref={containerRef}
      style={{ display: 'flex', flexWrap: 'nowrap', overflow: 'hidden' }}
    >
      {visibleItems.map((item, index) => (
        <Box
          key={index}
          style={{
            padding: '0px 5px',
            border: '1px solid #E7F0F0',
            margin: '0 5px',
            borderRadius: '5px',
            background: '#E7F0F0',
            maxWidth: `${width}px`, // Set your desired max width
            whiteSpace: 'nowrap',
          }}
        >
          {item && (
            <MediumTypography
              label={item}
              sxProps={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            />
          )}
        </Box>
      ))}
      {remainingCount > 0 && (
        <Box
          style={{
            padding: '0px 5px',
            whiteSpace: 'nowrap',
            fontWeight: '600',
          }}
        >
          <MediumTypography
            label={`+${remainingCount}`}
            sxProps={{
              color: '#008C82',
            }}
          />
        </Box>
      )}
    </Box>
  );
},
(prevProps, nextProps) => {
  return prevProps.items === nextProps.items;
});

export default ChipDisplay;
