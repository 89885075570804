import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import ParentGuardianInfo from '../../pages/ParentGuardianInfo';
import ClientPage from '../../pages/ClientPage';
import CommunicationLogPage from '../../pages/communicationLog/CommunicationLogPage';
import Insurance from '../../pages/Insurance';
import { AddNewClient } from '../../utils/type';
import Contacts from '../../pages/Contacts';
import ClientFilesPage from '../../pages/clientFiles/ClientFilesPage';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';
import ModalPopup from './ModalPopup';
import WaiversScreen from '../../pages/waivers/WaiversScreen';

export const TabTheme = createTheme({
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00C6B8',
          height: '2px',
          bottom: '5%',
        },
      },
    },
  },
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const CustomTab = styled(Tab)(() => ({
  '&.Mui-selected': {
    color: '#00C6B8',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
  },
}));
export const CustomTabs = styled(Tabs)({
  '& .MuiButtonBase-root.MuiTab-root': {
    padding: '8px 0px 0px',
    maxWidth: '100%',
    minWidth: 'auto',
    margin: '0px 12px',
    fontFamily: 'Lato-Regular',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'none',
  },
  '&.Mui-selected': {
    color: '#008C82',
    fontFamily: 'Lato-Regular',
    fontWeight: '700',
    fontSize: '14px',
  },
});
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export interface TabProps {
  clientCreated: boolean;
  tabPosition: number;
}

export function TabPanel(props: Readonly<TabPanelProps>) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: '16px 0px' }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
export default function TabNavigationComponent() {
  const [tabProp, setTabProp] = React.useState<TabProps>({
    clientCreated: false,
    tabPosition: 0,
  });
  const [openUnSavedModal, setOpenUnSavedModal] =
    React.useState<boolean>(false);
  const [pendingPosition, setPendingPosition] = React.useState<number>(0);
  const { hasUnsavedChanges, handleUpdateUnsavedChanges } = React.useContext(
    UnSavedChangesContext,
  );
  React.useEffect(() => {
    const clientId = localStorage.getItem('ClientId');
    const tabNames = sessionStorage.getItem('tabName');
    const tabPositionValue = parseInt(tabNames ?? '', 10);
    if (tabNames !== null) {
      setTabProp((prevState) => ({
        ...prevState,
        tabPosition: isNaN(tabPositionValue) ? 0 : tabPositionValue,
        clientCreated: clientId !== null && clientId.length > 0,
      }));
    } else {
      setTabProp(() => {
        return {
          tabPosition: 0,
          clientCreated: clientId !== null && clientId.length > 0,
        };
      });
    }
  }, []);

  const handleChange = (
    _event: React.ChangeEvent<object>,
    position: number,
  ) => {
    setPendingPosition(position);
    if (!hasUnsavedChanges) {
      setOpenUnSavedModal(false);
      sessionStorage.setItem('tabName', String(position));
      setTabProp({ ...tabProp, tabPosition: position });
    } else {
      setOpenUnSavedModal(true);
    }
  };

  const [data, setData] = React.useState<AddNewClient>();

  const updateData = (newData: AddNewClient) => {
    setData(newData);
  };

  return (
    <Box>
      <ModalPopup
        open={openUnSavedModal}
        description="UnsavedAlertDescriptionWhileSwitching"
        labelId1="UnSavedAlertCancel"
        labelId2="UnsavedAlertSwitch"
        negativeActionLabel="No"
        positiveActionLabel="Yes"
        onCancel={() => {
          setOpenUnSavedModal(false);
        }}
        onOk={() => {
          sessionStorage.setItem('tabName', String(pendingPosition));
          setTabProp({ ...tabProp, tabPosition: pendingPosition });
          handleUpdateUnsavedChanges(false);
          setOpenUnSavedModal(false);
        }}
      />
      <ThemeProvider theme={TabTheme}>
        <Box sx={{}}>
          <CustomTabs
            value={tabProp.tabPosition}
            onChange={handleChange}
            aria-label="secondary tabs example"
          >
            <CustomTab
              {...a11yProps(0)}
              label="Demographic"
              className="ml-none"
            />
            <CustomTab
              {...a11yProps(1)}
              label="Guardian"
              disabled={!tabProp.clientCreated}
            />

            <CustomTab
              {...a11yProps(2)}
              label="Contacts"
              disabled={!tabProp.clientCreated}
            />
            <CustomTab
              {...a11yProps(3)}
              label="Insurance"
              disabled={!tabProp.clientCreated}
            />
            <CustomTab
              {...a11yProps(4)}
              label="Communication Log"
              disabled={!tabProp.clientCreated}
            />
            {/* <CustomTab
              label="Referral Info"
              disabled={!tabProp.clientCreated}
            />
            <CustomTab
              label="General Referral"
              disabled={!tabProp.clientCreated}
            /> */}
            <CustomTab
              label="Client Documents"
              disabled={!tabProp.clientCreated}
            />
            <CustomTab label="Waivers" disabled={!tabProp.clientCreated} />
          </CustomTabs>
          <TabPanel value={tabProp.tabPosition} index={0}>
            <ClientPage updateData={updateData} setTabProp={setTabProp} />
          </TabPanel>
          <TabPanel value={tabProp.tabPosition} index={1}>
            {data && <ParentGuardianInfo addressData={data} />}
          </TabPanel>
          <TabPanel value={tabProp.tabPosition} index={2}>
            <Contacts />
          </TabPanel>
          <TabPanel value={tabProp.tabPosition} index={3}>
            <Insurance setTabProp={setTabProp} />
          </TabPanel>
          <TabPanel value={tabProp.tabPosition} index={4}>
            <CommunicationLogPage />
          </TabPanel>
          <TabPanel value={tabProp.tabPosition} index={5}>
            <ClientFilesPage />
          </TabPanel>
          <TabPanel value={tabProp.tabPosition} index={6}>
            <WaiversScreen />
          </TabPanel>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
