import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import {
  Card,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@mui/material';
import MediumTypography from '../../components/formlib/MediumTypography';
import { ReactComponent as DownArrow } from '../../assets/images/downarrowcolored.svg';
import { ReactComponent as UpArrow } from '../../assets/images/uparrowcolored.svg';
import KeyValueComponent, {
  DataJsonType,
} from '../../components/forms/KeyValueComponent';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import {
  InsuranceType,
  OptionType,
  ResponseType,
  employeeRoles,
} from '../../utils/type';
import { badgePicker } from '../Insurance';
import { ReactComponent as AddNew } from '../../assets/images/Plus.svg';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import CustomRadioButton from '../../components/formlib/CustomRadioButton';
import {
  EmployeeListType,
  getAllEmployeeMinimalList,
} from '../../services/ifspClient/IfspApi';
import DropdownComponent from '../../components/formlib/DropdownComponent';
import {
  IfspTeamMembersType,
  TeamMembersType,
} from '../ifsp/ifspContextApi/IfspContex';
import IfspTeamMembersTableRow from '../ifsp/IfspTeamMemberTableRow';
import ModalPopup from '../../components/formlib/ModalPopup';
import { primaryResidenceValues } from '../Dropdown';
import CommentTextbox from '../../components/formlib/CommentTextbox';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import {
  IntakeClientInfoResponseType,
  getIntakeDetailsByTab,
  putIntakeClientInfo,
} from '../../services/configApi/forms/Intake/IntakeServices';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import {
  DownloadContext,
  DownloadContextType,
  TabProps,
  showCancelToaster,
} from './IntakeTabNavigationComponent';
import { convertGenderToFrontend } from '../../utils/genderUtil';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { calculateMonths } from '../../utils/dateUtil';
import {
  LookUpContext,
  LookUpContextType,
} from '../../context/LookUpContextProvider';
import { IntakeRadioData } from './IntakePregnancyInfo';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';

export interface RoutingProps {
  setTabProp: React.Dispatch<React.SetStateAction<TabProps>>;
}

export const EICSRadioData = [
  {
    value: 'Yes',
    labelId: 'Intake.RadioLabelA',
    defaultLabel: 'Yes',
  },
  {
    value: 'No',
    labelId: 'Intake.RadioLabelB',
    defaultLabel: 'No',
  },
  {
    value: 'Unknown',
    labelId: 'Intake.RadioLabelC',
    defaultLabel: 'Unknown',
  },
];

interface Data {
  name: string;
  type: string;
  Subscriber: string;
  tableEffectiveStartdate: string;
  effectivedate: string;
  status: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'name',
    labelId: 'Insurance.Name',
    defaultLabelId: 'Insurance Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'type',
    labelId: 'Insurance.Type',
    defaultLabelId: 'Type',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'Subscriber',
    labelId: 'Insurance.Subscriber',
    defaultLabelId: 'Subscriber',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'tableEffectiveStartdate',
    labelId: 'insurance.effectiveStartDate',
    defaultLabelId: 'Effective Start Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'effectivedate',
    labelId: 'Insurance.Effectivedate',
    defaultLabelId: 'Effective End Date',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'status',
    labelId: 'Insurance.status',
    defaultLabelId: 'Eligibility Status',
    numeric: false,
    requiredSorting: false,
  },
];

interface TableData {
  name: string;
  discipline: string;
  role: string;
  email: string;
  phoneNumber: string;
  actions: string;
}

const teamHeadCells: HeadCell<TableData>[] = [
  {
    id: 'name',
    labelId: 'Name',
    defaultLabelId: 'Name',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'discipline',
    labelId: 'Discipline',
    defaultLabelId: 'Discipline',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'role',
    labelId: 'Role',
    defaultLabelId: 'Role',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'email',
    labelId: 'Email',
    defaultLabelId: 'Email',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'phoneNumber',
    labelId: 'Phone Number',
    defaultLabelId: 'Phone Number',
    numeric: false,
    requiredSorting: false,
  },
  {
    id: 'actions',
    labelId: 'Actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const validationSchema = yup.object().shape({
  formHandleFlag: yup.boolean(),
  yetToAddOtherContactId: yup.string().when('formHandleFlag', {
    is: false,
    then: yup
      .string()
      .required('IFSP.yetToAddOtherContactId.validation')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  yetToAddRoleId: yup.string().when('formHandleFlag', {
    is: false,
    then: yup.string().required('IFSP.yetToAddRoleId.validation').nullable(),
    otherwise: yup.string().nullable(),
  }),
  yetToAdddisciplineId: yup.string().when('formHandleFlag', {
    is: false,
    then: yup
      .string()
      .required('IFSP.yetToAddDisciplineId.validation')
      .nullable(),
    otherwise: yup.string().nullable(),
  }),
  concernsDescription: yup.string(),
  prioritiesDescription: yup.string(),
  gainDescription: yup.string(),
  comment: yup.string(),
  primaryResidence: yup.string().nullable(),
  biologicalParents: yup.string().nullable(),
  childBorn: yup.string().nullable(),
  childAdopted: yup.string().nullable(),
});

export interface IntakeClientInfoType {
  demographics: DataJsonType[];
  parent: {
    primary: DataJsonType[];
    secondary: DataJsonType[];
  };
  insurance: InsuranceType[];
  teamMembers: {
    others: TeamMembersType[];
  };
  biologicalParents: string;
  formHandleFlag: boolean;
  yetToAddOtherContactId: string;
  yetToAddRoleId: string;
  yetToAdddisciplineId: string;
  concernsDescription: string;
  prioritiesDescription: string;
  gainDescription: string;
  primaryResidence: string;
  childBorn: string;
  childAdopted: string;
  comment: string;
}

export interface IntakeformikType {
  formHandleFlag: boolean;
  yetToAddOtherContactId: string;
  yetToAddRoleId: string;
  yetToAdddisciplineId: string;
  concernsDescription: string;
  prioritiesDescription: string;
  gainDescription: string;
  biologicalParents: string;
  primaryResidence: string;
  childBorn: string;
  childAdopted: string;
  comment: string;
}

const IntakeFormInitialValues = {
  demographics: [
    {
      id: 'ProgressNote.ClientName',
      labelid: 'ProgressNote.ClientName',
      defaultLabel: 'Client Name:',
      value: '-',
    },
    {
      id: 'ProgressNote.DPHID',
      labelid: 'ProgressNote.DPHID',
      defaultLabel: 'Enrollment ID:',
      value: '-',
    },
    {
      id: 'ProgressNote.DateofBirth',
      labelid: 'ProgressNote.DateofBirth',
      defaultLabel: 'Date of Birth:',
      value: '-',
    },
    {
      id: 'age',
      labelid: 'age',
      defaultLabel: 'Age:',
      value: '-',
    },
    {
      id: 'ProgressNote.Sex',
      labelid: 'ProgressNote.Sex',
      defaultLabel: 'Sex:',
      value: '-',
    },
    {
      id: 'ConsentForms.clientAddress',
      labelid: 'ConsentForms.clientAddress',
      defaultLabel: 'Address:',
      value: '-',
    },
  ],
  parent: {
    primary: [
      {
        id: 'ProgressNote.Name',
        labelid: 'ProgressNote.Name',
        defaultLabel: 'Name:',
        value: '-',
      },
      {
        id: 'Facesheet.relationship',
        labelid: 'Facesheet.relationship',
        defaultLabel: 'Guardian Type:',
        value: '-',
      },
      {
        id: 'ConsentForms.contactPhone',
        labelid: 'ConsentForms.contactPhone',
        defaultLabel: 'Phone:',
        value: '-',
      },
      {
        id: 'ConsentForms.clientAddress',
        labelid: 'ConsentForms.clientAddress',
        defaultLabel: 'Address:',
        value: '-',
      },
    ],
    secondary: [
      {
        id: 'ProgressNote.Name',
        labelid: 'ProgressNote.Name',
        defaultLabel: 'Name:',
        value: '-',
      },
      {
        id: 'Facesheet.relationship',
        labelid: 'Facesheet.relationship',
        defaultLabel: 'Guardian Type:',
        value: '-',
      },
      {
        id: 'ConsentForms.contactPhone',
        labelid: 'ConsentForms.contactPhone',
        defaultLabel: 'Phone:',
        value: '-',
      },
      {
        id: 'ConsentForms.clientAddress',
        labelid: 'ConsentForms.clientAddress',
        defaultLabel: 'Address:',
        value: '-',
      },
    ],
  },
  insurance: [],
  teamMembers: {
    others: [],
  },
  biologicalParents: '',
  formHandleFlag: false,
  yetToAddOtherContactId: '',
  yetToAddRoleId: '',
  yetToAdddisciplineId: '',
  concernsDescription: '',
  prioritiesDescription: '',
  gainDescription: '',
  primaryResidence: '',
  childBorn: '',
  childAdopted: '',
  comment: '',
};

const IntakeClientsInfo: React.FC<RoutingProps> = ({ setTabProp }) => {
  const clientId = localStorage.getItem('ClientId');
  const [showAlertDialog, setShowAlertDialog] = useState<boolean>(false);
  const [deleteWaring, setDeleteWarning] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number>(0);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;

  const [toastrDefaultMessage, setToastrDefaultMessage] = React.useState('');
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<ResponseType>('success');

  const [openDemographicSection, setOpenDemographicSection] = useState(true);
  const [openParentSection, setOpenParentSection] = useState(true);
  const [openInsuranceSection, setOpenInsuranceSection] = useState(true);
  const [openTeamSection, setOpenTeamSection] = useState(true);
  const [openParentConcernsSection, setOpenParentConcernsSection] =
    useState(true);
  const [openAdditionalSection, setOpenAdditionalSection] = useState(true);
  const [openEICSSection, setOpenEICSSection] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [empEditDisable, setEmpEditDisable] = useState(false);

  const [intakeClientInfo, setIntakeClientInfo] =
    useState<IntakeClientInfoType>(IntakeFormInitialValues);

  const [othersContacts, setOthersContacts] = useState<OptionType[]>([]);
  const [othersContactsRole, setOthersContactsRole] = useState<OptionType[]>(
    [],
  );
  const [otherContactDiscipline, setOtherContactDiscipline] = useState<
    OptionType[]
  >([]);

  const [intakeTeamTable, setIntakeTeamTable] = useState<IfspTeamMembersType[]>(
    [],
  );

  const [initialTeamMembers, setInitialTeamMembers] = useState<
    IfspTeamMembersType[]
  >([]);

  const [editRecordIndex, setEditRecordIndex] = useState<number | null>(null);

  const [employeeList, setEmployeeList] = useState<EmployeeListType[]>([
    {
      id: '',
      name: '',
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      disciplineType: '',
      roles: [],
    },
  ]);

  const [viewToBeLocked, setViewToBeLocked] = useState<boolean>(false);
  const parentRef = React.useRef<HTMLDivElement>(null);

  const { lookups } = useContext(LookUpContext) as LookUpContextType;

  const { toggleDownload } = React.useContext(
    DownloadContext,
  ) as DownloadContextType;

  const { handleUpdateUnsavedChanges } = useContext(UnSavedChangesContext);

  useEffect(() => {
    const handleIntakeGet = async () => {
      handleIntakeGetAPI();
    };
    handleIntakeGet();
  }, []);

  const handleIntakeGetAPI = async () => {
    window.scrollTo(0, 0);
    if (clientId) {
      toggleLoader(true);
      getIntakeDetailsByTab(clientId)
        .then((response: IntakeClientInfoResponseType) => {
          if (response.readyToDownload) {
            toggleDownload(response.readyToDownload);
          }

          if (response.isSigned) {
            setViewToBeLocked(true);
          } else {
            if (response.isCurrentlyHeld) {
              setViewToBeLocked(true);
              showCancelToaster(response.heldBy);
            } else {
              // unlock the page
              if (
                checkPermissionForFeature('backend.clients', 'editPermission')
              ) {
                setViewToBeLocked(false);
              } else {
                setViewToBeLocked(true);
              }
            }
          }

          setIntakeClientInfo({
            ...intakeClientInfo,
            demographics: [
              {
                id: 'ProgressNote.ClientName',
                labelid: 'ProgressNote.ClientName',
                defaultLabel: 'Client Name:',
                value: response.demographics.name,
              },
              {
                id: 'ProgressNote.DPHID',
                labelid: 'ProgressNote.DPHID',
                defaultLabel: 'DPH ID:',
                value: response.demographics.dphId,
              },
              {
                id: 'ProgressNote.DateofBirth',
                labelid: 'ProgressNote.DateofBirth',
                defaultLabel: 'Date of Birth:',
                value: response.demographics.dateOfBirth,
              },
              {
                id: 'age',
                labelid: 'age',
                defaultLabel: 'Age:',
                value: `${calculateMonths(
                  response.demographics.dateOfBirth,
                ).replace(/[()]/g, '')}`,
              },
              {
                id: 'ProgressNote.Sex',
                labelid: 'ProgressNote.Sex',
                defaultLabel: 'Sex:',
                value:
                  convertGenderToFrontend(response.demographics.sex) ?? '-',
              },
              {
                id: 'ConsentForms.clientAddress',
                labelid: 'ConsentForms.clientAddress',
                defaultLabel: 'Address:',
                value: response.demographics.address,
              },
            ],
            parent: {
              primary: [
                {
                  id: 'ProgressNote.Name',
                  labelid: 'ProgressNote.Name',
                  defaultLabel: 'Name:',
                  value:
                    response.parents.length > 0
                      ? response.parents[0].name
                      : '-',
                },
                {
                  id: 'Facesheet.relationship',
                  labelid: 'Facesheet.relationship',
                  defaultLabel: 'Guardian Type:',
                  value:
                    (response.parents.length > 0 &&
                      lookups?.guardianType?.find(
                        (g) =>
                          g.code.toUpperCase() ===
                          response.parents[0].relationshipToClient.toUpperCase(),
                      )?.description) ||
                    '-',
                },
                {
                  id: 'ConsentForms.contactPhone',
                  labelid: 'ConsentForms.contactPhone',
                  defaultLabel: 'Phone:',
                  value:
                    response.parents.length > 0
                      ? response.parents[0].phoneNumber
                      : '-',
                },
                {
                  id: 'ConsentForms.clientAddress',
                  labelid: 'ConsentForms.clientAddress',
                  defaultLabel: 'Address:',
                  value:
                    response.parents.length > 0
                      ? response.parents[0].address
                      : '-',
                },
              ],
              secondary: [
                {
                  id: 'ProgressNote.Name',
                  labelid: 'ProgressNote.Name',
                  defaultLabel: 'Name:',
                  value: response.parents[1] ? response.parents[1].name : '-',
                },
                {
                  id: 'Facesheet.relationship',
                  labelid: 'Facesheet.relationship',
                  defaultLabel: 'Relationship:',
                  value:
                    (response.parents[1] &&
                      lookups?.guardianType?.find(
                        (g) =>
                          g.code.toUpperCase() ===
                          response.parents[1].relationshipToClient.toUpperCase(),
                      )?.description) ||
                    '-',
                },
                {
                  id: 'ConsentForms.contactPhone',
                  labelid: 'ConsentForms.contactPhone',
                  defaultLabel: 'Phone:',
                  value: response.parents[1]
                    ? response.parents[1].phoneNumber
                    : '-',
                },
                {
                  id: 'ConsentForms.clientAddress',
                  labelid: 'ConsentForms.clientAddress',
                  defaultLabel: 'Address:',
                  value: response.parents[1]
                    ? response.parents[1].address
                    : '-',
                },
              ],
            },
            teamMembers: {
              others: response.teamMembers.others,
            },
            insurance: response.insurance,
            concernsDescription: response.descriptions.concernsDescription,
            prioritiesDescription: response.descriptions.prioritiesDescription,
            gainDescription: response.descriptions.gainDescription,
            primaryResidence: response.eics.primaryResidence,
            biologicalParents: response.biologicalParents,
            childBorn: response.eics.childBorn,
            childAdopted: response.eics.childAdopted,
            comment: response.comment ? response.comment : '',
          });
          setInitialTeamMembers(response.teamMembers.others);
          getEmployeesData();
          toggleLoader(false);
        })
        .catch((error) => {
          toggleLoader(false);
          setSuccessOrError('error');
          if (isCustomError(error)) {
            const apiError = error as ApiError;
            setToastrId(apiError.id);
            setToastrDefaultMessage(apiError.message);
          } else {
            setToastrId(error);
            setToastrDefaultMessage(error);
          }
        });
    }
  };

  const getEmployeesData = async () => {
    toggleLoader(true);
    await getAllEmployeeMinimalList()
      .then((data) => {
        setEmployeeList(data);
        toggleLoader(false);
      })
      .catch((err) => {
        toggleLoader(false);
        setSuccessOrError('error');
        setToastrId('IFSP.failedToGetEmployeeData');
        setToastrDefaultMessage(err);
      });
  };

  useEffect(() => {
    setOthersContacts(() => {
      return employeeList.map((data) => {
        return {
          label: data.name ?? '',
          id: data.id,
        };
      });
    });
  }, [employeeList]);

  const formikRef = useRef<FormikProps<IntakeformikType>>(null);

  useEffect(() => {
    const intakeTeamMembers: IfspTeamMembersType[] = [];

    intakeClientInfo.teamMembers.others.forEach((other) => {
      intakeTeamMembers.push(other);
    });

    setIntakeTeamTable(intakeTeamMembers);
  }, [intakeClientInfo.teamMembers.others]);

  const handleAddNewOtherContact = async (
    submittedValues: IntakeformikType,
  ) => {
    setEmpEditDisable(false);

    const newOtherContact = employeeList.find(
      (employee) => employee.id === submittedValues.yetToAddOtherContactId,
    );

    const isDuplicated = intakeTeamTable.map((otherContact) => {
      const isRecordDuplicated =
        otherContact.role === submittedValues.yetToAddRoleId &&
        otherContact.name === newOtherContact?.name;

      return isRecordDuplicated;
    });

    if (editRecordIndex !== null && !isDuplicated.includes(true)) {
      const updatedRecord = {
        id: newOtherContact?.id ?? intakeTeamTable[editRecordIndex].id,
        email: newOtherContact?.email ?? intakeTeamTable[editRecordIndex].email,
        name: newOtherContact?.name ?? intakeTeamTable[editRecordIndex].name,
        phone: newOtherContact?.phone ?? intakeTeamTable[editRecordIndex].phone,
        role:
          formikRef.current?.values.yetToAddRoleId ??
          intakeTeamTable[editRecordIndex].role,
        discipline:
          newOtherContact?.disciplineType ??
          intakeTeamTable[editRecordIndex].discipline,
      };
      setIntakeTeamTable((prev) => {
        const updatedTable = [...prev];
        updatedTable[editRecordIndex] = updatedRecord;

        if (formikRef.current) {
          setIntakeClientInfo({
            ...intakeClientInfo,
            teamMembers: {
              ...intakeClientInfo.teamMembers,
              others: updatedTable.filter(
                (item) => typeof item.id !== 'number',
              ),
            },
            yetToAddOtherContactId:
              formikRef.current.values.yetToAddOtherContactId,
            yetToAddRoleId: formikRef.current.values.yetToAddRoleId,
            yetToAdddisciplineId: formikRef.current.values.yetToAdddisciplineId,
            concernsDescription: formikRef.current.values.concernsDescription,
            prioritiesDescription:
              formikRef.current.values.prioritiesDescription,
            gainDescription: formikRef.current.values.gainDescription,
            primaryResidence: formikRef.current.values.primaryResidence,
            biologicalParents: formikRef.current.values.biologicalParents,
            childBorn: formikRef.current.values.childBorn,
            childAdopted: formikRef.current.values.childAdopted,
            comment: formikRef.current.values.comment,
          });
        }

        return updatedTable;
      });
    } else if (
      newOtherContact &&
      !isDuplicated.includes(true) &&
      formikRef.current
    ) {
      setIntakeClientInfo({
        ...intakeClientInfo,
        teamMembers: {
          ...intakeClientInfo.teamMembers,
          others: [
            ...intakeClientInfo.teamMembers.others,
            {
              id: newOtherContact.id || '',
              email: newOtherContact.email ?? '',
              name: newOtherContact.name ?? '',
              phone: newOtherContact.phone ?? '',
              role: formikRef.current?.values.yetToAddRoleId || '',
              discipline: newOtherContact.disciplineType || '',
            },
          ],
        },
        yetToAddOtherContactId: formikRef.current.values.yetToAddOtherContactId,
        yetToAddRoleId: formikRef.current.values.yetToAddRoleId,
        yetToAdddisciplineId: formikRef.current.values.yetToAdddisciplineId,
        concernsDescription: formikRef.current.values.concernsDescription,
        prioritiesDescription: formikRef.current.values.prioritiesDescription,
        gainDescription: formikRef.current.values.gainDescription,
        primaryResidence: formikRef.current.values.primaryResidence,
        biologicalParents: formikRef.current.values.biologicalParents,
        childBorn: formikRef.current.values.childBorn,
        childAdopted: formikRef.current.values.childAdopted,
        comment: formikRef.current.values.comment,
      });
    } else if (isDuplicated.includes(true)) {
      await formikRef.current?.setFieldValue('formHandleFlag', true);
      setShowAlertDialog(true);
    }
    setEditRecordIndex(null);
    setOthersContactsRole([]);
    setOtherContactDiscipline([]);
  };

  const handleEditOtherContact = async (editIndex: number) => {
    setEmpEditDisable(true);
    setEditRecordIndex(editIndex);

    const data = intakeTeamTable[editIndex];

    if (data) {
      await formikRef.current?.setFieldValue('yetToAddOtherContactId', data.id);

      setOthersContactsRole(() => {
        const filteredContactsRoles: OptionType[] = employeeList
          .filter((contact) => contact.id === data?.id)
          .flatMap((contact) =>
            contact.roles
              ? contact.roles.flatMap((roleString) =>
                  roleString.split(',').map((roleId) => ({
                    id: roleId.trim(),
                    label:
                      employeeRoles.find(
                        (empRole) => empRole.id === roleId.trim(),
                      )?.label || '',
                  })),
                )
              : [],
          );
        const val = filteredContactsRoles.find(
          (contact) => contact.id === data.role,
        );
        formikRef.current?.setFieldValue('yetToAddRoleId', val ? val.id : null);
        return filteredContactsRoles;
      });

      if (lookups === null) {
        return;
      }
      setOtherContactDiscipline(() => {
        const filteredContactsDisciplines: OptionType[] = employeeList
          .filter((contact) => contact.id === data?.id)
          .map((contact) => ({
            id:
              lookups?.discipline.find(
                (dis) => dis.code === contact.disciplineType,
              )?.code || '',
            label:
              lookups?.discipline?.find(
                (discipline) => discipline.code === contact.disciplineType,
              )?.description || '',
          }));

        // Tomorrow if Discipline type comes with multiple this logic is needed //
        // const val = filteredContactsDisciplines.find(
        //   (contact) => contact.id === data.role,
        // );
        formikRef.current?.setFieldValue(
          'yetToAdddisciplineId',
          filteredContactsDisciplines.length > 0
            ? filteredContactsDisciplines[0].id
            : null,
          // val ? val.id : null,
        );
        return filteredContactsDisciplines;
      });
    }
  };

  const handleDeleteOtherContact = (index: number) => {
    formikRef.current?.setFieldValue('yetToAddOtherContactId', null);
    formikRef.current?.setFieldError('yetToAddOtherContactId', '');
    formikRef.current?.setFieldValue('yetToAdddisciplineId', null);
    formikRef.current?.setFieldError('yetToAdddisciplineId', '');
    formikRef.current?.setFieldValue('yetToAddRoleId', null);
    formikRef.current?.setFieldError('yetToAddRoleId', '');
    setEmpEditDisable(false);
    setOthersContactsRole([]);
    setOtherContactDiscipline([]);
    setEditRecordIndex(null);
    setDeleteIndex(index);
    setDeleteWarning(true);
  };

  const handleSubmitForm = async () => {
    if (formikRef.current) {
      const params: IntakeClientInfoResponseType = {
        demographics: {
          name: intakeClientInfo.demographics[0].value,
          dphId: intakeClientInfo.demographics[1].value,
          dateOfBirth: intakeClientInfo.demographics[2].value,
          ageInMonths: intakeClientInfo.demographics[3].value,
          sex: intakeClientInfo.demographics[4].value,
          address: intakeClientInfo.demographics[5].value,
        },
        parents: [
          {
            id: intakeClientInfo.parent.primary[0].id,
            name: intakeClientInfo.parent.primary[0].value,
            relationshipToClient: intakeClientInfo.parent.primary[1].value,
            phoneNumber: intakeClientInfo.parent.primary[2].value,
            address: intakeClientInfo.parent.primary[3].value,
          },
          {
            id: intakeClientInfo.parent.secondary[0].id,
            name: intakeClientInfo.parent.secondary[0].value,
            relationshipToClient: intakeClientInfo.parent.secondary[1].value,
            phoneNumber: intakeClientInfo.parent.secondary[2].value,
            address: intakeClientInfo.parent.secondary[3].value,
          },
        ],
        insurance: intakeClientInfo.insurance,
        teamMembers: {
          others: intakeClientInfo.teamMembers.others,
        },
        biologicalParents: intakeClientInfo.biologicalParents,
        eics: {
          primaryResidence: formikRef.current.values.primaryResidence,
          childBorn: formikRef.current.values.childBorn,
          childAdopted: formikRef.current.values.childAdopted,
        },
        descriptions: {
          concernsDescription: formikRef.current.values.concernsDescription,
          prioritiesDescription: formikRef.current.values.prioritiesDescription,
          gainDescription: formikRef.current.values.gainDescription,
        },
        comment: formikRef.current.values.comment,
      };

      toggleLoader(true);
      if (clientId) {
        putIntakeClientInfo(clientId, params)
          .then(async (response) => {
            if (response) {
              setSuccessOrError('success');
              setToastrId('Intake.successMsg');
              setToastrDefaultMessage('Intake updated successfully');
              await formikRef.current?.setFieldValue('formHandleFlag', false);
              await formikRef.current?.setFieldValue(
                'yetToAddOtherContactId',
                '',
              );
              await formikRef.current?.setFieldValue(
                'yetToAdddisciplineId',
                '',
              );
              await formikRef.current?.setFieldValue('yetToAddRoleId', '');
              setEmpEditDisable(false);

              setTimeout(() => {
                handleUpdateUnsavedChanges(false);
                toggleLoader(false);
                setTabProp((value) => {
                  sessionStorage.setItem(
                    'intakeTabName',
                    String(value.tabPosition),
                  );
                  return {
                    tabPosition: value.tabPosition,
                  };
                });
              }, 1000);

              handleIntakeGetAPI();
            }
          })
          .catch((error) => {
            toggleLoader(false);
            setSuccessOrError('error');
            if (isCustomError(error)) {
              const apiError = error as ApiError;
              setToastrId(apiError.id);
              setToastrDefaultMessage(apiError.message);
            } else {
              setToastrId(error);
              setToastrDefaultMessage(error);
            }
          });
      }
    }
  };

  const getCompanyName = (data: InsuranceType) => {
    if (data.dph3rdPartyPayerName && data.dph3rdPartyPayer === 'OTHER') {
      return `${data.dph3rdPartyPayerName} - ${data.insuranceCompany}`;
    } else if (data.dph3rdPartyPayer && data.dph3rdPartyPayerName) {
      return `${data.dph3rdPartyPayer} - ${data.dph3rdPartyPayerName}`;
    } else {
      return '-';
    }
  };

  return (
    <>
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={toastrDefaultMessage}
          successOrError={successOrError}
        />
      )}
      <Box
        ref={parentRef}
        style={{
          pointerEvents: viewToBeLocked ? 'none' : 'auto',
          opacity: viewToBeLocked ? '0.8' : 'unset',
        }}
        onKeyDownCapture={(e) => {
          if (viewToBeLocked) {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
        onFocus={() => {
          if (parentRef.current) parentRef.current.focus();
        }}
      >
        <Card className="formCardview gray3 borderRadius4 mt-md mb-md p-sm">
          <Box component="div">
            <ModalPopup
              open={showAlertDialog}
              description="duplicateIntakeTeamWarningMessage"
              onOk={() => {
                setShowAlertDialog(false);
              }}
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="Ok"
            />
            <ModalPopup
              open={deleteWaring}
              description="deleteConfirmationMessage"
              onOk={async () => {
                const updatedTeamMembers = intakeTeamTable.filter(
                  (_, i) => i !== deleteIndex,
                );
                if (formikRef.current) {
                  setIntakeClientInfo({
                    ...intakeClientInfo,
                    teamMembers: {
                      ...intakeClientInfo.teamMembers,
                      others: updatedTeamMembers.filter(
                        (item) => typeof item.id !== 'number',
                      ),
                    },
                    yetToAddOtherContactId:
                      formikRef.current.values.yetToAddOtherContactId,
                    yetToAddRoleId: formikRef.current.values.yetToAddRoleId,
                    yetToAdddisciplineId:
                      formikRef.current.values.yetToAdddisciplineId,
                    concernsDescription:
                      formikRef.current.values.concernsDescription,
                    prioritiesDescription:
                      formikRef.current.values.prioritiesDescription,
                    gainDescription: formikRef.current.values.gainDescription,
                    primaryResidence: formikRef.current.values.primaryResidence,
                    biologicalParents:
                      formikRef.current.values.biologicalParents,
                    childBorn: formikRef.current.values.childBorn,
                    childAdopted: formikRef.current.values.childAdopted,
                    comment: formikRef.current.values.comment,
                  });

                  setDeleteWarning(false);
                }
              }}
              onCancel={() => {
                setDeleteWarning(false);
              }}
              labelId1="Clientpage.cancelbtn"
              negativeActionLabel="cancelText"
              labelId2="Clientpage.Okbtn"
              positiveActionLabel="Ok"
            />
            <Formik
              initialValues={intakeClientInfo}
              innerRef={formikRef}
              validateOnMount={true}
              validateOnChange={true}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={async (values, { setFieldValue, setFieldError }) => {
                await handleAddNewOtherContact(values);
                setFieldValue('yetToAddOtherContactId', '');
                setFieldError('yetToAddOtherContactId', '');
                setFieldValue('yetToAdddisciplineId', '');
                setFieldError('yetToAdddisciplineId', '');
                setFieldValue('yetToAddRoleId', '');
                setFieldError('yetToAddRoleId', '');
              }}
            >
              {({ values, setFieldValue, errors, touched, handleSubmit }) => {
                useEffect(() => {
                  if (
                    JSON.stringify(intakeClientInfo) !==
                    JSON.stringify(formikRef.current?.values)
                  ) {
                    handleUpdateUnsavedChanges(true);
                  } else {
                    handleUpdateUnsavedChanges(false);
                  }
                }, [values]);
                return (
                  <Box component="div">
                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Demographic.Titletext"
                          defaultlabel="Demographic"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />
                        {!openDemographicSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenDemographicSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenDemographicSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openDemographicSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />
                          <Box
                            component="div"
                            className="flex__ align__items__center"
                          >
                            <KeyValueComponent
                              data={intakeClientInfo.demographics}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4 mt-md"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Intake.parentTitleText"
                          defaultlabel="Guardian"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />

                        {!openParentSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenParentSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenParentSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openParentSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />
                          <Box
                            component="div"
                            className="flex__ align__items__center"
                          >
                            <KeyValueComponent
                              data={intakeClientInfo.parent.primary}
                            />
                          </Box>
                          <Box
                            component="div"
                            className="flex__ align__items__center"
                          >
                            <KeyValueComponent
                              data={intakeClientInfo.parent.secondary}
                            />
                          </Box>
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />
                          <Box component="div" className="align__items__center">
                            <MediumTypography
                              labelid="IntakeClientInfo.biologicalParents"
                              defaultlabel="Are the intake questions answered by the biological parents ?"
                            />
                            <CustomRadioButton
                              data={IntakeRadioData}
                              value={values.biologicalParents}
                              onSelectItem={(value) => {
                                if (value) {
                                  setFieldValue('biologicalParents', value);
                                }
                              }}
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4 mt-md"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Facesheet.insurance"
                          defaultlabel="Insurance"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />

                        {!openInsuranceSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenInsuranceSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenInsuranceSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openInsuranceSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />
                          <TableContainer sx={{ maxHeight: 310 }}>
                            <Table stickyHeader aria-label="sticky table">
                              <TableHeader
                                className="listDataTableHead"
                                headerNames={headCells}
                                checkBoxRequired={false}
                              />

                              <TableBody className="tableRowcss">
                                {intakeClientInfo.insurance.map(
                                  (row: InsuranceType, index: number) => (
                                    <TableRow
                                      hover
                                      tabIndex={-1}
                                      key={row.coverageType2}
                                      sx={{
                                        cursor: 'pointer',
                                        backgroundColor:
                                          index % 2 === 0
                                            ? '#ECF9F8'
                                            : '#ffffff',
                                      }}
                                    >
                                      {/* <TableCell> {index + 1}</TableCell> */}
                                      <TooltipTableCell
                                        value={getCompanyName(row)}
                                        visible={true}
                                      />
                                      <TooltipTableCell
                                        value={
                                          row.coverageType2
                                            ? row.coverageType2
                                            : '-'
                                        }
                                        visible={true}
                                      />
                                      <TooltipTableCell
                                        value={row.subscriberName}
                                        visible={true}
                                      />

                                      {/* <TooltipTableCell
                                        value={
                                          row.insurancePolicyId
                                            ? row.insurancePolicyId
                                            : '-'
                                        }
                                        visible={true}
                                      />
                                      <TooltipTableCell
                                        value={
                                          row.insuranceGroup
                                            ? row.insuranceGroup
                                            : '-'
                                        }
                                        visible={true}
                                      /> */}
                                      <TableCell>
                                        {row.effectiveStartDate
                                          ? ` ${row.effectiveStartDate} `
                                          : '-'}
                                      </TableCell>
                                      <TableCell>
                                        {row.effectiveEndDate
                                          ? ` ${row.effectiveEndDate} `
                                          : '-'}
                                      </TableCell>

                                      <TableCell
                                        sx={{
                                          fontFamily: 'Lato-Regular',
                                        }}
                                      >
                                        {badgePicker('Not Verified')}
                                        Not verified
                                      </TableCell>
                                    </TableRow>
                                  ),
                                )}
                              </TableBody>
                            </Table>
                            {intakeClientInfo.insurance.length === 0 && (
                              <Box
                                component="div"
                                className="width__100 flex__align__center"
                              >
                                <MediumTypography
                                  sxProps={{ backgroundColor: '#ECF9F8' }}
                                  textstyle={'center'}
                                  labelid="Insurance.nodataValidation"
                                  defaultlabel="No Insurance added yet"
                                  className="p-sm"
                                />
                              </Box>
                            )}
                          </TableContainer>
                        </Box>
                      )}
                    </Box>

                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4 mt-md"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Intake.teamMembersTitle"
                          defaultlabel="Team members"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />

                        {!openTeamSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenTeamSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenTeamSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openTeamSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />
                          <Box component="div" className="mt-md mb-md">
                            <Grid container columnSpacing={'24px'}>
                              <Grid item xs={3}>
                                <DropdownComponent
                                  names={othersContacts}
                                  labelid="IFSP.selectName"
                                  defaultlabelid="Select Name *"
                                  value={values.yetToAddOtherContactId}
                                  handleChange={(e) => {
                                    setOthersContacts(() => {
                                      return employeeList.map((data) => {
                                        return {
                                          label: data.name ?? '',
                                          id: data.id,
                                        };
                                      });
                                    });
                                    setOthersContactsRole(() => {
                                      const filteredContactsRoles: OptionType[] =
                                        employeeList
                                          .filter((contact) => contact.id === e)
                                          .flatMap((contact) =>
                                            contact.roles
                                              ? contact.roles.flatMap(
                                                  (roleString) =>
                                                    roleString
                                                      .split(',')
                                                      .map((roleId) => ({
                                                        id: roleId.trim(),
                                                        label:
                                                          employeeRoles.find(
                                                            (empRole) =>
                                                              empRole.id ===
                                                              roleId.trim(),
                                                          )?.label || '',
                                                      })),
                                                )
                                              : [],
                                          );
                                      return filteredContactsRoles;
                                    });
                                    setOtherContactDiscipline(() => {
                                      const filteredContactsDisciplines =
                                        employeeList
                                          .filter((contact) => contact.id === e)
                                          .map((contact) => ({
                                            id:
                                              lookups?.discipline.find(
                                                (dis) =>
                                                  dis.code ===
                                                  contact.disciplineType,
                                              )?.code || '',
                                            label:
                                              lookups?.discipline.find(
                                                (dis) =>
                                                  dis.code ===
                                                  contact.disciplineType,
                                              )?.description || '',
                                          }));
                                      return filteredContactsDisciplines;
                                    });

                                    setFieldValue('yetToAddOtherContactId', e);
                                  }}
                                  disabled={empEditDisable}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.yetToAddOtherContactId &&
                                  touched.yetToAddOtherContactId && (
                                    <MediumTypography
                                      className="errorText-md"
                                      labelid={errors.yetToAddOtherContactId}
                                      defaultlabel="Contact is required"
                                    />
                                  )}
                              </Grid>

                              <Grid item xs={3} className="mb-md">
                                <DropdownComponent
                                  names={otherContactDiscipline}
                                  labelid="Ifsp.discipline"
                                  defaultlabelid="Select Discipline"
                                  value={values.yetToAdddisciplineId}
                                  handleChange={(e) => {
                                    setFieldValue('yetToAdddisciplineId', e);
                                  }}
                                  Required
                                  disabled={otherContactDiscipline.length === 0}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.yetToAdddisciplineId &&
                                  touched.yetToAdddisciplineId && (
                                    <MediumTypography
                                      className="errorText-md"
                                      labelid={errors.yetToAdddisciplineId}
                                      defaultlabel="Discipline is required"
                                    />
                                  )}
                              </Grid>
                              <Grid item xs={3} className="mb-md">
                                <DropdownComponent
                                  names={othersContactsRole}
                                  labelid="Ifsp.selectRole"
                                  defaultlabelid="Select Role"
                                  value={values.yetToAddRoleId}
                                  handleChange={(e) => {
                                    setFieldValue('yetToAddRoleId', e);
                                  }}
                                  Required
                                  disabled={othersContactsRole.length === 0}
                                  placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                                />
                                {errors.yetToAddRoleId &&
                                  touched.yetToAddRoleId && (
                                    <MediumTypography
                                      className="errorText-md"
                                      labelid={errors.yetToAddRoleId}
                                      defaultlabel="Role is required"
                                    />
                                  )}
                              </Grid>
                              <Grid
                                container
                                xs={1}
                                className="cursorPointer "
                                onClick={() => handleSubmit()}
                              >
                                <Grid item>
                                  <Box
                                    component="div"
                                    className="flex__ pt-xs "
                                  >
                                    <AddNew className="ml-sm mr-sm" />
                                    <MediumTypography
                                      labelid="IFSP.Add"
                                      defaultlabel="Add"
                                    />
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box component="section">
                            <TableContainer
                              component={Paper}
                              className="mt-md tableStyles"
                            >
                              <Table stickyHeader aria-label="sticky table">
                                <TableHeader
                                  className="listDataTableHead"
                                  headerNames={teamHeadCells}
                                  checkBoxRequired={false}
                                />
                                <TableBody className="tableRowcss">
                                  {intakeTeamTable.map((listData, index) => (
                                    <IfspTeamMembersTableRow
                                      key={index}
                                      data={listData}
                                      dateRequired={true}
                                      onDelete={() =>
                                        handleDeleteOtherContact(index)
                                      }
                                      onEdit={() =>
                                        handleEditOtherContact(index)
                                      }
                                    />
                                  ))}
                                </TableBody>
                              </Table>
                              {intakeTeamTable.length === 0 && (
                                <Box
                                  component="div"
                                  className="width__100 flex__align__center"
                                >
                                  <MediumTypography
                                    sxProps={{ backgroundColor: '#ECF9F8' }}
                                    textstyle={'center'}
                                    labelid="Intake.teamMembersemptyMessage"
                                    defaultlabel="No Team members added yet"
                                    className="p-sm"
                                  />
                                </Box>
                              )}
                            </TableContainer>
                          </Box>
                        </Box>
                      )}
                    </Box>

                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4 mt-md"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Intake.parentsConcernsTitle"
                          defaultlabel="Parent's concerns"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />

                        {!openParentConcernsSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenParentConcernsSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenParentConcernsSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openParentConcernsSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />
                          <Grid
                            container
                            direction={'row'}
                            columnSpacing={'40px'}
                            className="mt-md mb-lg"
                          >
                            <Grid item xs={9} lg={9}>
                              <CommentTextbox
                                Value={values.concernsDescription}
                                placeholder="Please describe your concerns or questions you have for your child? Your family?"
                                name="concernsDescription"
                                maxLength={3000}
                                handlechange={(e) => {
                                  setFieldValue('concernsDescription', e);
                                }}
                                height="150px"
                                placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                            </Grid>
                            <Grid item xs={9} lg={9}>
                              <CommentTextbox
                                Value={values.prioritiesDescription}
                                placeholder="Please describe your priorities for your child? Your family?"
                                name="prioritiesDescription"
                                maxLength={3000}
                                handlechange={(e) => {
                                  setFieldValue('prioritiesDescription', e);
                                }}
                                height="150px"
                                placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                            </Grid>
                            <Grid item xs={9} lg={9}>
                              <CommentTextbox
                                Value={values.gainDescription}
                                placeholder="What do you hope to gain from working with Early Intervention?"
                                name="gainDescription"
                                maxLength={3000}
                                handlechange={(e) => {
                                  setFieldValue('gainDescription', e);
                                }}
                                height="150px"
                                placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            direction={'row'}
                            columnSpacing={'40px'}
                            className="mt-md mb-lg"
                          >
                            <Grid item xs={4.5} lg={4.5}>
                              <DropdownComponent
                                names={primaryResidenceValues}
                                labelid={'Intake.primaryResidencePlaceholder'}
                                value={values.primaryResidence}
                                defaultlabelid="Child's primary residence"
                                handleChange={(e) => {
                                  setFieldValue('primaryResidence', e);
                                }}
                                placeholderOnfloatBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                              {errors.primaryResidence &&
                                touched.primaryResidence && (
                                  <MediumTypography
                                    labelid={errors.primaryResidence}
                                    className="errorText-md"
                                  />
                                )}
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>

                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4 mt-md"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Intake.additionalCommentsTitle"
                          defaultlabel="Additional Comments"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />

                        {!openAdditionalSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenAdditionalSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenAdditionalSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openAdditionalSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />

                          <Grid
                            container
                            direction={'row'}
                            columnSpacing={'40px'}
                            className="mt-md pl-xs"
                          >
                            <Grid item xs={9} lg={9}>
                              <CommentTextbox
                                Value={values.comment}
                                placeholder="Comments"
                                name="comment"
                                maxLength={3000}
                                handlechange={(e) => {
                                  setFieldValue('comment', e);
                                }}
                                height="150px"
                                placeholderBackgroundColor="var(--thom-mono-gray3-bg)"
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>

                    <Box
                      component="div"
                      className="p-sm gray3 borderRadius4 mt-md"
                      sx={{
                        border: '1px solid #00C6B8',
                      }}
                    >
                      <Box
                        component="div"
                        className="flex__justify__space-between"
                      >
                        <MediumTypography
                          labelid="Intake.eicsTitle"
                          defaultlabel="EICS Additional Data"
                          fontSize="16px"
                          fontweight={600}
                          textColor="#2A4241"
                        />

                        {!openEICSSection ? (
                          <DownArrow
                            className="cursorPointer"
                            style={{ color: '#008C82' }}
                            onClick={() => {
                              setOpenEICSSection(true);
                            }}
                          />
                        ) : (
                          <UpArrow
                            className="cursorPointer"
                            onClick={() => {
                              setOpenEICSSection(false);
                            }}
                          />
                        )}
                      </Box>

                      {openEICSSection && (
                        <Box component="div">
                          <Box
                            className="horizontalLine"
                            sx={{ marginY: '8px' }}
                          />

                          <Grid
                            container
                            direction={'row'}
                            columnSpacing={'40px'}
                            className="mt-md pl-xs"
                          >
                            <Grid item xs={3} lg={3}>
                              <MediumTypography
                                labelid="IntakeClientInfo.ChildBornMA"
                                defaultlabel="Was this child born in MA ?"
                              />
                              <CustomRadioButton
                                data={EICSRadioData}
                                value={values.childBorn}
                                onSelectItem={(value) => {
                                  if (value) {
                                    setFieldValue('childBorn', value);
                                  }
                                }}
                                disable
                              />
                              {errors.childBorn && touched.childBorn && (
                                <MediumTypography
                                  labelid={errors.childBorn}
                                  className="errorText-md"
                                />
                              )}
                            </Grid>
                            <Grid item xs={3} lg={3}>
                              <MediumTypography
                                labelid="IntakeClientInfo.ChildAdopted"
                                defaultlabel="Is this child adopted ?"
                              />
                              <CustomRadioButton
                                data={EICSRadioData}
                                value={values.childAdopted}
                                onSelectItem={(value) => {
                                  if (value) {
                                    setFieldValue('childAdopted', value);
                                  }
                                }}
                                disable
                              />
                              {errors.childAdopted && touched.childAdopted && (
                                <MediumTypography
                                  labelid={errors.childAdopted}
                                  className="errorText-md"
                                />
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              }}
            </Formik>
            <ModalPopup
              description="formUnsavedChangesMessage"
              open={openModal}
              onCancel={() => {
                setOpenModal(false);
              }}
              onOk={() => {
                formikRef.current?.resetForm();
                setIntakeClientInfo({
                  ...IntakeFormInitialValues,
                  demographics: intakeClientInfo.demographics,
                  parent: intakeClientInfo.parent,
                  insurance: intakeClientInfo.insurance,
                  teamMembers: { others: initialTeamMembers },
                });
                setOpenModal(false);
              }}
              labelId1="Clientpage.Nobtn"
              negativeActionLabel="No"
              labelId2="Clientpage.Yesbtn"
              positiveActionLabel="Yes"
            />
          </Box>
        </Card>
        <Box
          className="mt-md mb-xlg"
          display={'flex'}
          justifyContent={'flex-end'}
        >
          <ButtonComponent
            className="btn-primary btn-cancel alrt_Width_btn mr-md"
            variantType="contained"
            labelId={'cancelText'}
            defaultLabelId={'Cancel'}
            onClick={() => {
              if (
                JSON.stringify(intakeClientInfo) !==
                JSON.stringify(formikRef.current?.values)
              ) {
                setOpenModal(true);
              }
            }}
          />

          <ButtonComponent
            variantType="contained"
            type="submit"
            className="btn-primary btn-submit alrt_Width_btn"
            labelId={'Intake.submit'}
            defaultLabelId={'Save'}
            onClick={async () => {
              if (intakeTeamTable.length === 0) {
                formikRef.current?.setFieldValue('yetToAddOtherContactId', '');
                formikRef.current?.setFieldError('yetToAddOtherContactId', '');
                formikRef.current?.setFieldValue('yetToAdddisciplineId', '');
                formikRef.current?.setFieldError('yetToAdddisciplineId', '');
                formikRef.current?.setFieldValue('yetToAddRoleId', '');
                formikRef.current?.setFieldError('yetToAddRoleId', '');
                await formikRef.current?.setFieldValue('formHandleFlag', false);

                await formikRef.current?.submitForm();
                await formikRef.current?.validateForm();

                if (
                  !formikRef.current?.isSubmitting &&
                  formikRef.current &&
                  formikRef.current.isValid
                ) {
                  handleSubmitForm();
                }
              } else {
                formikRef.current?.setFieldValue(
                  'yetToAddOtherContactId',
                  null,
                );
                formikRef.current?.setFieldError('yetToAddOtherContactId', '');
                formikRef.current?.setFieldValue('yetToAdddisciplineId', null);
                formikRef.current?.setFieldError('yetToAdddisciplineId', '');
                formikRef.current?.setFieldValue('yetToAddRoleId', null);
                formikRef.current?.setFieldError('yetToAddRoleId', '');
                await formikRef.current?.setFieldValue('formHandleFlag', true);

                if (!formikRef.current?.isSubmitting) {
                  handleSubmitForm();
                }
              }
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default IntakeClientsInfo;
