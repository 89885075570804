import {
  Box,
  Grid,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Fab,
  AlertColor,
  // Typography,
  styled,
  Button,
  Theme,
  Menu,
  MenuProps,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
// import { ReactComponent as GraphicBottomRight } from '../../assets/images/Graphic-BottomRight.svg';
// import { ReactComponent as GraphicBottomLeft } from '../../assets/images/Graphic-righttop.svg';
import TitleText from '../../components/formlib/TitleText';
// import { weekList } from '../Dropdown';
import SearchBox from '../../components/formlib/SearchBox';
import ButtonComponent from '../../components/formlib/ButtonComponent';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import TooltipTableCell from '../../components/formlib/TooltipTableCell';
import { ReactComponent as ViewEye } from '../../assets/images/ViewEye.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/DeleteIcon.svg';
import TaskManagerAddModal from '../../components/formlib/modal/TaskManagerAddModal';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ReactComponent as Filtericon } from '../../assets/images/FilterIconsvg.svg';
import './TaskManagerList.css';
// import DropdownComponent from '../../components/formlib/DropdownComponent';
import { encodeURI } from 'js-base64';
import { Client, Order, TaskManagerFilterType } from '../../utils/type';
import {
  TaskListResponse,
  TaskResponse,
  deleteTaskId,
  getTaskListAPI,
} from '../../services/configApi/taskManager/taskManagerServices';
import {
  ApiError,
  ApiMessage,
  isCustomError,
} from '../../services/ApiResponseHandler';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import MediumTypography from '../../components/formlib/MediumTypography';
import { FormattedMessage } from 'react-intl';
import dayjs, { Dayjs } from 'dayjs';
import AppPagination from '../../components/shared/AppPagination';
import CheckBoxComponent from '../../components/formlib/CheckBoxComponent';
import { checkPermissionForFeature } from '../../utils/checkPermission';
import { CLIENT_NAME_KEY, REFERRAL_ID_KEY } from '../../services/Constant';
import ModalPopup from '../../components/formlib/ModalPopup';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';
import { right } from '@popperjs/core';
import DatePickerComponent from '../../components/formlib/DatePickerComponent';
import DisplayFilter, { FilterBy } from '../../components/shared/DisplayFilter';
import MultiSelectAutocompleteSearch, {
  MultiSelectOptioinType,
} from '../../components/formlib/MultiSelectAutocompleteSearch';
import { getAllEmployeeMinimalList } from '../../services/ifspClient/IfspApi';
import { Employee } from '../../services/configApi/employees/employeeServices';
import { getAllClients } from '../../services/configApi/Clients';
import { formatName } from '../../utils/nameUtils';
import { OptionType } from '../../utils/type';
import EmptyScreen from '../../components/shared/EmptyScreen';
// import { FACESHEET } from '../../routes/Routing';
// import { useNavigate } from 'react-router';

interface Data {
  taskName: string;
  origin: string;
  dateAdded: string;
  dueDate: string;
  status: string;
  actions: string;
  clientName: string;
}

interface SortData {
  order: string;
  orderBy: string;
}

const headCells: HeadCell<Data>[] = [
  {
    id: 'taskName',
    labelId: 'taskNameText',
    defaultLabelId: 'Task Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'origin',
    labelId: 'originText',
    defaultLabelId: 'Origin',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'clientName',
    labelId: 'clientName',
    defaultLabelId: 'Client Name',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'dateAdded',
    labelId: 'dateAddedText',
    defaultLabelId: 'Date Added',
    numeric: false,
    requiredSorting: true,
  },

  {
    id: 'dueDate',
    labelId: 'dueDateText',
    defaultLabelId: 'Due Date',
    numeric: false,
    requiredSorting: true,
  },

  {
    id: 'status',
    labelId: 'ssp.status',
    defaultLabelId: 'Status',
    numeric: false,
    requiredSorting: true,
  },
  {
    id: 'actions',
    labelId: 'actions',
    defaultLabelId: 'Actions',
    numeric: false,
    requiredSorting: false,
  },
];

const TaskManagerList = () => {
  const allStatus: string =
    'backend.task_status.open,backend.task_status.due,backend.task_status.warning,backend.task_status.overdue,backend.task_status.completed';
  const allStatusExceptCompleted: string =
    'backend.task_status.open,backend.task_status.due,backend.task_status.warning,backend.task_status.overdue';
  const [page, setPage] = React.useState(0);
  const [totalCount, setTotalCount] = React.useState(0);
  const [checkbox, setCheckbox] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedValue] = React.useState('All');
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openit = Boolean(anchorEl);
  const [sortData, setSortData] = React.useState<SortData>({
    order: 'asc',
    orderBy: 'due_date',
  });
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Data>('dueDate');
  const [backgroundColorForAll, setBackgroundColorForAll] = useState(
    'var(--thom-green-primary2)',
  );
  const [textColorForAll, setTextColorForAll] = useState('var(--white)');
  const [backgroundColorForOpen, setBackgroundColorForOpen] =
    useState('#EDF6FF');
  const [textColorForOpen, setTextColorForOpen] = useState('#2a4241');
  const [backgroundColorForDue, setBackgroundColorForDue] = useState('#ECFFFE');
  const [textColorForDue, setTextColorForDue] = useState('#2a4241');
  const [backgroundColorForWarning, setBackgroundColorForWarning] =
    useState('#ECFFFE');
  const [textColorForWarning, setTextColorForWarning] = useState('#2a4241');
  const [backgroundColorForOverdue, setBackgroundColorForOverdue] =
    useState('#ECFFFE');
  const [textColorForOverdue, setTextColorForOverdue] = useState('#2a4241');
  const [backgroundColorForCompleted, setBackgroundColorForCompleted] =
    useState('#ECFFFE');
  const [textColorForCompleted, setTextColorForCompleted] = useState('#2a4241');
  const [openTaskManagerModal, setOpenTaskManagerModal] = React.useState(false);
  const [tasks, setTask] = React.useState<TaskListResponse>();
  const [toastrId, setToastrId] = useState<string>();
  const [successOrError, setSuccessOrError] = useState<AlertColor>('success');
  const [defaultlabel, setToastrDefaultMessage] = useState<string>();
  const [taskResponse, setTaskResponse] = useState<TaskResponse | null>(null);
  const { toggleLoader } = React.useContext(LoaderContext) as LoaderContextType;
  const [selectedStatus, setSelectedStatus] = useState<string>(
    allStatusExceptCompleted,
  );
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [filterPlainData, setFilterPlainData] =
    React.useState<TaskManagerFilterType>({
      dateAddedFrom: null,
      dateAddedTo: null,
      dueDateFrom: null,
      dueDateTo: null,
      client: [],
      origin: [],
    });
  const formRef = React.useRef<FormikProps<TaskManagerFilterType>>(null);
  const CustomMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }: { theme: Theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 100,
      maxWidth: 490,
      padding: '20px 25px',
      boxShadow: '10px 10px 50px 0px rgba(0, 71, 66, 0.3)',
    },
  }));
  const [showAlertDialog, setShowAlertDialog] = React.useState<string>();
  const [filterBy, setFilterBy] = React.useState<FilterBy[]>([]);
  const [filterData, setFilterData] = React.useState('');
  const MemorizedCustomMenu = useMemo(() => CustomMenu, []);
  const handlefilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlefilterClose = () => {
    setAnchorEl(null);
  };
  const applyFilter = (values: TaskManagerFilterType) => {
    const filterDataBy: FilterBy[] = [];
    setFilterPlainData(values);
    if (
      values.dateAddedTo != null &&
      values.dateAddedTo !== '' &&
      values.dateAddedFrom != null &&
      values.dateAddedFrom !== ''
    ) {
      filterDataBy.push({
        key: 'TaskManager.dateaddedfilter',
        value: values.dateAddedFrom + ' - ' + values.dateAddedTo,
      });
    }
    if (
      values.dueDateTo != null &&
      values.dueDateTo !== '' &&
      values.dueDateFrom != null &&
      values.dueDateFrom !== ''
    ) {
      filterDataBy.push({
        key: 'TaskManager.duedatefilter',
        value: values.dueDateFrom + ' - ' + values.dueDateTo,
      });
    }
    if (values.client.length > 0) {
      const clientsFilter: OptionType[] = values.client.map((client) => {
        const specificClient: OptionType = {
          id: client.id.toString(),
          label: formatName(
            client.firstName,
            client.middleName,
            client.lastName,
            client.suffix,
          ),
        };
        return specificClient;
      });
      filterDataBy.push({
        key: 'TaskManager.clientfilter',
        value: clientsFilter,
      });
    }
    if (values.origin.length > 0) {
      filterDataBy.push({
        key: 'TaskManager.originfilter',
        value: values.origin
          .map((origin) => origin.firstName + ' ' + origin.lastName)
          .join(', '),
      });
    }

    setFilterBy(filterDataBy);
    //Once everything is done
    if (filterDataBy.length <= 2) {
      if (filterDataBy.some((item) => item.value === 'false')) {
        clearForm();
        setFilterBy([]);
        configureFilterAndCallAPI(null);
      } else {
        configureFilterAndCallAPI(values);
      }
    } else {
      configureFilterAndCallAPI(values);
    }
  };

  const configureFilterAndCallAPI = (values: TaskManagerFilterType | null) => {
    let filterString: string = '';
    if (values?.dateAddedTo && values?.dateAddedFrom) {
      filterString = filterString
        .concat('filterByCreatedOn>=')
        .concat(values.dateAddedFrom.toString())
        .concat('&')
        .concat('filterByCreatedOn<=')
        .concat(values.dateAddedTo.toString());
    }
    if (values?.dueDateFrom && values?.dueDateTo) {
      if (filterString !== '') {
        filterString = filterString.concat('&');
      }
      filterString = filterString
        .concat('filterByPeriod>=')
        .concat(values.dueDateFrom.toString())
        .concat('&')
        .concat('filterByPeriod<=')
        .concat(values.dueDateTo.toString());
    }
    if (values?.client) {
      if (filterString !== '') {
        filterString = filterString.concat('&');
      }
      const clientFltr = values.client
        .map((client) => client.clientId)
        .join(',');
      filterString = filterString
        .concat('filterByClientIds=')
        .concat(clientFltr);
    }
    if (values?.origin) {
      if (filterString !== '') {
        filterString = filterString.concat('&');
      }
      const originFltr = values.origin.map((origin) => origin.id).join(',');
      filterString = filterString.concat('filterByOrigins=').concat(originFltr);
    }

    const encodedFltr = encodeURI(filterString);
    setFilterData(encodedFltr);
  };
  const clearForm = () => {
    setPage(0);
    formRef.current?.resetForm();
    setFilterPlainData({
      dateAddedFrom: null,
      dateAddedTo: null,
      dueDateFrom: null,
      dueDateTo: null,
      client: [],
      origin: [],
    });
    setFilterBy([]);
    setFilterData('');
    setAnchorEl(null);
  };
  const validationSchema = yup.object().shape({
    dateAddedFrom: yup
      .string()
      .nullable()
      .test(
        'required-if-date-addedTo',
        'TaskManager.dateaddedfrom',
        function (value) {
          const { dateAddedTo } = this.parent;
          if (dateAddedTo && !value) {
            return false;
          }
          return true;
        },
      ),
    dateAddedTo: yup
      .string()
      .nullable()
      .test(
        'required-if-date-addedFrom',
        'TaskManager.dateaddedto',
        function (value) {
          const { dateAddedFrom } = this.parent;
          if (dateAddedFrom && !value) {
            return false;
          }
          return true;
        },
      ),
    dueDateFrom: yup
      .string()
      .nullable()
      .test(
        'required-if-duedate-to',
        'TaskManager.duedatefrom',
        function (value) {
          const { dueDateTo } = this.parent;
          if (dueDateTo && !value) {
            return false;
          }
          return true;
        },
      ),
    dueDateTo: yup
      .string()
      .nullable()
      .test(
        'required-if-duedate-from',
        'TaskManager.duedateto',
        function (value) {
          const { dueDateFrom } = this.parent;
          if (dueDateFrom && !value) {
            return false;
          }
          return true;
        },
      ),
    client: yup.array().optional(),
    origin: yup.array().optional(),
  });
  const validateForm = (value: TaskManagerFilterType) => {
    const errors: Partial<TaskManagerFilterType> = {};
    if (
      value.dateAddedFrom &&
      value.dateAddedTo &&
      dayjs(value.dateAddedFrom).isAfter(dayjs(value.dateAddedTo))
    ) {
      errors.dateAddedTo = 'dateValidationForDateAdded';
    }
    if (
      value.dueDateFrom &&
      value.dueDateTo &&
      dayjs(value.dueDateFrom).isAfter(dayjs(value.dueDateTo))
    ) {
      errors.dueDateTo = 'dateValidationForDueDate';
    }
    return errors;
  };
  function compareObjectsDeeply(
    obj1: TaskManagerFilterType,
    obj2: TaskManagerFilterType,
  ): boolean {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  // const Custombuttonsubmit = styled(Button)`
  //   background: #00938e;
  //   border-radius: 4px;
  //   font-family: 'Lato-Regular', sans-serif;
  //   font-style: normal;
  //   font-weight: 600;
  //   font-size: 14px;
  //   line-height: 16.8px;
  //   color: #ffffff;
  //   padding: 10px, 16px, 10px, 16px;
  //   text-transform: 'none';
  //   &:hover {
  //     background: #00938e;
  //     color: #ffffff;
  //   }
  //   display: flex;
  //   flex-direction: row;
  //   justify-content: center;
  //   align-items: center;
  //   width: 122px;
  //   height: 40px;
  // `;

  const handleAdd = (responseData: TaskResponse | null) => {
    setTaskResponse(responseData);
    setOpenTaskManagerModal(true);
  };
  const handleRequestSort = (
    _event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    let orderDirection = order;
    if (property === orderBy) {
      orderDirection = order === 'asc' ? 'desc' : 'asc';
      setOrder(orderDirection);
    } else {
      setOrder('asc');
      orderDirection = 'asc';
    }
    setOrderBy(property);
    setSortData({ order: orderDirection, orderBy: getOrderBy(property) });
  };

  const getOrderBy = (orderby: string): string => {
    const orderByMapping: Record<string, string> = {
      dueDate: 'due_date',
      taskName: 'name',
      status: 'status',
      dateAdded: 'created_on',
      clientName: 'clientName',
      origin: 'firstName',
    };
    return orderByMapping[orderby] || 'due_date';
  };

  const handleAllClick = () => {
    setSelectedStatus(allStatusExceptCompleted);
    setCheckbox(false);
    setAllClick();
  };

  const setAllClick = () => {
    setBackgroundColorForAll('var(--thom-green-primary2)');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('var(--white)');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleOpenClick = () => {
    setSelectedStatus('backend.task_status.open');
    setBackgroundColorForOpen('#017AFF');

    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('var(--white');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleDueClick = () => {
    setSelectedStatus('backend.task_status.due');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#F96300');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('var(--white)');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleWarningClick = () => {
    setSelectedStatus('backend.task_status.warning');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#F9B803');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('var(--white)');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('#2a4241');
  };

  const handleOverdueClick = () => {
    setSelectedStatus('backend.task_status.overdue');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#EB4C60');
    setBackgroundColorForCompleted('#ECFFFE');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('var(--white)');
    setTextColorForCompleted('#2a4241');
  };

  const handleCompletedClick = () => {
    setSelectedStatus('backend.task_status.completed');
    setBackgroundColorForAll('#ECFFFE');
    setBackgroundColorForOpen('#ECFFFE');
    setBackgroundColorForDue('#ECFFFE');
    setBackgroundColorForWarning('#ECFFFE');
    setBackgroundColorForOverdue('#ECFFFE');
    setBackgroundColorForCompleted('#37D183');
    setTextColorForAll('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForOpen('#2a4241');
    setTextColorForDue('#2a4241');
    setTextColorForWarning('#2a4241');
    setTextColorForOverdue('#2a4241');
    setTextColorForCompleted('var(--white)');
  };

  // create a function to get the end date based on the selectedValue the Week dropdown
  const getEndDate = () => {
    const endDate = new Date();
    if (selectedValue === 'Week') {
      endDate.setDate(endDate.getDate() + 6);
    } else if (selectedValue === 'Month') {
      endDate.setMonth(endDate.getMonth() + 1);
      endDate.setDate(endDate.getDate() - 1);
    } else if (selectedValue === 'All') {
      endDate.setFullYear(endDate.getFullYear() + 3);
      endDate.setDate(endDate.getDate() - 1);
    }
    // return end date in MM/dd/yyyy format
    return formatDate(endDate, 'MM/DD/YYYY');
  };

  const formatDate = (date: Date, format: string) => {
    return dayjs(date).format(format);
  };

  useEffect(() => {
    if (page === 0) {
      getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), 0);
    } else {
      setPage(0);
    }
  }, [selectedStatus, selectedValue, searchTerm, filterData]);

  const handleDelete = (id: string) => {
    toggleLoader(true);
    deleteTaskId(id)
      .then(() => {
        setToastrId('taskDeleteSuccessMessage');
        setToastrDefaultMessage('Deleted the task successfully');
        setShowAlertDialog(undefined);
        toggleLoader(false);
        if (page === 0) {
          getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), 0);
        } else {
          setPage(0);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 400) {
            setToastrId('taskDeleteFailedMessage');
            setToastrDefaultMessage('Failed to delete the task');
          }
        }
      });
  };

  const getClients = () => {
    getAllClients(false)
      .then((response) => {
        setClients(response);
        toggleLoader(false);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
          setSuccessOrError('error');
        } else {
          const responseError = error as ApiMessage;
          setToastrId('failedToGetEmployee');
          setToastrDefaultMessage(responseError.message);
          setSuccessOrError('error');
        }
      });
  };

  const createEmployeeObj = (
    id: string,
    name: string | null,
    email: string | null,
    firstName: string | null,
    lastName: string | null,
    disciplineType: string | null,
    phone: string | null,
  ) => {
    return {
      id: id,
      name: name,
      firstName: firstName,
      lastName: lastName,
      email: email,
      disciplineType: disciplineType,

      nameSuffix: '',
      clientId: '',
      kcUsername: '',
      middleName: '',
      preferredName: '',
      phone1: phone,
      phone2: '',
      workingHoursPerWeek: 0,
      productivityGoal: null,
      allowProductivityGoalEdit: null,
      employeeRole: '',
      reportingTo: '',
      employeeType: '',
      startDate: '',
      endDate: '',
      status: '',
      primaryAddress1: '',
      primaryAddress2: '',
      primaryZipcode: '',
      primaryCity: '',
      primaryState: '',
      secondaryAddressRequired: false,
      secondaryAddress1: '',
      secondaryAddress2: '',
      secondaryZipcode: '',
      secondaryCity: '',
      secondaryState: '',
      emergencyFirstName: '',
      emergencyLastName: '',
      emergencyPhone: '',
      emergencyEmail: '',
      emergencyAddress1: '',
      emergencyAddress2: '',
      emergencyZipcode: '',
      emergencyCity: '',
      emergencyState: '',
      programs: [],
      modifiedOn: '',
      allowDelete: null,
      employeeCredentials: null,
      signature: '',
      pin: '',
      phoneNumberExtension: '',
      phoneNumberExtension2: '',
      defaultProgram: '',
      superProgramId: '',
      group: {
        id: '',
        groupName: '',
      },
    };
  };

  const getEmployees = () => {
    getAllEmployeeMinimalList()
      .then((data) => {
        let employeelist: Employee[] = [];
        employeelist = data.map((employee) => {
          return createEmployeeObj(
            employee.id,
            employee.name,
            employee.email,
            employee.firstName,
            employee.lastName,
            employee.disciplineType,
            employee.phone,
          );
        });
        employeelist.unshift(
          createEmployeeObj(
            'Thom Connect',
            'Thom Connect',
            '',
            'Thom Connect',
            '',
            '',
            '',
          ),
        );

        setEmployeeList(employeelist);

        getClients();
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
          setSuccessOrError('error');
        } else {
          setToastrId('failedToGetEmployee');
          setToastrDefaultMessage('Failed to get employee data');
          setSuccessOrError('error');
        }
      });
  };

  const getTaskList = (
    startDate: string,
    endDate: string,
    pageNumber: number,
  ) => {
    toggleLoader(true);
    const empId = localStorage.getItem('userId');
    if (empId === null) return;
    getTaskListAPI(
      pageNumber,
      10,
      sortData.orderBy,
      sortData.order,
      searchTerm,
      selectedStatus,
      startDate,
      endDate,
      empId,
      selectedValue === 'All',
      filterData,
    )
      .then((response: TaskListResponse) => {
        setTotalCount(response.totalTasks);
        setTask(response);
        if (clients.length === 0 || employeeList.length === 0) {
          getEmployees();
        } else {
          toggleLoader(false);
        }
      })
      .catch((error) => {
        toggleLoader(false);
        setSuccessOrError('error');
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastrId(apiError.id);
          setToastrDefaultMessage(apiError.message);
        } else {
          const response = error as ApiMessage;
          if (response.code === 400) {
            setToastrId('taskFetchFailedMessage');
            setToastrDefaultMessage('Failed to get the tasks');
          }
        }
      });
  };

  useEffect(() => {
    getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), 0);
  }, []);

  useEffect(() => {
    getTaskList(formatDate(new Date(), 'MM/DD/YYYY'), getEndDate(), page);
  }, [page, sortData]);

  return (
    <Box component="main">
      {toastrId && (
        <SnackBarComponent
          open={toastrId !== undefined}
          handleClose={() => {
            setToastrId(undefined);
          }}
          labelId={toastrId}
          defaultMessageId={defaultlabel}
          successOrError={successOrError}
        />
      )}
      {showAlertDialog && (
        <ModalPopup
          open={showAlertDialog !== undefined}
          onCancel={() => {
            setShowAlertDialog(undefined);
          }}
          description="CommunicationLogTableView.deleteDescription"
          onOk={() => handleDelete(showAlertDialog)}
          labelId1="Clientpage.cancelbtn"
          negativeActionLabel="cancelText"
          labelId2="Clientpage.Okbtn"
          positiveActionLabel="deleteText"
        />
      )}
      {openTaskManagerModal && (
        <TaskManagerAddModal
          open={openTaskManagerModal}
          taskResponse={taskResponse}
          handleClose={() => {
            setOpenTaskManagerModal(!openTaskManagerModal);
          }}
          onSuccess={() => {
            if (
              selectedStatus === allStatus ||
              selectedStatus === allStatusExceptCompleted
            ) {
              if (page === 0) {
                getTaskList(
                  formatDate(new Date(), 'MM/DD/YYYY'),
                  getEndDate(),
                  0,
                );
              } else {
                setPage(0);
              }
            } else {
              if (checkbox) {
                setSelectedStatus(allStatus);
              } else {
                setSelectedStatus(allStatusExceptCompleted);
              }
              setAllClick();
            }
          }}
          onMarkAsComplete={() => {
            getTaskList(
              formatDate(new Date(), 'MM/DD/YYYY'),
              getEndDate(),
              page,
            );
          }}
          onError={(errorId: string, errorMessage: string) => {
            setToastrId(errorId);
            setToastrDefaultMessage(errorMessage);
            setSuccessOrError('error');
          }}
        />
      )}
      <Box component="section">
        <Box className="rowContainer">
          <Grid container>
            <Grid item xs={4} lg={4}>
              <Box className="flex__ align__items__center">
                <TitleText
                  labelid="taskManagerTitleText"
                  defaultlabel="Task Manager"
                />
              </Box>
            </Grid>

            <Grid item xs={8} lg={8}>
              <Box className="flex__ justifyContent-FlexEnd">
                <SearchBox
                  labelId="taskManagerTaskSearch"
                  defaultlabel="Search Task"
                  sxProps={{ mx: '24px' }}
                  onChange={(e) => {
                    setSearchTerm(e);
                  }}
                />
                {/* <Box className="fitWidthContent headerButtonHeight mr-xlg">
                  <DropdownComponent
                    names={weekList}
                    value={selectedValue}
                    handleChange={(e) => {
                      setSelectedValue(e);
                    }}
                  />
                </Box> */}
                <Box
                  sx={{
                    flexGrow: 0,
                  }}
                >
                  <Button
                    className="btn-primary btn-filter"
                    variant="outlined"
                    onClick={handlefilter}
                    endIcon={<KeyboardArrowDownIcon />}
                    startIcon={<Filtericon />}
                  >
                    <FormattedMessage id="FiterText" defaultMessage="Filter" />
                  </Button>
                  <MemorizedCustomMenu
                    id="demo-customized-menu"
                    anchorEl={anchorEl}
                    open={openit}
                    onClose={handlefilterClose}
                  >
                    <Formik
                      innerRef={formRef}
                      initialValues={filterPlainData}
                      validationSchema={validationSchema}
                      onSubmit={(values) => {
                        setAnchorEl(null);
                        applyFilter(values);
                      }}
                      validate={validateForm}
                    >
                      {({
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                        values,
                      }) => (
                        <Grid container rowSpacing={2} columnSpacing={2}>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="dateAddedFrom"
                              value={
                                values.dateAddedFrom !== null
                                  ? (dayjs(values.dateAddedFrom) as Dayjs)
                                  : null
                              }
                              labelid="TaskManager.dateaddedfromlabel"
                              defaultlabelid="Date Added From"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('dateAddedFrom', null);
                                } else {
                                  setFieldValue('dateAddedFrom', formattedDate);
                                }
                                setFieldValue('dateAddedTo', null);
                              }}
                            />
                            {errors.dateAddedFrom && touched.dateAddedFrom && (
                              <MediumTypography
                                labelid={errors.dateAddedFrom}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            <MediumTypography
                              labelid="Dashboard.to"
                              defaultlabel="to"
                              sxProps={{
                                textAlign: 'center',
                                margin: 'revert',
                              }}
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="dateAddedTo"
                              value={
                                values.dateAddedTo !== null
                                  ? (dayjs(values.dateAddedTo) as Dayjs)
                                  : null
                              }
                              labelid="TaskManager.dateaddedtolabel"
                              defaultlabelid="Date Added To"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('dateAddedTo', null);
                                } else {
                                  setFieldValue('dateAddedTo', formattedDate);
                                }
                              }}
                            />
                            {errors.dateAddedTo && touched.dateAddedTo && (
                              <MediumTypography
                                labelid={errors.dateAddedTo}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="dueDateFrom"
                              value={
                                values.dueDateFrom !== null
                                  ? (dayjs(values.dueDateFrom) as Dayjs)
                                  : null
                              }
                              labelid="TaskManager.duedatefromlabel"
                              defaultlabelid="Due Date From"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('dueDateFrom', null);
                                } else {
                                  setFieldValue('dueDateFrom', formattedDate);
                                }
                                setFieldValue('dueDateTo', null);
                              }}
                            />
                            {errors.dueDateFrom && touched.dueDateFrom && (
                              <MediumTypography
                                labelid={errors.dueDateFrom}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>
                          <Grid item xs={1}>
                            <MediumTypography
                              labelid="Dashboard.to"
                              defaultlabel="to"
                              sxProps={{
                                textAlign: 'center',
                                margin: 'revert',
                              }}
                            />
                          </Grid>
                          <Grid item xs={5.5}>
                            <DatePickerComponent
                              name="dueDateTo"
                              value={
                                values.dueDateTo !== null
                                  ? (dayjs(values.dueDateTo) as Dayjs)
                                  : null
                              }
                              labelid="TaskManager.duedatetolabel"
                              defaultlabelid="Due Date To"
                              handlechange={(date: Dayjs | null) => {
                                const formattedDate =
                                  dayjs(date).format('MM/DD/YYYY');
                                if (date === null) {
                                  setFieldValue('dueDateTo', null);
                                } else {
                                  setFieldValue('dueDateTo', formattedDate);
                                }
                              }}
                            />
                            {errors.dueDateTo && touched.dueDateTo && (
                              <MediumTypography
                                labelid={errors.dueDateTo}
                                textColor="red"
                                marginTop="8px"
                              />
                            )}
                          </Grid>

                          <Grid item xs={12} className="multisearchHgt">
                            <MultiSelectAutocompleteSearch
                              labelId="originText"
                              defaultId="Origin"
                              onlyName={true}
                              onSelectItem={(
                                value: MultiSelectOptioinType[],
                              ) => {
                                if (value) {
                                  if (employeeList.length > 0) {
                                    setFieldValue('origin', value);
                                  } else {
                                    setFieldValue('origin', []);
                                  }
                                }
                              }}
                              value={values.origin ? values.origin : []}
                              highlightSelectedValues={[]}
                              highlightSelectedValuesTooltipLabelId=""
                              data={employeeList}
                              hideOption={null}
                              optionHintId={
                                'AddNewSchedulerEvent.noCliniciansFoundInOptions'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} className="multisearchHgt">
                            <MultiSelectAutocompleteSearch
                              labelId="clientText"
                              defaultId="Client"
                              onlyName={true}
                              value={values.client}
                              onSelectItem={(
                                value: MultiSelectOptioinType[],
                              ) => {
                                if (clients.length > 0) {
                                  setFieldValue('client', value);
                                } else {
                                  setFieldValue('client', []);
                                }
                              }}
                              data={clients}
                              optionHintId={
                                'AddNewSchedulerEvent.noClientsFoundInOptions'
                              }
                            />
                          </Grid>

                          <Grid item xs={12} textAlign={right} mt={1.5}>
                            <Box
                              sx={{
                                justifyContent: 'right',
                                display: 'flex',
                              }}
                            >
                              <Box className="mr-md">
                                <ButtonComponent
                                  className="btn-primary btn-cancel btn_width_90px"
                                  variantType="contained"
                                  labelId="Dashboard.filtercancel"
                                  defaultLabelId="Cancel"
                                  onClick={() => {
                                    setAnchorEl(null);
                                  }}
                                  disabled={false}
                                />
                              </Box>
                              <Box>
                                <ButtonComponent
                                  className="btn-primary btn-submit btn_width_90px"
                                  variantType="contained"
                                  type="submit"
                                  labelId="Dashboard.filtersubmit"
                                  defaultLabelId="Apply"
                                  onClick={() => {
                                    if (
                                      compareObjectsDeeply(
                                        filterPlainData,
                                        values,
                                      )
                                    ) {
                                      return;
                                    }

                                    handleSubmit();
                                  }}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Formik>
                  </MemorizedCustomMenu>
                </Box>
                {checkPermissionForFeature(
                  'backend.task_manager',
                  'addPermission',
                ) &&
                  tasks &&
                  tasks.tasks.length > 0 && (
                    <Box sx={{ marginLeft: '24px' }}>
                      <ButtonComponent
                        className="btn-primary btn-submit"
                        variantType="contained"
                        type="submit"
                        labelId="taskManagerAddText"
                        defaultLabelId="Add Task"
                        onClick={() => {
                          setTaskResponse(null);
                          setOpenTaskManagerModal(!openTaskManagerModal);
                        }}
                      />
                    </Box>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Grid container className="mb-md">
          <Grid item xs={9} lg={9}>
            <Box
              className="flex__ "
              alignItems={'center'}
              sx={{ bottom: '12px' }}
              gap={3}
            >
              <Fab
                variant="extended"
                size="small"
                className="fabStyles"
                sx={{
                  backgroundColor: backgroundColorForAll,
                  color: textColorForAll,
                  borderLeft: '4px solid #00C6B8',
                  '&:hover': {
                    backgroundColor: backgroundColorForAll,
                    color: textColorForAll,
                  },
                }}
                onClick={handleAllClick}
              >
                <MediumTypography
                  labelid={'backend.task_status.all'}
                  defaultlabel="All"
                />
                <Box className="pl-sm">
                  {tasks && (
                    <MediumTypography
                      label={`${
                        tasks.completedStatusCount +
                        tasks.openStatusCount +
                        tasks.dueStatusCount +
                        tasks.warningStatusCount +
                        tasks.overdueStatusCount
                      }`}
                    />
                  )}
                </Box>
              </Fab>
              <Fab
                variant="extended"
                size="small"
                className="fabStyles"
                sx={{
                  backgroundColor: backgroundColorForOpen,
                  borderLeft: '4px solid #017AFF',
                  color: textColorForOpen,
                  '&:hover': {
                    backgroundColor: backgroundColorForOpen,
                    color: textColorForOpen,
                  },
                }}
                onClick={handleOpenClick}
              >
                <MediumTypography
                  labelid={'backend.task_status.open'}
                  defaultlabel="Open"
                />
                <Box className="pl-sm">
                  {tasks && (
                    <MediumTypography label={`${tasks.openStatusCount}`} />
                  )}
                </Box>
              </Fab>
              <Fab
                variant="extended"
                size="small"
                className="fabStyles"
                sx={{
                  backgroundColor: backgroundColorForWarning,
                  borderLeft: '4px solid #F9B803',
                  color: textColorForWarning,
                  '&:hover': {
                    backgroundColor: backgroundColorForWarning,
                    color: textColorForWarning,
                  },
                }}
                onClick={handleWarningClick}
              >
                <MediumTypography
                  labelid={'backend.task_status.warning'}
                  defaultlabel="Warning"
                />
                <Box className="pl-sm">
                  {tasks && (
                    <MediumTypography label={`${tasks.warningStatusCount}`} />
                  )}
                </Box>
              </Fab>
              <Fab
                variant="extended"
                size="small"
                className="fabStyles"
                sx={{
                  backgroundColor: backgroundColorForDue,
                  borderLeft: '4px solid #F96300',
                  color: textColorForDue,
                  '&:hover': {
                    backgroundColor: backgroundColorForDue,
                    color: textColorForDue,
                  },
                }}
                onClick={handleDueClick}
              >
                <MediumTypography
                  labelid={'backend.task_status.due'}
                  defaultlabel="Due"
                />
                <Box className="pl-sm">
                  {tasks && (
                    <MediumTypography label={`${tasks.dueStatusCount}`} />
                  )}
                </Box>
              </Fab>
              <Fab
                variant="extended"
                size="small"
                className="fabStyles"
                sx={{
                  backgroundColor: backgroundColorForOverdue,
                  borderLeft: '4px solid #EB4C60',
                  color: textColorForOverdue,
                  '&:hover': {
                    backgroundColor: backgroundColorForOverdue,
                    color: textColorForOverdue,
                  },
                }}
                onClick={handleOverdueClick}
              >
                <MediumTypography
                  labelid={'backend.task_status.overdue'}
                  defaultlabel="Overdue"
                />
                <Box className="pl-sm">
                  {tasks && (
                    <MediumTypography label={`${tasks.overdueStatusCount}`} />
                  )}
                </Box>
              </Fab>
              <Fab
                variant="extended"
                size="small"
                className="fabStyles"
                sx={{
                  backgroundColor: backgroundColorForCompleted,
                  borderLeft: '4px solid #37D183',
                  color: textColorForCompleted,
                  '&:hover': {
                    backgroundColor: backgroundColorForCompleted,
                    color: textColorForCompleted,
                  },
                }}
                onClick={handleCompletedClick}
              >
                <MediumTypography
                  labelid={'backend.task_status.completed'}
                  defaultlabel="Completed"
                />
                <Box className="pl-sm">
                  {tasks && (
                    <MediumTypography label={`${tasks.completedStatusCount}`} />
                  )}
                </Box>
              </Fab>
            </Box>
          </Grid>
          <Grid item xs={3} lg={3}>
            {(selectedStatus == allStatus ||
              selectedStatus == allStatusExceptCompleted) && (
              <Box className="flex__ justifyContent-FlexEnd">
                <Box component="div">
                  <CheckBoxComponent
                    className="mr-none"
                    defaultlabelid="Include Completed"
                    labelid="includeCompleted"
                    ischecked={checkbox}
                    CheckHandleChange={(
                      e: React.ChangeEvent<HTMLInputElement>,
                    ) => {
                      if (e.target.checked) {
                        setSelectedStatus(allStatus);
                      } else {
                        setSelectedStatus(allStatusExceptCompleted);
                      }

                      setCheckbox(e.target.checked);
                    }}
                  />
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>

        {filterBy.length > 0 && (
          <Box sx={{ marginBottom: '10px' }}>
            <DisplayFilter
              filteredData={filterBy}
              handleCloseClick={(index: number, key: string | undefined) => {
                if (filterBy.length < 2) {
                  clearForm();
                } else {
                  if (key === 'TaskManager.originfilter') {
                    const updatedFilterPlainData: TaskManagerFilterType = {
                      ...filterPlainData,
                      origin: [],
                    };

                    applyFilter(updatedFilterPlainData);
                  } else if (key === 'TaskManager.clientfilter') {
                    const updatedFilterPlainData: TaskManagerFilterType = {
                      ...filterPlainData,
                      client: [],
                    };

                    applyFilter(updatedFilterPlainData);
                  } else if (key === 'TaskManager.duedatefilter') {
                    const updatedFilterPlainData: TaskManagerFilterType = {
                      ...filterPlainData,
                      dueDateFrom: null,
                      dueDateTo: null,
                    };
                    applyFilter(updatedFilterPlainData);
                  } else {
                    const updatedFilterPlainData: TaskManagerFilterType = {
                      ...filterPlainData,
                      dateAddedFrom: null,
                      dateAddedTo: null,
                    };
                    applyFilter(updatedFilterPlainData);
                  }
                }
              }}
              handleCloseClickArrayType={(main: number, sub: number) => {
                if (filterBy.length < 2 && filterBy[0].value.length < 2) {
                  clearForm();
                } else {
                  if (
                    filterBy[main].key === 'TaskManager.clientfilter' &&
                    filterBy[main].value.length < 2
                  ) {
                    const updatedFilterPlainData: TaskManagerFilterType = {
                      ...filterPlainData,
                      client: [],
                    };

                    applyFilter(updatedFilterPlainData);
                  } else if (
                    filterBy[main].key === 'TaskManager.clientfilter' &&
                    Array.isArray(filterBy[main].value)
                  ) {
                    const updatedFilterPlainData: TaskManagerFilterType = {
                      ...filterPlainData,
                      client: filterPlainData.client.filter(
                        (item, index) => index !== sub,
                      ),
                    };
                    applyFilter(updatedFilterPlainData);
                  }
                }
              }}
              handleClearClick={() => {
                clearForm();
              }}
              sxProps={{ marginTop: '0px' }}
            />
          </Box>
        )}
        {tasks && tasks.tasks.length === 0 && (
          <Box className="pb-md">
            <EmptyScreen
              titleLabelId="noTasksFound"
              defaultTitleText="No Tasks Found"
              buttonLabelId="addTask"
              defaultButtonTitleText="Add Task"
              showButton={checkPermissionForFeature(
                'backend.task_manager',
                'addPermission',
              )}
              onButtonClick={() => handleAdd(null)}
            />
          </Box>
        )}
        {tasks && tasks.tasks.length > 0 && (
          <Box component="div">
            <Card>
              <TableContainer>
                <Table>
                  <TableHeader
                    className="listDataTableHead"
                    headerNames={headCells}
                    checkBoxRequired={false}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody className="tableRowcss">
                    {tasks.tasks.map((data, index: number) => {
                      return (
                        <TableRow
                          hover
                          onClick={() => {}}
                          key={index}
                          sx={{
                            cursor: 'pointer',
                            backgroundColor:
                              index % 2 === 0 ? '#ECF9F8' : '#ffffff',
                          }}
                        >
                          <TooltipTableCell
                            value={data.name ? data.name : '-'}
                            visible={true}
                            textSxProps={{ width: '100%', maxWidth: '200px' }}
                          />
                          <TooltipTableCell
                            className={
                              data.userType === 'Client' ? 'cursorPointer' : ''
                            }
                            onClick={() => {
                              if (
                                data.userType === 'Client' &&
                                data.functionalityId != null &&
                                data.clientId !== null
                              ) {
                                const taskClientId = data.clientId;
                                localStorage.setItem('ClientId', taskClientId);
                                localStorage.setItem(
                                  CLIENT_NAME_KEY,
                                  data.origin,
                                );
                                localStorage.setItem(
                                  REFERRAL_ID_KEY,
                                  data.functionalityId,
                                );
                                // if (
                                //   checkPermissionForFeature(
                                //     'backend.clients',
                                //     'viewPermission',
                                //   )
                                // ) {
                                //   navigation(FACESHEET, {
                                //     state: { taskClientId },
                                //   });
                                // }
                              }
                            }}
                            value={data.origin ? data.origin : '-'}
                            visible={true}
                          />
                          <TooltipTableCell
                            value={
                              data.clientName && data.clientName.trim() !== ''
                                ? data.clientName
                                : '-'
                            }
                            visible={true}
                          />
                          <TooltipTableCell
                            value={data.createdOn ? data.createdOn : '-'}
                            visible={true}
                          />

                          <TooltipTableCell
                            value={
                              data.dueDate
                                ? data.dueDate === ''
                                  ? '-'
                                  : data.dueDate
                                : '-'
                            }
                            visible={true}
                          />

                          <TooltipTableCell
                            className="cursorPointer"
                            localizationId={data.status}
                            textSxProps={{
                              ...(data.status ===
                              'backend.task_status.completed'
                                ? { color: '#008C82' }
                                : data.status === 'backend.task_status.open'
                                ? { color: '#017AFF' }
                                : data.status === 'backend.task_status.due'
                                ? { color: '#F96300' }
                                : data.status === 'backend.task_status.warning'
                                ? { color: '#F9B803' }
                                : data.status === 'backend.task_status.overdue'
                                ? { color: '#EB4C60' }
                                : { color: '#2A4241' }),
                            }}
                            visible={true}
                          />

                          <TableCell>
                            <Box className="flex__ alignItemCenter cursorPointer">
                              <Box>
                                <ViewEye onClick={() => handleAdd(data)} />
                              </Box>
                              {checkPermissionForFeature(
                                'backend.task_manager',
                                'deletePermission',
                              ) &&
                                data &&
                                data.taskType === 'Default' && (
                                  <Box className="ml-md cursorPointer">
                                    <DeleteIcon
                                      onClick={() =>
                                        setShowAlertDialog(data.id)
                                      }
                                    />
                                  </Box>
                                )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
            {totalCount > 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={page}
                  paginationCount={totalCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== page) {
                      setPage(newPage - 1);
                    }
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default TaskManagerList;
