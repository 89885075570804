import dayjs from 'dayjs';
import { ApiMessage, ApiResponseHandler } from '../ApiResponseHandler';
import ThomApiClient from '../ThomApiClient';
import { WaiversType } from '../../utils/type';

export const getAllWaivers = async (
  clientId: string,
): Promise<WaiversType[]> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().get(
      `client/waiver?clientId=${clientId}&timezone=${timezone}`,
    ),
  );
};

export const createWaiver = async (
  params: WaiversType,
  clientId: string,
): Promise<ApiMessage> => {
  const timezone = encodeURIComponent(dayjs(new Date()).format('Z'));
  return ApiResponseHandler(
    await ThomApiClient.getInstance().post(
      `client/waiver?clientId=${clientId}&timezone=${timezone}`,
      params,
    ),
  );
};

export const updateWaiver = async (
  params: WaiversType,
  clientId: string,
  id: string,
): Promise<ApiMessage> => {
  return ApiResponseHandler(
    await ThomApiClient.getInstance().put(
      `client/waiver/${id}?clientId=${clientId}`,
      params,
    ),
  );
};
