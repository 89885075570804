import { Box, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import MediumTypography from './MediumTypography';

export interface MenuData {
  labelId: string;
  defaultLabelId: string;
  visible: boolean;
  itemTextColor?: string;
  disabled?: boolean;
}

interface MenuType {
  rootView: React.ReactElement;
  items: MenuData[];
  onItemClick: (index: number) => void;
}

const ActionMenu: FC<MenuType> = ({ rootView, items, onItemClick }) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null);
  const openAnchor = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box sx={{ cursor: 'pointer' }} onClick={handleClick}>
        {rootView}
      </Box>
      <Menu
        className="viewAddDropdown"
        id="basic-menu"
        anchorEl={anchorEl}
        open={openAnchor}
        style={{
          boxShadow: 'inherit',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        sx={{
          '&. MuiPaper-root-MuiPopover-paper-MuiMenu-paper': {
            boxShadow: 'none',
          },
        }}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((item, index) => {
          if (item.visible) {
            return (
              <MenuItem
                sx={{
                  borderBottom: '1px solid #E7E8ED',
                  paddingTop: '8px',
                  paddingBottom: '8px',
                }}
                disabled={item.disabled}
                onClick={() => {
                  handleMenuClose();
                  onItemClick(index);
                }}
                key={item.labelId}
              >
                <MediumTypography
                  labelid={item.labelId}
                  defaultlabel={item.defaultLabelId}
                  textColor={item.itemTextColor}
                />
              </MenuItem>
            );
          }
        })}
      </Menu>
    </Box>
  );
};

export default ActionMenu;
