import React, { FC, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { loginEntraId } from '../services/userApi/User';
import MediumTypography from '../components/formlib/MediumTypography';
import { Box } from '@mui/system';
import {
  SSO_REDIRECT_URI,
  STORAGE_USER_ID_KEY,
  STORAGE_USERNAME_KEY,
} from '../services/Constant';
import { Context } from '../LanguageWrapper';
import { isSupported } from 'firebase/messaging';
import { Notification } from '../utils/type';
import { getFirebaseToken } from '../firebase';
import { notifications } from '../services/configApi/notification/notification';

interface AuthPropType {
  onLoginSuccess: (userId: string) => void;
}

export interface AuthBody {
  code: string;
  redirectUri: string;
  grantType: string;
  refreshToken?: string;
}

const AuthScreen: FC<AuthPropType> = ({ onLoginSuccess }) => {
  const context = useContext(Context);
  const navigation = useNavigate();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');
    if (code) {
      submit(code);
    }
  }, []);

  const submit = async (codeValue: string) => {
    const userparams: AuthBody = {
      code: codeValue,
      redirectUri: `${SSO_REDIRECT_URI}auth`,
      grantType: 'password',
    };

    loginEntraId(userparams)
      .then(async (response) => {
        console.log('response=', response);
        if (response !== null && response.programList !== null) {
          localStorage.setItem('accessToken', response.accessToken);
          localStorage.setItem('refreshToken', response.refreshToken);
          localStorage.setItem('offlineToken', response.offlineToken);
          localStorage.setItem(STORAGE_USERNAME_KEY, response.name);
          localStorage.setItem(STORAGE_USER_ID_KEY, response.userId);
          localStorage.setItem('refreshTokenExpiry', response.refreshExpiresIn);
          localStorage.setItem('superProgramId', response.superProgramId);
          localStorage.setItem(
            'defaultProgramId',
            response.defaultProgramId ?? '',
          );
          context.updatePrograms(response.programList);
          context.updateSelectedProgram(
            response.defaultProgramId
              ? response.programList.filter(
                  (program) => program.id === response.defaultProgramId,
                )[0]
              : response.programList[0],
            false,
          );
          checkAndReadFirebaseToken(response.refreshExpiresIn, response.userId);
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const checkAndReadFirebaseToken = async (
    refreshExpiresIn: string,
    userId: string,
  ) => {
    try {
      if (
        (await isSupported()) &&
        window.Notification.permission === 'granted'
      ) {
        readFirebaseToken(refreshExpiresIn, userId);
      } else {
        navigation('/');
        onLoginSuccess(userId);
      }
    } catch (error) {
      navigation('/');
      onLoginSuccess(userId);
    }
  };

  const readFirebaseToken = (refreshExpiresIn: string, userId: string) => {
    getFirebaseToken()
      .then((firebaseToken) => {
        const params: Notification = {
          deviceToken: firebaseToken,
          refreshExpiresIn: refreshExpiresIn,
        };
        localStorage.setItem('fireBaseToken', firebaseToken);
        readNotifications(params, userId);
      })
      .catch(() => {
        navigation('/');
        onLoginSuccess(userId);
      });
  };

  const readNotifications = (params: Notification, userId: string) => {
    notifications(params)
      .then(() => {
        console.log('notification is coming in signin.tsx');
      })
      .catch(async (error) => {
        console.error(
          'An error occurred while adding notification info and device token:',
          error,
        );
      })
      .finally(() => {
        navigation('/');
        onLoginSuccess(userId);
      });
  };

  return (
    <>
      <Box>
        <MediumTypography label="Loading" />
      </Box>
    </>
  );
};

export default AuthScreen;
