import {
  Box,
  Card,
  // Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  debounce,
} from '@mui/material';
import TitleText from '../../components/formlib/TitleText';
import { useIntl } from 'react-intl';
import { SELECTED_PROGRAM_KEY } from '../../services/Constant';
import { OptionType, SnackbarType } from '../../utils/type';
import { useCallback, useContext, useEffect, useState } from 'react';
import MediumTypography from '../../components/formlib/MediumTypography';
import { HeadCell, TableHeader } from '../../components/formlib/TableHeader';
import {
  ActivitySheetEmployeeResponse,
  // ReviewActivitySheetOverView,
  ReviewEmployeeHeader,
} from './types';
import {
  getReviewActivitySheetHeaders,
  // nonWorkingLabelIds,
  // workingLabelIds,
} from './activityUtils';
import ActivitySheetEmployeeRow from './ActivitySheetEmployeeRow';
// import DoughnutChart from '../../components/charts/DoughnutChart';
import {
  // convertTimeDuration,
  formatDate,
  // formatStringTime,
} from '../../utils/dateUtil';
import AppPagination from '../../components/shared/AppPagination';
import { useLocation, useNavigate } from 'react-router';
import { ACTIVITY_APPROVAL_SHEET_ROUTE_NAME } from '../../routes/Routing';
import SearchBox from '../../components/formlib/SearchBox';
import {
  // getSubmittedActivitySheet,
  getSubmittedActivitySheetEmployees,
} from '../../services/configApi/activitySheet/weeklyActivitySheetProvider';
import { LoaderContext, LoaderContextType } from '../../layouts/AppSidebar';
import SnackBarComponent from '../../components/formlib/SnackBarComponent';
import { ApiError, isCustomError } from '../../services/ApiResponseHandler';
import WeekDatePicker from '../../components/formlib/modal/WeekDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import HolidayListDialog from '../holidays/HolidayListDialog';
import {
  Holiday,
  getHolidayList,
} from '../../services/configApi/employees/holidayServices';
import { Context } from '../../LanguageWrapper';

const ActivitySheetOverview = () => {
  const rctl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [showHolidays, setShowHolidays] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<Date>(
    location.state ? location.state.date : new Date(),
  );
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [selectedProgram, setSelectedProgram] = useState<OptionType | null>(
    null,
  );
  const context = useContext(Context);
  const [holidays, setHolidays] = useState<Holiday[]>([]);
  const { toggleLoader } = useContext(LoaderContext) as LoaderContextType;
  // const [activity, setActivity] = useState<ActivitySheet | null>(null);
  const [employeeResponse, setEmployeeResponse] =
    useState<ActivitySheetEmployeeResponse | null>(null);
  const [toastr, setToastr] = useState<SnackbarType>({
    toasterId: null,
    defaultToasterMessage: '',
    toasterAction: 'info',
  });

  // useEffect(() => {
  //   getActivitySheetChartValues();
  // }, [selectedDate, searchQuery]);

  useEffect(() => {
    const navParams = location.state;
    if (navParams !== null) {
      setSelectedDate(navParams.date as Date);
    }
  }, []);

  useEffect(() => {
    getActivitySheetEmployees();
  }, [selectedDate, searchQuery, pageNumber]);

  // const getActivitySheetChartValues = () => {
  //   getSubmittedActivitySheet(formatDate(selectedDate, 'MM/DD/YYYY'))
  //     .then((response) => {
  //       setActivity(response);
  //     })
  //     .catch((error) => {
  //       if (isCustomError(error)) {
  //         const apiError = error as ApiError;
  //         setToastr({
  //           toasterId: apiError.id,
  //           toasterAction: 'error',
  //           defaultToasterMessage: apiError.message,
  //         });
  //       } else {
  //         setToastr({
  //           toasterId: 'requestFailureMessage',
  //           toasterAction: 'error',
  //           defaultToasterMessage: 'Request Failure',
  //         });
  //       }
  //     });
  // };

  const getActivitySheetEmployees = () => {
    toggleLoader(true);
    getSubmittedActivitySheetEmployees(
      formatDate(selectedDate, 'MM/DD/YYYY'),
      searchQuery,
      pageNumber,
    )
      .then((response) => {
        toggleLoader(false);
        setEmployeeResponse(response);
      })
      .catch((error) => {
        toggleLoader(false);
        if (isCustomError(error)) {
          const apiError = error as ApiError;
          setToastr({
            toasterId: apiError.id,
            toasterAction: 'error',
            defaultToasterMessage: apiError.message,
          });
        } else {
          setToastr({
            toasterId: 'requestFailureMessage',
            toasterAction: 'error',
            defaultToasterMessage: 'Request Failure',
          });
        }
      });
  };

  const readHolidayList = () => {
    toggleLoader(true);
    const program = context.selectedProgram;
    if (program === undefined) {
      return;
    }

    getHolidayList(formatDate(selectedDate, 'MM/DD/YYYY'), program.id)
      .then((response) => {
        toggleLoader(false);
        setShowHolidays(true);
        setHolidays(response.holidayList);
      })
      .catch(() => {
        toggleLoader(false);
      });
  };

  // const getChartValues = (): number[] => {
  //   if (activityChart !== null) {
  //     const billableHours = formatStringTime(
  //       activityChart.totalBillableHours !== null
  //         ? activityChart.totalBillableHours
  //         : '00:00',
  //     );
  //     const productivityGoal = formatStringTime(
  //       activityChart.productivityGoal !== null
  //         ? activityChart.productivityGoal
  //         : '00:00',
  //     );
  //     if (productivityGoal === 0 && billableHours === 0) {
  //       return [0, 1];
  //     }
  //     return [billableHours, productivityGoal];
  //   }
  //   return [0, 0];
  // };

  // const getSalariedChartValues = (): number[] => {
  //   if (activityChart !== null) {
  //     const etHours = formatStringTime(activityChart.totalEtHours ?? '00:00');
  //     const ltiHours = formatStringTime(activityChart.totalLtiHours ?? '00:00');
  //     const vacationHours = formatStringTime(
  //       activityChart.totalVacationHours ?? '00:00',
  //     );
  //     const profHours = formatStringTime(
  //       activityChart.totalProfessionalHours ?? '00:00',
  //     );
  //     const holidayHours = formatStringTime(
  //       activityChart.totalHolidayTimeHours ?? '00:00',
  //     );
  //     const otherHours = formatStringTime(
  //       activityChart.totalOtherHours ?? '00:00',
  //     );
  //     if (
  //       etHours === 0 &&
  //       holidayHours === 0 &&
  //       ltiHours === 0 &&
  //       vacationHours === 0 &&
  //       profHours === 0 &&
  //       otherHours === 0
  //     ) {
  //       return [0, 0, 0, 0, 0, 0, 1];
  //     } else {
  //       return [
  //         etHours,
  //         ltiHours,
  //         holidayHours,
  //         vacationHours,
  //         profHours,
  //         otherHours,
  //       ];
  //     }
  //   }
  //   return [0, 0, 0, 0, 0, 0, 1];
  // };

  // const getSalariedTotalWorkUsage = () => {
  //   if (activityChart === null) {
  //     return '00.00 hrs';
  //   }
  //   return activityChart.totalHoursOut !== null
  //     ? convertTimeDuration(activityChart.totalHoursOut, true) + ' hrs'
  //     : '00.00 hrs';
  // };

  // const getTotalWorkUsage = () => {
  //   if (!activityChart) {
  //     return '00%';
  //   }
  //   if (
  //     activityChart?.productivityGoal === null ||
  //     activityChart?.totalBillableHours === null
  //   ) {
  //     return '00%';
  //   }
  //   const billableHours = formatStringTime(activityChart.totalBillableHours);
  //   if (billableHours === 0) {
  //     return '00%';
  //   }
  //   const productivityGoal = formatStringTime(
  //     activityChart.productivityGoal,
  //   );
  //   return ((billableHours / productivityGoal) * 100).toFixed(2) + '%';
  // };

  useEffect(() => {
    const program = localStorage.getItem(SELECTED_PROGRAM_KEY);
    if (typeof program === 'string') {
      setSelectedProgram(JSON.parse(program) as OptionType);
    }
  }, []);

  const handleToasterClose = () => {
    setToastr({
      toasterId: null,
      toasterAction: 'info',
      defaultToasterMessage: '',
    });
  };

  const changeTextDebouncer = useCallback(
    debounce((input: string) => {
      setPageNumber(0);
      setSearchQuery(input);
    }, 500),
    [],
  );

  const [headerCells, setHeaderCells] = useState<
    HeadCell<ReviewEmployeeHeader>[]
  >([]);

  useEffect(() => {
    setHeaderCells(getReviewActivitySheetHeaders());
  }, []);

  return (
    <Box component={'main'}>
      <Box component={'section'} sx={{ display: 'flex', marginTop: '24px' }}>
        {toastr.toasterId !== null && (
          <SnackBarComponent
            open={true}
            handleClose={handleToasterClose}
            successOrError={toastr.toasterAction}
            labelId={toastr.toasterId}
            defaultMessageId={toastr.defaultToasterMessage}
          />
        )}
        {showHolidays && (
          <HolidayListDialog
            open={showHolidays}
            holidays={holidays}
            onClose={() => setShowHolidays(false)}
          />
        )}
        <Box sx={{ display: 'flex' }}>
          <TitleText
            label={`${rctl.formatMessage({
              id: 'reviewActivitySheetText',
            })}:`}
            defaultlabel="reviewActivitySheetText"
          />
          <TitleText
            label={`${selectedProgram !== null ? selectedProgram.label : ''}`}
            defaultlabel="Program Name"
            Sxprops={{ color: '#008C82', marginLeft: '8px' }}
          />
        </Box>
      </Box>
      <Box component={'section'} sx={{ display: 'flex', marginTop: '16px' }}>
        <WeekDatePicker
          date={dayjs(selectedDate)}
          disableFutureDate={true}
          onDateSelect={(newDate: Dayjs) => {
            setSelectedDate(newDate.toDate());
          }}
        />

        <Box
          component={'section'}
          sx={{ display: 'flex', alignItems: 'center', marginLeft: '24px' }}
        >
          <MediumTypography
            sxProps={{ fontSize: '16px' }}
            labelid="employeeSubmitSheetText"
            defaultlabel="No. of employees submitted the Activity Sheet -"
          />
          <MediumTypography
            label={`${
              employeeResponse !== null ? employeeResponse.submittedCount : 0
            }`}
            defaultlabel="0 / 0"
            sxProps={{
              fontSize: '16px',
              color: '#008C82',
              marginLeft: '8px',
              fontWeight: 'bold',
            }}
          />
        </Box>

        <Box
          sx={{
            marginRight: '8px',
            alignSelf: 'center',
            marginLeft: 'auto',
          }}
          onClick={() => {
            if (holidays.length === 0) {
              readHolidayList();
            } else {
              setShowHolidays(true);
            }
          }}
        >
          <MediumTypography
            sxProps={{ color: '#008C82', cursor: 'pointer' }}
            labelid={`${rctl.formatMessage({
              id: 'holiday(s)Text',
              defaultMessage: 'Holiday(s)',
            })} ${dayjs().format('YYYY')}`}
            defaultlabel="Holiday(s)"
          />
        </Box>
      </Box>

      <Box sx={{ display: 'flex', marginTop: '16px' }}>
        <SearchBox
          labelId="searchEmployeeText"
          defaultlabel="Search Employee"
          onChange={changeTextDebouncer}
        />
      </Box>

      <Box component={'section'} sx={{ marginTop: '16px' }}>
        <Card sx={{ width: '100%' }}>
          <TableContainer>
            <Table
              sx={{ minWidth: 750, maxWidth: '100%' }}
              aria-labelledby="tableTitle"
              size={'medium'}
            >
              <TableHeader
                className="listDataTableHead"
                labelSxProp={{ whiteSpace: 'normal', padding: 'none' }}
                headerNames={headerCells}
                checkBoxRequired={false}
              />
              <TableBody className="tableRowcss">
                {employeeResponse?.employeesReview.map((item) => {
                  return (
                    <ActivitySheetEmployeeRow
                      key={item.id}
                      data={item}
                      handleViewClick={(id: string) => {
                        navigate(ACTIVITY_APPROVAL_SHEET_ROUTE_NAME, {
                          state: {
                            id: id,
                            date: selectedDate,
                            firstName: item.firstName,
                            lastName: item.lastName,
                          },
                        });
                      }}
                    />
                  );
                })}
                {employeeResponse !== null &&
                  employeeResponse.employeesReview.length === 0 && (
                    <TableRow>
                      <TableCell colSpan={10}>
                        <Box
                          display={'flex'}
                          justifyContent={'center'}
                          padding={'16px'}
                        >
                          {searchQuery.length === 0 ? (
                            <MediumTypography
                              labelid="noRecordsFoundMessage"
                              defaultlabel="No records found"
                            />
                          ) : (
                            <MediumTypography
                              labelid="searchnotfound.smallText"
                              defaultlabel="We could not find any search results. Give it another go"
                            />
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </TableContainer>

          {employeeResponse !== null &&
            employeeResponse.employeesReview.length > 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <AppPagination
                  pageNumber={pageNumber}
                  paginationCount={employeeResponse.submittedCount}
                  handleChangePage={(_event, newPage) => {
                    if (newPage - 1 !== pageNumber) {
                      setPageNumber(newPage - 1);
                    }
                  }}
                />
              </Box>
            )}
        </Card>
      </Box>
    </Box>
  );
};

export default ActivitySheetOverview;
