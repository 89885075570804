import { Box, ThemeProvider } from '@mui/material';
import {
  a11yProps,
  CustomTab,
  CustomTabs,
  TabPanel,
  TabTheme,
} from './TabNavigationComponent';
import { useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import LEAPreschoolForm from '../../pages/referral/LEAPreschoolForm';
import LEAReferralConsent from '../../pages/consent/LEAReferralConsent';
import { UnSavedChangesContext } from '../../context/UnSavedChangesProvider';
import ModalPopup from './ModalPopup';

const LEATabNavigationComponent = () => {
  const [currentTab, setCurrentTab] = useState<number | null>(null);
  const [openUnSavedModal, setOpenUnSavedModal] = useState<boolean>(false);
  const [pendingPosition, setPendingPosition] = useState<number>(0);
  const { hasUnsavedChanges, handleUpdateUnsavedChanges } = useContext(
    UnSavedChangesContext,
  );
  const intl = useIntl();

  useEffect(() => {
    const currentTabValue = sessionStorage.getItem('leaReferral');
    setCurrentTab(currentTabValue === null ? 0 : parseInt(currentTabValue));

    return () => {
      sessionStorage.removeItem('leaReferral');
    };
  }, []);

  if (currentTab === null) {
    return null;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <ModalPopup
        open={openUnSavedModal}
        description="UnsavedAlertDescriptionWhileSwitching"
        labelId1="UnSavedAlertCancel"
        labelId2="UnsavedAlertSwitch"
        negativeActionLabel="No"
        positiveActionLabel="Yes"
        onCancel={() => {
          setOpenUnSavedModal(false);
        }}
        onOk={() => {
          sessionStorage.setItem('leaReferral', String(pendingPosition));
          setCurrentTab(pendingPosition);
          handleUpdateUnsavedChanges(false);
          setOpenUnSavedModal(false);
        }}
      />
      <ThemeProvider theme={TabTheme}>
        <CustomTabs
          sx={{ marginLeft: '4px' }}
          value={currentTab}
          onChange={(_, newValue) => {
            setPendingPosition(newValue);
            if (!hasUnsavedChanges) {
              setOpenUnSavedModal(false);
              sessionStorage.setItem('leaReferral', String(newValue));
              setCurrentTab(newValue);
            } else {
              setOpenUnSavedModal(true);
            }
          }}
        >
          <CustomTab
            {...a11yProps(0)}
            label={intl.formatMessage({
              id: 'leaReferralTitleSidebar',
              defaultMessage: 'LEA Referral',
            })}
          />
          <CustomTab
            {...a11yProps(0)}
            label={intl.formatMessage({
              id: 'LeaSchool.LEAReferralNavigate',
              defaultMessage: 'LEA Preschool',
            })}
          />
        </CustomTabs>
        <TabPanel value={currentTab} index={0}>
          <LEAReferralConsent />
        </TabPanel>
        <TabPanel value={currentTab} index={1}>
          <LEAPreschoolForm />
        </TabPanel>
      </ThemeProvider>
    </Box>
  );
};

export default LEATabNavigationComponent;
